import axios from 'axios'

import * as loginActions from '../../users/actions/loginActions'


export const SET_COMPANY_VENDORS_LIST = 'SET_COMPANY_VENDORS_LIST'
export const SET_COMPANY_VENDORS = 'SET_COMPANY_VENDORS'
export const SET_COMPANY_VENDORS_ORDER = 'SET_COMPANY_VENDORS_ORDER'
export const SORT_COMPANY_VENDORS = 'SORT_COMPANY_VENDORS'


const baseUrl = '/api/vendors/'


const processVendor = (vendor) => {
    return {
        vendor_id: vendor.id,
        id: vendor.provider_group.id,
        name: vendor.provider_group.name,
        description: vendor.description,
        first_name: vendor.provider_group.admin.first_name,
        last_name: vendor.provider_group.admin.last_name,
        email: vendor.provider_group.admin.notification_email,
        phone: vendor.provider_group.admin.phone,
        active: vendor.active,
        has_profile: vendor.provider_group.has_profile,
    }
}


export const loadVendorsList = (dispatch, data, initial=false) => {
    axios.get(baseUrl, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        let update = {
            vendors_list: response.data.map(processVendor)
        }
        if (initial) {
            update.vendors = response.data.map(processVendor)
        }
        dispatch({
            type: SET_COMPANY_VENDORS_LIST,
            update: update,
        })
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


export const loadVendors = (dispatch, data) => {
    let url = baseUrl
    let orderBy = data.vendorsOrderBy || ''
    let order = data.vendorsOrder || 'asc'

    if (order === 'desc') {
        orderBy = `-${orderBy}`
    }
    if (orderBy !== '') {
        url += `?ordering=${orderBy}`
    }

    axios.get(url, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        dispatch({
            type: SET_COMPANY_VENDORS,
            update: {
                vendors: response.data.map(processVendor)
            }
        })
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


export const sortVendors = (dispatch, sort, data) => {
    let order = 'asc'
    if (data.vendorsOrderBy === sort) {
        if (data.vendorsOrder === 'asc') {
            order = 'desc'
        }
    }
    const update = {
        vendorsOrderBy: sort,
        vendorsOrder: order,
    }
    dispatch({
        type: SORT_COMPANY_VENDORS,
        update: update
    })
    loadVendors(dispatch, {
        ...data,
        ...update,
    })
}


export const updateVendor = (dispatch, formData, data) => {
    const url = `/api/vendors/${formData.id}/`
    axios.patch(url, formData, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        loadVendors(dispatch, data)
    })
    .catch(thrown => {
        console.log(thrown.message)
    })
}

