import React, { Component } from 'react'
import { Colors } from './colors'
import { Line } from 'react-chartjs-2'
import Typography from '@material-ui/core/Typography'

import IntervalSelect from './IntervalSelect'

import classes from './Charts.module.sass'


class ActivityChart extends Component {
    state = {
        data: [
            [0, 1, 0, 0],
            [2, 3, 1, 0],
            [3, 1, 1, 0],
            [5, 2, 2, 1],
            [2, 3, 2, 4],
            [4, 1, 1, 0],
        ]
    }

    getRandomInt = (min, max) => {
        return Math.floor((Math.random() * ((max + 1) - min)) + min)
    }

    setRandomData = () => {
        const max = this.getRandomInt(5, 10)
        const newData = this.state.data.map(
            row => row.map(_ => this.getRandomInt(1, max))
        )
        this.setState({
            data: newData
        })
    }

    render = () => {
        return (
            <div style={{ padding: 16}}>
                <Typography variant="h3" className={classes.chartTitle}>
                    Project Activity

                    <IntervalSelect
                        name="activityInterval"
                        id="activityInterval"
                        onChange={this.setRandomData}
                    />
                </Typography>

                <Line
                    data={{
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
                        datasets: [
                            {
                                label: 'Active',
                                data: this.state.data.map(row => row[0]),
                                borderColor: Colors.Green,
                                borderWidth: 3,
                                fill: false
                            },
                            {
                                label: 'Pending',
                                data: this.state.data.map(row => row[1]),
                                borderColor: Colors.Yellow,
                                borderWidth: 3,
                                fill: false
                            },
                            {
                                label: 'Draft',
                                data: this.state.data.map(row => row[2]),
                                borderColor: Colors.Orange,
                                borderWidth: 3,
                                fill: false
                            },
                            {
                                label: 'Closed',
                                data: this.state.data.map(row => row[3]),
                                borderColor: Colors.Blue,
                                borderWidth: 3,
                                fill: false
                            }
                        ],
                    }}
                    options={{
                        legend: {
                            position: 'bottom',
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value) => {
                                        if (value % 1 === 0) {
                                            return value
                                        }
                                    },
                                },
                            }]
                        },
                    }}
                    width={600}
                    height={150}
                />
            </div>
        )
    }
}


export default ActivityChart
