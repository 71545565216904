import React, { Component } from 'react'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
// import Snackbar from '@material-ui/core/Snackbar'
// import SnackbarContent from '@material-ui/core/SnackbarContent'
// import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import CancelIcon from '@material-ui/icons/Cancel'

import classes from './JobFilesTab.module.sass'


class JobFilesTab extends Component {
    state = {
        comment: "",
        upload_file: null,
        upload_file_display: ""
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    handleFileChange = (event) => {
        const upload_file = event.target.files[0]
        console.log('upload_file', upload_file)
        this.setState({
            upload_file: upload_file,
            upload_file_display: upload_file.name
        })
    }

    handleRemoveFile = (event) => {
        event.preventDefault()
        this.setState({
            upload_file: null,
            upload_file_display: ''
        })
    }

    uploadFile = (event) => {
        event.preventDefault()
        let formData = new FormData()
        formData.append('job', this.props.jobId)
        formData.append('creator', this.props.userId)
        formData.append('upload_file', this.state.upload_file)
        formData.append('comment', this.state.comment)
        this.props.addFile(formData, () => {
            this.setState({
                upload_file: null,
                upload_file_display: '',
                comment: ''
            })
        })
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Upload a File
                </Typography>
                <Paper className={classes.section}>
                    <Grid container space={16} className={classes.field}>
                        <Grid item sm={4} className={classes.gridField}>
                            <input
                                accept="*/*"
                                className={classes.hidden}
                                id="upload_file"
                                name="upload_file"
                                type="file"
                                onChange={this.handleFileChange}
                            />
                            <label htmlFor="upload_file">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    component="span"
                                    fullWidth
                                >
                                    Select Files to Upload
                                </Button>
                            </label>
                        </Grid>
                        <Grid item sm={8} className={classes.gridField}>
                            {this.state.upload_file ? (
                                <Typography variant="body2">
                                    {this.state.upload_file_display}
                                    <a
                                        href="#nofile"
                                        className={classes.link}
                                        onClick={this.handleRemoveFile}
                                    >
                                        <CancelIcon
                                            style={{
                                                margin: '6px 3px -6px 20px'
                                            }}
                                        />
                                        Remove File
                                    </a>
                                </Typography>
                            ) : null}
                        </Grid>
                    </Grid>
                    <div className={classes.formRow}>
                        <TextField
                            id="comment"
                            name="comment"
                            label="Optional Note"
                            value={this.state.comment}
                            type="text"
                            className={classes.field}
                            onChange={this.updateField}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.uploadButton}
                            onClick={this.uploadFile}
                        >
                            Upload File
                        </Button>
                    </div>
                </Paper>

                <Typography variant="h6" className={classes.dtitle}>
                    Job Files
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'creator__last_name'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'creator__last_name', 'files'
                                        )}
                                    >
                                        Uploaded By
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'create_date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'create_date', 'files'
                                        )}
                                    >
                                        Date
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'label'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'label', 'files'
                                        )}
                                    >
                                        File Name
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'comment'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'comment', 'files'
                                        )}
                                    >
                                        Comment
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Delete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.files.map((file) => {
                            return (
                                <TableRow key={file.file_id}>
                                    <TableCell className={classes.tableCell}>
                                        {file.creator.fullname}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {moment(file.create_date).format('MM/DD/YYYY')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <a
                                            href={file.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.link}
                                        >
                                            {file.label}
                                        </a>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {file.comment}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {file.creator.id === parseInt(this.props.userId) ? (
                                            <a
                                                href={`#delete/${file.file_id}`}
                                                className={classes.link}
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    this.props.deleteFile(file.file_id)
                                                }}
                                            >
                                                Delete
                                            </a>
                                        ) : "N/A"}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>

            </div>
        )
    }
}


export default JobFilesTab
