import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import classes from './JobSteps.module.sass'


const JobStepsProject = (props) => {
    return (
        <List>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 1
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    Fill out the form on this page.
                    Provide as much detail as you can
                    for the expert to evaluate the project.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 2
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    The appropriate expert will contact you 
                    within one day to review your project with you.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 3
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    Your expert will send you an estimate for the 
                    project and if you accept it, the project will begin.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 4
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    You will find your project listed under the "Active"
                    tab on your "My Panel" page. There you will be 
                    able to upload files, track tasks and time, 
                    and add additional people to the project as collaborators.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 5
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    When your project is completed, 
                    your expert will close the project. 
                    You may reopen it at any time.
                </ListItemText>
            </ListItem>
            <ListItem style={{justifyContent: 'center'}}>
                <Typography variant="h6" style={{textAlign: 'center'}}>
                    It's that easy.
                </Typography>
            </ListItem>
            <ListItem style={{justifyContent: 'center'}}>
                <Typography variant="h6" style={{textAlign: 'center'}}>
                    You can always get help at support@sourcepanel.com.
                </Typography>
            </ListItem>
        </List>
    )
}


export default JobStepsProject
