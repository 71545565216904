import React from 'react'
import { connect } from 'react-redux'
import Drawer from '@material-ui/core/Drawer'

import classes from './RightNavBar.module.sass'


const RightNavBar = (props) => (
    <Drawer
        variant="permanent"
        anchor="right"
        className={classes.rightNavDrawer}
        classes={{
            paper: classes.rightNavDrawer,
        }}
    >
        {props.children}
    </Drawer>
)


const mapStateToProps = state => ({
    ...state
})


export default connect(mapStateToProps)(RightNavBar)
  