import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import classes from './InviteCustomer.module.sass'

class InviteCustomer extends Component {
    state = {
        company_name: '',
        contact_name: '',
        email: ''
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.checked
        })
    }

    inviteCustomer = () => {
        this.props.handleClose()
    }

    render = () => {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="invite-customer"
            >
                <DialogTitle id="invite-customer">
                    Invite a customer to work with you
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <em>(No subscription required)</em>
                    </DialogContentText>

                    <TextField
                        margin="dense"
                        id="company_name"
                        name="company_name"
                        label="Company Name"
                        value={this.state.company_name}
                        type="text"
                        fullWidth
                        className={classes.field}
                        onChange={this.updateField}
                    />

                    <TextField
                        margin="dense"
                        id="contact_name"
                        name="contact_name"
                        label="Contact Name"
                        value={this.state.contact_name}
                        type="text"
                        fullWidth
                        className={classes.field}
                        onChange={this.updateField}
                    />

                    <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        value={this.state.email}
                        type="text"
                        fullWidth
                        className={classes.field}
                        onChange={this.updateField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.handleClose}
                        color="primary"
                        className={classes.dialogButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.inviteCustomer}
                        color="primary"
                        variant="contained"
                        className={classes.dialogButton}
                    >
                        Send Invite
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default InviteCustomer
