import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import axios from 'axios'


import * as jobActions from '../jobs/actions/jobActions'
import * as companyActions from '../companies/actions/companyActions'
import * as vendorActions from '../companies/actions/vendorActions'
import NavBar from './components/NavBar'
import Login from '../users/components/Login'
import ForgotPassword from '../users/components/ForgotPassword'
import JobList from '../jobs/components/JobList'
import Job from '../jobs/components/Job'
import MyPanel from '../users/components/MyPanel'
import MyProjects from '../users/components/MyProjects'
import MyAccount from '../users/components/MyAccount'
import MyCompany from '../companies/components/MyCompany'
import MyGroup from '../providers/components/MyGroup'
import JobForm from '../jobs/components/JobForm'
import SourcePanelTheme from './SourcePanelTheme'


SourcePanelTheme.typography = {
    useNextVariants: true,
}

const theme = createMuiTheme(SourcePanelTheme)


class App extends Component {
    state = {}

    componentDidMount = () => {
        this.loadAppData()
        if (this.props.companyId) {
            this.loadCompanyData()
        }
        if (this.props.providerId) {
            this.loadProviderData()
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.token === null && this.props.token !== null) {
            this.loadAppData()
        }
        if (prevProps.companyId === null && this.props.companyId !== null) {
            this.loadCompanyData()
        }
        if (prevProps.providerId === null && this.props.providerId !== null) {
            this.loadProviderData()
        }
    }

    loadAppData = () => {
        this.props.loadJobs(this.props)
        
    }

    loadCompanyData = () => {
        this.props.loadCompany(this.props)
        this.props.loadCustomFields(this.props)
        this.props.loadMembersList(this.props, true)
        this.props.loadVendorsList(this.props, true)
    }

    loadProviderData = () => {
        //
    }

    updateMyGroup = (formData, callback) => {
        const url = `/api/providers/${this.props.providerId}/`
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            this.getUserInfo(this.props.token)
            if (typeof callback === 'function') {
                callback()
            }
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    render = () => {
        return (
            <MuiThemeProvider theme={theme}>
                <Router>
                    <div style={{ paddingTop: 64 }}>
                        <NavBar />
                        <Route
                            exact path="/"
                            render={() => (
                                <Login />
                            )}
                        />
                        <Route
                            exact path="/forgot_password/"
                            render={() => (
                                <ForgotPassword
                                    loggedIn={this.isLoggedIn}
                                />
                            )}
                        />
                        <Route
                            path="/jobs/:jobId"
                            render={({match}) => (
                                <Job
                                    jobId={match.params.jobId}
                                />
                            )}
                        />
                        <Route
                            exact path="/jobs/"
                            render={() => (
                                <JobList 
                                    loggedIn={this.isLoggedIn}
                                    token={this.state.token}
                                />
                            )}
                        />
                        <Route
                            path="/my_panel/"
                            render={() => (
                                <MyPanel />
                            )}
                        />
                        <Route
                            path="/my_projects/"
                            render={() => (
                                <MyProjects />
                            )}
                        />
                        <Route
                            path="/my_account/"
                            render={() => (
                                <MyAccount
                                    loggedIn={this.isLoggedIn}
                                    token={this.state.token}
                                    userId={this.state.userId}
                                    firstName={this.state.firstName}
                                    lastName={this.state.lastName}
                                    phone={this.state.phone}
                                    username={this.state.username}
                                    notificationEmail={this.state.notificationEmail}
                                    title={this.state.title}
                                    updateMyInfo={this.updateMyInfo}
                                    organizationName={this.state.organizationName}
                                    companyId={this.state.companyId}
                                    companyName={this.state.companyName}
                                    providerId={this.state.providerId}
                                    providerName={this.state.providerName}
                                    companyAdmin={this.state.companyAdmin}
                                    providerAdmin={this.state.providerAdmin}
                                />
                            )}
                        />
                        <Route
                            path="/my_company/"
                            render={() => (
                                <MyCompany />
                            )}
                        />
                        <Route
                            path="/my_group/"
                            render={() => (
                                <MyGroup
                                    loggedIn={this.isLoggedIn}
                                    token={this.state.token}
                                    userId={this.state.userId}
                                    firstName={this.state.firstName}
                                    lastName={this.state.lastName}
                                    providerId={this.state.providerId}
                                    providerName={this.state.providerName}
                                    providerAdmin={this.state.providerAdmin}
                                    updateMyGroup={this.updateMyGroup}
                                />
                            )}
                        />
                        <Route
                            path="/new_job/:type/:jobId?"
                            render={({match}) => (
                                <JobForm
                                    type={match.params.type}
                                    jobId={match.params.jobId}
                                />
                            )}
                        />
                    </div>
                </Router>
            </MuiThemeProvider>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    loadJobs: (data) => jobActions.loadJobs(dispatch, data),
    loadCompany: (data) => companyActions.loadCompany(dispatch, data),
    loadMembersList: (data, initial) => companyActions.loadMembersList(
        dispatch, data, initial
    ),
    loadVendorsList: (data, initial) => vendorActions.loadVendorsList(
        dispatch, data, initial
    ),
    loadCustomFields: (data) => companyActions.loadCustomFields(dispatch, data),
})


export default connect(mapStateToProps, mapDispatchToProps)(App);
