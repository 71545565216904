import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import classes from './AddCompanyVendor.module.sass'


class AddCompanyVendor extends Component {
    state = {
        vendor_name: "",
        contact_first_name: "",
        contact_last_name: "",
        contact_title: "",
        contact_email: "",
        contact_phone: "",
        description: "",
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.checked
        })
    }

    save = (event) => {
        const formData = {...this.state}
        this.props.addVendor(formData)
        this.props.handleClose(event)
    }

    render = () => {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="add-company-vendor"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="add-company-vendor">
                    Add a Vendor for {this.props.companyName}
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <Grid container space={16} >
                        <Grid item sm={12} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="vendor_name"
                                name="vendor_name"
                                label="Vendor Name"
                                value={this.state.vendor_name}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>

                        <Grid item sm={12} md={6} lg={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="contact_first_name"
                                name="contact_first_name"
                                label="Contact First Name"
                                value={this.state.contact_first_name}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} lg={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="contact_last_name"
                                name="contact_last_name"
                                label="Contact Last Name"
                                value={this.state.contact_last_name}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={12} md={12} lg={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="contact_title"
                                name="contact_title"
                                label="Title"
                                value={this.state.contact_title}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>


                        <Grid item sm={12} md={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="contact_email"
                                name="contact_email"
                                label="Email"
                                value={this.state.contact_email}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="contact_phone"
                                name="contact_phone"
                                label="Phone"
                                value={this.state.contact_phone}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>

                        <Grid item sm={12} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="description"
                                name="description"
                                label="Short Description / Skills"
                                value={this.state.description}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary" className={classes.dialogButton}>
                        Cancel
                    </Button>
                    <Button onClick={this.save} color="primary" variant="contained" className={classes.dialogButton}>
                        Submit Vendor Request
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default AddCompanyVendor
