import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios, { CancelToken } from 'axios'
// Material UI
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Card from '@material-ui/core/Card'
import CardContent  from '@material-ui/core/CardContent'
// Icons
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import BusinessIcon from '@material-ui/icons/Business'
import BuildIcon from '@material-ui/icons/Build'
// Custom components
import * as loginActions from '../../users/actions/loginActions'
import * as companyActions from '../actions/companyActions'
import * as vendorActions from '../actions/vendorActions'
import LeftNavBar from '../../src/components/LeftNavBar'
import RightNavBar from '../../src/components/RightNavBar'
import AlternateRightNavBar from '../../src/components/AlternateRightNavBar'
import NotificationsList from '../../notifications/components/NotificationsList'
import CompanyProfileTab from './CompanyProfileTab'
import CompanyMembersTab from './CompanyMembersTab'
import CompanyProvidersTab from './CompanyProvidersTab'
import AddCompanyMember from './AddCompanyMember'
import AddCompanyVendor from './AddCompanyVendor'
import ManageCustomFields from './ManageCustomFields'
// Styling
import classes from './MyCompany.module.sass'


class MyCompany extends Component {
    ajaxToken = null
    ajaxCancel = null

    state = {
        menuValue: 0,
        customFieldsOrderBy: '',
        customFieldsOrder: 'desc',
        addMemberOpen: false,
        addVendorOpen: false,
        customFieldsOpen: false,
    }

    componentDidMount = () => {
        this.ajaxToken = new CancelToken(c => {this.ajaxCancel = c})
    }

    componentWillUnmount = () => {
        this.ajaxCancel('Unmounting MyCompany.')
    }

    updateMember = (memberId, formData) => {
        const url = `/api/users/${memberId}/`
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.props.loadMembers(this.props)
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    addMember = (formData) => {
        const url = `/api/users/add_company_member/`
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.props.loadMembers(this.props)
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    addVendor = (formData) => {
        const url = `/api/vendors/add_company_vendor/`
        axios.post(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.props.loadVendors(this.props)
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    handleChange = (event, value) => {
        this.setState({ menuValue: value })
    }

    handleSort = (value, type='members') => {
        let order = 'asc'
        if (this.state[type + 'OrderBy'] === value) {
            if (this.state[type + 'Order'] === 'asc') {
                order = 'desc'
            }
        }
        this.setState({
            [type + 'OrderBy']: value,
            [type + 'Order']: order
        }, () => {
            if (type === 'members') {
                this.props.loadMembers(this.props)
            } else if (type === 'vendors') {
                this.props.loadVendors(this.props)
            }
        })
    }

    handleAddMemberOpen = () => {
        this.setState({
            addMemberOpen: true
        })
    }

    handleAddMemberClose = () => {
        this.setState({
            addMemberOpen: false
        })
    }

    handleAddVendorOpen = () => {
        this.setState({
            addVendorOpen: true
        })
    }

    handleAddVendorClose = () => {
        this.setState({
            addVendorOpen: false
        })
    }

    handleCustomFieldsOpen = () => {
        this.setState({
            customFieldsOpen: true
        })
    }

    handleCustomFieldsClose = () => {
        this.setState({
            customFieldsOpen: false
        })
    }

    renderRightNav = () => (
        <div>
            <Typography variant="h6" className={classes.sideTitle}>
                Notifications.
                <Card>
                    <CardContent style={{ padding: 8, fontSize: '1rem', fontWeight: 400 }}>
                        {/*<Typography
                            variant="overline"
                            component={Link}
                            to='/jobs/11700/'
                            className={classes.link}
                        >
                            New Comment on Job #11700
                        </Typography>*/}
                        <Typography variant="body2">
                            No new notifications at this time.
                        </Typography>
                    </CardContent>
                </Card>
            </Typography>

            <Typography variant="h6" className={classes.sideTitle}>
                Meetings.
                <Card>
                    <CardContent style={{ padding: 8, fontSize: '1rem', fontWeight: 400 }}>
                        <Typography variant="body2">
                            No meetings scheduled.
                        </Typography>
                    </CardContent>
                </Card>
            </Typography>
        </div>
    )

    render = () => {
        return (
           <div className={classes.root}>
                <LeftNavBar>
                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleAddMemberOpen}
                    >
                        <PersonAddIcon className={classes.actionButtonIcon} />
                        Add a company member
                    </Button>
                    <AddCompanyMember
                        open={this.state.addMemberOpen}
                        profile={{}}
                        handleClose={this.handleAddMemberClose}
                        addMember={this.addMember}
                        companyId={this.props.companyId}
                        companyName={this.props.companyName}
                    />
                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleAddVendorOpen}
                    >
                        <BusinessIcon className={classes.actionButtonIcon} />
                        Add a provider
                    </Button>
                    <AddCompanyVendor
                        open={this.state.addVendorOpen}
                        handleClose={this.handleAddVendorClose}
                        addVendor={this.addVendor}
                        companyId={this.props.companyId}
                        companyName={this.props.companyName}
                    />
                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleCustomFieldsOpen}
                    >
                        <BuildIcon className={classes.actionButtonIcon} />
                        Manage custom fields
                    </Button>
                    <ManageCustomFields
                        open={this.state.customFieldsOpen}
                        handleClose={this.handleCustomFieldsClose}
                    />

                    <AlternateRightNavBar>
                        <NotificationsList />
                    </AlternateRightNavBar>
                </LeftNavBar>

                <Paper className={classes.newContent}>
                    <Tabs
                        value={this.state.menuValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="off"
                        className={classes.tabs}
                    >
                        <Tab label={'My Company'} className={classes.tab} value={0} />
                        <Tab label={'Company Members'} className={classes.tab} value={1} />
                        <Tab label={'Providers'} className={classes.tab} value={2} />
                    </Tabs>

                    {this.state.menuValue === 0 ? (
                        <CompanyProfileTab
                            updateCompany={(formData) => {
                                this.props.updateCompany(formData, this.props)
                            }}
                        />
                    ) : null}
                    {this.state.menuValue === 1 ? (
                         <CompanyMembersTab
                            isCompanyAdmin={this.props.isCompanyAdmin}
                            companyId={this.props.companyId}
                            company={this.props.company}
                            members={this.props.members}
                            orderBy={this.props.membersOrderBy}
                            order={this.props.membersOrder}
                            handleSort={sort => this.props.sortMembers(sort, this.props)}
                            updateMember={this.updateMember}
                            companyAdmin={this.props.companyAdmin}
                        />
                    ) : null}
                    {this.state.menuValue === 2 ? (
                        <CompanyProvidersTab />
                    ) : null}
                </Paper>

                <RightNavBar>
                    <NotificationsList />
                </RightNavBar>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    logoutHandler: () => loginActions.logout(dispatch),
    loadCompany: (data) => companyActions.loadCompany(dispatch, data),
    loadMembers: (data) => companyActions.loadMembers(dispatch, data),
    loadVendors: (data) => vendorActions.loadVendors(dispatch, data),
    loadCustomFields: (data) => companyActions.loadCustomFields(dispatch, data),
    sortMembers: (sort, data) => companyActions.sortMembers(
        dispatch, sort, data
    ),
})


export default connect(mapStateToProps, mapDispatchToProps)(MyCompany)

