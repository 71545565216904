import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Autosuggest from 'react-autosuggest'
// Material UI
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'
// Icons
import SearchIcon from '@material-ui/icons/Search'
// Styling
import classes from './SearchBar.module.sass'


const baseUrl = '/api/jobs/'


class SearchBar extends Component {
    state = {
        value: '',
        suggestions: [],
    }

    onChange = (event, {newValue}) => {
        this.setState({
            value: newValue,
        })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        axios.get(`${baseUrl}?search=${value}`, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            this.setState({
                suggestions: response.data.results
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    }

    getSuggestionValue = suggestion => suggestion.name

    renderInputComponent = (inputProps) => {
        const { inputClasses, inputRef, ref, ...other} = inputProps

        return (
            <TextField
                fullWidth={true}
                variant="outlined"
                margin="dense"
                InputProps={{
                    inputRef: node => {
                        ref(node)
                        inputRef(node)
                    },
                    classes: {
                        input: inputClasses,
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                {...other}
            />
        )
    }

    renderSuggestion = (suggestion) => (
        <MenuItem
            key={suggestion.id}
            value={suggestion.value}
            component={Link}
            to={`/jobs/${suggestion.id}`}
        >
            Job #{suggestion.id} - {suggestion.name}
        </MenuItem>
    )

    renderSuggestionsContainer = (options) => (
        <Popper anchorEl={this.popperNode} open={Boolean(options.children)} placement="bottom-start">
            <Paper
                square
                {...options.containerProps}
                style={{ width: this.popperNode ? this.popperNode.clientWidth + 40 : null }}
            >
                {options.children}
            </Paper>
        </Popper>
    )

    render = () => {
        return (
            <Autosuggest
                {...this.props}
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                }}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderInputComponent={this.renderInputComponent}
                renderSuggestionsContainer={this.renderSuggestionsContainer}
                inputProps={{
                    classes: {...this.props.classes},
                    style: this.props.style,
                    label: this.props.label,
                    placeholder: this.props.placeholder,
                    value: this.state.value,
                    onChange: this.onChange,
                    inputRef: node => {
                        this.popperNode = node
                    },
                    InputLabelProps: {
                    }
                }}
            />
        )
    }
}


export default SearchBar