import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'

import classes from './ChangePassword.module.sass'


class ChangePassword extends Component {
    state = {
        password: '',
        confirm_password: '',
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    clearForm = () => {
        this.setState({
            password: '',
            confirm_password: '',
        })
        this.props.handleClose()
    }

    changePassword = () => {
        const url = '/api/users/change_password/'
        const formData = {...this.state}
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            if (response.data.status === 'SUCCESS') {
                this.clearForm()
            }
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    render = () => {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="change-password"
            >
                <DialogTitle id="change-password">
                    Change your password
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <em>(Minimum 8 characters)</em>
                    </DialogContentText>

                    <TextField
                        margin="dense"
                        id="password"
                        name="password"
                        label="Password"
                        value={this.state.password}
                        type="password"
                        fullWidth
                        className={classes.field}
                        onChange={this.updateField}
                    />

                    <TextField
                        margin="dense"
                        id="confirm_password"
                        name="confirm_password"
                        label="Confirm Password"
                        value={this.state.confirm_password}
                        type="password"
                        fullWidth
                        className={classes.field}
                        onChange={this.updateField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.clearForm}
                        color="primary"
                        className={classes.dialogButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.changePassword}
                        color="primary"
                        variant="contained"
                        className={classes.dialogButton}
                    >
                        Change Password
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default ChangePassword
