import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'

import classes from './ForgotPassword.module.sass'


class ForgotPassword extends Component {
    state = {
        username: '',
    }

    handleChange = fieldName => event => {
        this.setState({
            [fieldName]: event.target.value
        })
    }

    enterForgotPassword = (event) => {
        const ENTER_CODE = 13
        if (event.keyCode === ENTER_CODE) {
            this.requestPasswordReset(event)
        }
    }

    requestPasswordReset = (event) => {
        axios.post(
            '/api/users/forgot_password/',
            {
                username: this.state.username
            }
        )
        .then(response => {
            this.props.history.push('/')
        })
        .catch(response => {
            console.log(response)
        })
    }

    render = () => {
        if (this.props.loggedIn) {
            return <Redirect push to="/my_panel/" />
        }
        
        return (
            <div className={classes.root}>
                <Grid container justify="center" spacing={16}>
                    <Grid item lg={5} md={6}>
                        <Paper className={classes.content}>
                            <Typography variant="h3" gutterBottom>
                                Password Reset
                            </Typography>
                            <hr />
                            <br />
                            <TextField
                                id="username"
                                name="username"
                                label="Username"
                                className={classes.formField}
                                variant="outlined"
                                type="text"
                                value={this.state.username}
                                onChange={this.handleChange('username')}
                                InputProps={{startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                )}}
                            />
                            <Button 
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.requestPasswordReset}
                            >
                                Forgot Password
                            </Button>

                            <div className={classes.linkSection}>
                                <Link to="/" className={classes.link}>
                                    Return to login.
                                </Link>
                                <br />
                                <Link to="/signup" className={classes.link}>
                                    Signup.
                                </Link>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword))
