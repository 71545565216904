import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import classes from './JobSteps.module.sass'


const JobStepsFTE = (props) => {
    return (
        <List>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 1
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    Fill out the form on this page.  Provide as much description 
                    as you can to ensure the best candidates are presented to you.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 2
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    SourcePanel will upload vetted candidates for your consideration. 
                    You will be notified when new candidates have been submitted 
                    and you can review candidates from the “Candidates” tab on this project.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 3
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    Select the candidates you would like to interview and provide 
                    your interview availability.  SourcePanel will schedule your 
                    interviews for you based on your availalbity.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 4
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    Rank your candidates, upload any required employee documentation, 
                    and make offers as you see fit.
                </ListItemText>
            </ListItem>
            <ListItem className={classes.stepItem}>
                <Typography variant="h6" className={classes.stepNumber}>
                    Step 5
                </Typography>
                <ListItemText className={classes.stepInfo}>
                    Candidates will receive your offer and can accept On Demand. 
                    SourcePanel will send an invoice based on rates outlined in our 
                    current terms and conditions and you may begin paying your new employee directly.
                </ListItemText>
            </ListItem>
            <ListItem style={{justifyContent: 'center'}}>
                <Typography variant="h6" style={{textAlign: 'center'}}>
                    It's that easy.
                </Typography>
            </ListItem>
            <ListItem style={{justifyContent: 'center'}}>
                <Typography variant="h6" style={{textAlign: 'center'}}>
                    You can always get help at support@sourcepanel.com.
                </Typography>
            </ListItem>
        </List>
    )
}


export default JobStepsFTE
