import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// import Snackbar from '@material-ui/core/Snackbar'
// import SnackbarContent from '@material-ui/core/SnackbarContent'
// import IconButton from '@material-ui/core/IconButton'

// import Grid from '@material-ui/core/Grid'
// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
// import TableSortLabel from '@material-ui/core/TableSortLabel'
// import Tooltip from '@material-ui/core/Tooltip'

import InvoicingWorklogsTab from '../../worklogs/components/InvoicingWorklogsTab'
import InvoicingExpensesTab from '../../expenses/components/InvoicingExpensesTab'
import InvoicingMilestonesTab from '../../milestones/components/InvoicingMilestonesTab'
import InvoicesTab from './InvoicesTab'

import classes from './JobInvoicingTab.module.sass'


class JobInvoicingTab extends Component {
    state = {
        tabValue: 0
    }

    hasWorklogs = () => {
        const hourly_types = ['STAFFING', 'DYNAMIC', 'HOURLY']
        if (hourly_types.indexOf(this.props.job.project_type) !== -1) {
            return true
        }
        return false
    }

    hasMilestones = () => {
        if (this.props.job.project_type === 'FIXED') {
            return true
        }
        return false
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    handleChange = (event, tabValue) => {
        this.setState({ tabValue })
    }

    handleChangeIndex = index => {
        this.setState({ tabValue: index })
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Project Invoicing
                </Typography>
                {this.props.job.start_date ? (
                    <React.Fragment>
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab
                                label="Invoices"
                                className={classes.fillTab}
                                value={0}
                            />
                            {this.hasWorklogs() ? (
                                <Tab
                                    label="Work logs" 
                                    className={classes.fillTab}
                                    value={1}
                                />
                            ) : null}
                            {this.hasMilestones() ? (
                                <Tab 
                                    label="Milestones"
                                    className={classes.fillTab}
                                    value={2}
                                />
                            ) : null}
                            <Tab
                                label="Expenses"
                                className={classes.fillTab}
                                value={3}
                            />
                        </Tabs>
                        {this.state.tabValue === 0 ? (
                            <InvoicesTab
                                userType={this.props.userType}
                                invoices={this.props.invoices}
                                handleSort={this.props.handleSort}
                                order={this.props.invoicesOrder}
                                orderBy={this.props.invoicesOrderBy}
                                jobId={this.props.jobId}
                            />
                        ) : null}
                            
                        {this.state.tabValue === 1 ? (
                            <InvoicingWorklogsTab
                                userType={this.props.userType}
                                worklogs={this.props.worklogs}
                                handleSort={this.props.handleSort}
                                order={this.props.worklogsOrder}
                                orderBy={this.props.worklogsOrderBy}
                                addWorklog={this.props.addWorklog}
                                jobId={this.props.jobId}
                            />
                        ) : null}
                            
                        {this.state.tabValue === 2 ? (
                            <InvoicingMilestonesTab
                                userType={this.props.userType}
                                milestones={this.props.milestones}
                                handleSort={this.props.handleSort}
                                jobId={this.props.jobId}
                            />
                        ) : null}
                            
                        {this.state.tabValue === 3 ? (
                            <InvoicingExpensesTab
                                userType={this.props.userType}
                                expenses={this.props.expenses}
                                handleSort={this.props.handleSort}
                                order={this.props.expensesOrder}
                                orderBy={this.props.expensesOrderBy}
                                addExpense={this.props.addExpense}
                                jobId={this.props.jobId}
                            />
                        ) : null}
                    </React.Fragment>
                ) : (
                    <Paper className={classes.tabContent}>
                        Project has not started yet.
                    </Paper>
                )}
            </div>
        )
    }
}


export default JobInvoicingTab
