import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddCircle from '@material-ui/icons/AddCircle'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CKEditor from 'react-ckeditor-component'
import axios from 'axios'

import classes from './Job.module.sass'

import CommentCard from '../../comments/components/CommentCard'


class JobCommentsTab extends Component {
    state = {
        comment: "",
        addCommentOpen: false,
        addCommentMessage: "Comment successfully posted",
        addCommentClass: ""
    }

    updateComment = (event) => {
        const currentComment = event.editor.getData();
        this.setState({
            comment: currentComment
        })
    }

    addComment = (event) => {
        let url = '/api/comments/'
        const data = {
            comment: this.state.comment,
            job: this.props.job.id
        }
        axios.post(url, data, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            console.log(response)
            this.props.prependComment(response.data)
            this.setState({
                comment: "",
                addCommentOpen: true,
                addCommentMessage: "Comment successfully posted",
                addCommentClass: null
            })
        })
        .catch((error) => {
            this.setState({
                addCommentOpen: true,
                addCommentMessage: "Error sending comment. " + error,
                addCommentClass: classes.error
            })
        })
    }

    handleClose = (event) => {
        this.setState({
            addCommentOpen: false
        })
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Add Comment
                </Typography>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    open={this.state.addCommentOpen}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                >
                    <SnackbarContent
                        onClose={this.handleClose}
                        className={this.state.addCommentClass}
                        ContentProps={{
                            'aria-describedby': 'add-comment-message'
                        }}
                        message={
                            <span id="add-comment-message">
                                {this.state.addCommentMessage}
                            </span>
                        }
                        action={
                            <IconButton
                                aria-label="Close"
                                color="inherit"
                                onClick={this.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                </Snackbar>
                <Paper className={classes.innerSection}>
                    <CKEditor
                        activeClass={classes.ckeditor}
                        content={this.state.comment} 
                        events={{
                            "blur": this.updateComment,
                            "change": this.updateComment,
                            "keyup": this.updateComment
                        }}
                        config={{
                            disableNativeSpellChecker: false,
                        }}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <Button 
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.addComment}
                            className={classes.formButton}
                        >
                            <AddCircle className={classes.buttonIcon} />
                            Add Comment
                        </Button>
                    </div>
                </Paper>

                <Typography variant="h6" className={classes.dtitle}>
                    Comments
                </Typography>
                {this.props.comments.map((comment) => {
                    return (
                        <CommentCard
                            key={comment.id}
                            comment={comment} 
                            userId={this.props.userId}
                        />
                    )
                })}
            </div>
        )
    }
}


export default JobCommentsTab