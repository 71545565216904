import React from 'react'
import { connect } from 'react-redux'
import Drawer from '@material-ui/core/Drawer'

import classes from './LeftNavBar.module.sass'


const LeftNavBar = (props) => (
    <Drawer
        variant="permanent"
        className={classes.navDrawer}
        classes={{
            paper: classes.navDrawer,
        }}
    >
        {props.providerLogo ? (
            <div className={classes.logoImage}>
                <img 
                    src={props.providerLogo}
                    alt={props.providerName}
                />
            </div>
        ) : null}
        {/* ToDo: Show name */}
        {props.companyLogo ? (
            <div className={classes.logoImage}>
                <img 
                    src={props.companyLogo}
                    alt={props.companyName}
                />
            </div>
        ) : null}
        {props.children}
    </Drawer>
)


const mapStateToProps = state => ({
    ...state
})


export default connect(mapStateToProps)(LeftNavBar)
  