import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
// Material UI
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
// Custom components
import LeftNavBar from '../../src/components/LeftNavBar'
import RightNavBar from '../../src/components/RightNavBar'
import {
    DefaultCustomerMenu,
    DefaultExpertMenu
} from '../../src/components/DefaultNavBarMenus'
import NotificationsList from '../../notifications/components/NotificationsList'
import SearchBar from './SearchBar'
import ProjectsChart from '../../analytics/components/ProjectsChart'
import InvoicesChart from '../../analytics/components/InvoicesChart'
import UsersChart from '../../analytics/components/UsersChart'
import ActivityChart from '../../analytics/components/ActivityChart'
// Stylings
import classes from './MyPanel.module.sass'


class MyPanel extends Component {

    state = {
        showAll: true,
    }

    componentDidMount = () => {}

    handleShowAllToggle = (event) => {
        this.setState({
            showAll: !this.state.showAll,
        })
    }

    render = () => {
        if (!this.props.loggedIn) {
            return <Redirect push to="/" />
        }

        return (
            <div className={classes.root}>
                <LeftNavBar>
                    {this.props.companyId ? <DefaultCustomerMenu /> : null}
                    {this.props.providerId ? <DefaultExpertMenu /> : null}
                </LeftNavBar>

                <Paper className={classes.newContent}>
                    <Grid container spacing={16} className={classes.formGrid}>
                        <Grid item md={6}>
                            <FormGroup row style={{ marginTop: 8 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.showAll}
                                            onChange={this.handleShowAllToggle}
                                            value="showAll"
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body1"
                                        >
                                            Me
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                />
                                <label
                                    className={classes.label}
                                    onClick={this.handleShowAllToggle}
                                >
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            `All ${this.props.companyName}`
                                        }
                                    </Typography>
                                </label>

                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <SearchBar
                                label=""
                                placeholder="Search for projects, people, comments, etc."
                                token={this.props.token}
                            />
                        </Grid>
                    </Grid>

                    <hr className={classes.divide} />

                    <Grid container spacing={16} className={classes.formGrid}>
                        <Grid item lg={4} md={6}>
                            <ProjectsChart />
                        </Grid>
                        <Grid item lg={4} md={6}>
                            <InvoicesChart />
                        </Grid>
                        <Grid item lg={4} md={6}>
                            <UsersChart />
                        </Grid>
                        <Grid item lg={12} md={6}>
                            <ActivityChart />
                        </Grid>
                    </Grid>
                </Paper>

                <RightNavBar>
                    <NotificationsList />
                </RightNavBar>
            </div>

        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(MyPanel)

