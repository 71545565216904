import axios from 'axios'

export const ADD_PROVIDER_MEMBER = 'ADD_PROVIDER_MEMBER'

export const addMember = (dispatch, data) => {
    axios.post(
        '/api/providers/add_member', data
    )
    .then(response => {
        // dispatch({
        //     type: LOGIN,
        //     token: response.data.token,
        //     loggedIn: true,
        //     errors: {},

        // })
    })
    .catch(errors => {
        // if (typeof errors.response !== 'undefined') {
        //      errors = errors.response.data
        // }
        // dispatch({
        //     type: LOGIN,
        //     token: null,
        //     loggedIn: false,
        //     errors: errors,
        // })
    })
}
