import axios from 'axios'

import * as loginActions from '../../users/actions/loginActions'
import * as userActions from '../../users/actions/userActions'

export const LOAD_COMPANY = 'LOAD_COMPANY'
export const LOAD_COMPANY_MEMBERS = 'LOAD_COMPANY_MEMBERS'
export const LOAD_COMPANY_MEMBERS_LIST = 'LOAD_COMPANY_MEMBERS_LIST'
export const LOAD_CUSTOM_FIELDS = 'LOAD_CUSTOM_FIELDS'
export const SORT_COMPANY_MEMBERS = 'SORT_COMPANY_MEMBERS'


/**
 * Company Data
 */

export const loadCompany = (dispatch, data) => {
    const url = `/api/companies/${data.companyId}/`
    axios.get(url, {
        headers: {
            Authorization: `Token ${data.token}`
        },
    })
    .then(response => {
        dispatch({
            type: LOAD_COMPANY,
            update: {
                company: response.data
            }
        })
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


export const updateCompany = (dispatch, formData, data) => {
    const url = `/api/companies/${data.companyId}/`
    axios.patch(url, formData, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        userActions.getUserInfo(dispatch, data)
        dispatch({
            type: LOAD_COMPANY,
            update: {
                company: response.data
            }
        })
    })
    .catch(error => {
        console.log(error.message)
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


/**
 * Members
 */


export const loadMembersList = (dispatch, data, initial=false) => {
    let url = `/api/users/?company=${data.companyId}`
    axios.get(url, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        const updateData = {
            members_list: response.data.results
        }
        if (initial) {
            updateData.members = response.data.results
        }
        dispatch({
            type: LOAD_COMPANY_MEMBERS_LIST,
            update: updateData,
        })
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


export const loadMembers = (dispatch, data) => {
    let url = `/api/users/?company=${data.companyId}`
    let orderBy = data.membersOrderBy || ''
    let order = data.membersOrder || 'asc'

    if (order === 'desc') {
        orderBy = `-${orderBy}`
    }
    if (orderBy !== '') {
        url += `&ordering=${orderBy}`
    }

    axios.get(url, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        dispatch({
            type: LOAD_COMPANY_MEMBERS,
            update: {
                members: response.data.results,
            },
        })
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


export const sortMembers = (dispatch, sort, data) => {
    let order = 'asc'
    if (data.membersOrderBy === sort) {
        if (data.membersOrder === 'asc') {
            order = 'desc'
        }
    }
    const update = {
        membersOrderBy: sort,
        membersOrder: order,
    }
    dispatch({
        type: SORT_COMPANY_MEMBERS,
        update: update
    })
    loadMembers(dispatch, {
        ...data,
        ...update,
    })
}


/**
 * Custom Fields
 */

export const loadCustomFields = (dispatch, data) => {
    let url = `/api/custom_fields/?company=${data.companyId}`
    let orderBy = data.customFieldsOrderBy || ''
    let order = data.customFieldsOrder || 'asc'

    if (order === 'desc') {
        orderBy = `-${orderBy}`
    }
    if (orderBy !== '') {
        url += `&ordering=${orderBy}`
    }

    axios.get(url, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        dispatch({
            type: LOAD_CUSTOM_FIELDS,
            update: {
                customFields: response.data,
            },
        })
    })
    .catch(error => {
        console.log(error.message)
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


export const updateCustomField = (dispatch, formData, data) => {
    const url = `/api/custom_fields/${formData.id}/`
    axios.patch(url, formData, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        const index = data.customFields.findIndex(el => {
            return el.id === response.data.id
        })
        dispatch({
            type: LOAD_CUSTOM_FIELDS,
            update: {
                customFields: [
                    ...data.customFields.slice(0, index),
                    response.data,
                    ...data.customFields.slice(index + 1)
                ]
            }
        })
    })
    .catch(error => {
        console.log(error.message)
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}

export const addCustomField = (dispatch, formData, data) => {
    const url = `/api/custom_fields/`
    axios.post(url, formData, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        dispatch({
            type: LOAD_CUSTOM_FIELDS,
            update: {
                customFields: [
                    ...data.customFields,
                    response.data,
                ]
            }
        })
    })
    .catch(error => {
        console.log(error.message)
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


