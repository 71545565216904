import React from 'react'
// import { Link } from 'react-router-dom'
// Material UI
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import classes from './NotificationsList.module.sass'


const NotificationsList = (props) => (
    <div>
        <Typography variant="h6" className={classes.sideTitle}>
            Notifications.
            <Card>
                <CardContent style={{ padding: 8, fontSize: '1rem', fontWeight: 400 }}>
                    {/*<Typography
                        variant="overline"
                        component={Link}
                        to='/jobs/11700/'
                        className={classes.link}
                    >
                        New Comment on Job #11700
                    </Typography>*/}
                    <Typography variant="body2">
                        No new notifications at this time.
                    </Typography>
                </CardContent>
            </Card>
        </Typography>

        <Typography variant="h6" className={classes.sideTitle}>
            Meetings.
            <Card>
                <CardContent style={{ padding: 8, fontSize: '1rem', fontWeight: 400 }}>
                    <Typography variant="body2">
                        No meetings scheduled.
                    </Typography>
                </CardContent>
            </Card>
        </Typography>
    </div>
)

export default NotificationsList
