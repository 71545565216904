import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import classes from './IntervalSelect.module.sass'


class IntervalSelect extends Component {
    state = {
        interval: 'years=1',
    }

    options = [
        {
            label: '30 Day',
            value: 'days=30',
        },
        {
            label: '60 Day',
            value: 'days=60',
        },
        {
            label: '90 Day',
            value: 'days=90',
        },
        {
            label: 'YTD',
            value: 'ytd=True',
        },
        {
            label: '1 Year',
            value: 'years=1',
        },
        {
            label: '2 Year',
            value: 'years=2',
        },
        {
            label: '3 Year',
            value: 'years=3',
        },
    ]

    selectHandler = (event) => {
        const value = event.target.value
        this.setState({
            interval: value,
        })
        this.props.onChange(value)
    }

    render = () => {
        return (
            <FormControl className={classes.dropDown}>
                <Select
                    value={this.state.interval}
                    onChange={this.selectHandler}
                    inputProps={{
                        name: this.props.name,
                        id: this.props.id,
                    }}
                >
                    {this.options.map((option) => (
                        <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}


export default IntervalSelect
