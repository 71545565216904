import axios from 'axios'
import { format } from 'date-fns'

import * as loginActions from '../../users/actions/loginActions'

export const LOAD_JOBS = 'LOAD_JOBS'
export const LOAD_MORE_JOBS = 'LOAD_MORE_JOBS'
export const SORT_JOBS = 'SORT_JOBS'
export const SET_JOB_STATUS_FILTER = 'SET_JOB_STATUS_FILTER'
export const SET_ME_ONLY_FILTER = 'SET_ME_ONLY_FILTER'
export const SET_JOB_START_FILTER = 'SET_JOB_START_FILTER'
export const SET_JOB_END_FILTER = 'SET_JOB_END_FILTER'
export const SET_JOB_CUSTOMER_FILTER = 'SET_JOB_CUSTOMER_FILTER'
export const SET_JOB_VENDOR_FILTER = 'SET_JOB_VENDOR_FILTER'

const baseUrl = '/api/jobs/'
const DEFAULT_ORDER_BY = 'request_date'
const DEFAULT_ORDER = 'desc'
let JOBS_LOAD_ID = null


const formatDateTime = (date, end=false) => {
    let timeString = '00:00'
    if (end) {
        timeString = '23:59'
    }
    const formattedDate = `${format(date, 'yyyy-MM-dd')} ${timeString}`
    return formattedDate
}


const getFilters = (data) => {
    let filters = '?'
    let statusFilters = []
    for (const key in data.jobStatusFilters) {
        if (data.jobStatusFilters[key]) {
            statusFilters.push(key.replace(' ', '+'))
        }
    }
    if (statusFilters.length) {
        filters += `status__in=${statusFilters.join(',')}&`
    }
    if (data.jobsMeOnlyFilter) {
        filters += `filters=(customer=${data.userId})|` +
                   `(expert=${data.userId})|` +
                   `(collaboration__user=${data.userId})&`
    }
    if (data.jobsStartFilter !== null) {
        filters += `request_date__gte=${formatDateTime(data.jobsStartFilter)}&`
    }
    if (data.jobsEndFilter !== null) {
        filters += `request_date__lte=${formatDateTime(
            data.jobsEndFilter, true
        )}&`
    }
    if (data.jobsCustomerFilter !== '') {
        filters += `customer=${data.jobsCustomerFilter}&`
    }
    if (data.jobsVendorFilter !== '') {
        filters += `provider_group=${data.jobsVendorFilter}&`
    }
    return filters
}


const getOrdering = (data) => {
    let ordering = 'ordering='
    let orderBy = data.jobsOrderBy || DEFAULT_ORDER_BY
    let order = data.jobsOrder || DEFAULT_ORDER
    if (order === 'desc') {
        ordering += '-'
    }
    ordering += orderBy
    return ordering
}


export const loadJobs = (dispatch, data=null) => {
    let url = baseUrl 
    if (data !== null) {
        url += getFilters(data) + getOrdering(data)
    }
    JOBS_LOAD_ID = new Date().getTime()
    let loadId = JOBS_LOAD_ID
    axios.get(url, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        if (loadId !== JOBS_LOAD_ID) {
            return
        }
        dispatch({
            type: LOAD_JOBS,
            update: {
                count: response.data.count,
                jobs: response.data.results,
            },
        })
        if (response.data.next !== null) {
            loadMoreJobs(
                dispatch,
                loadId,
                data.token,
                response.data.next,
            )
        }
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
            loginActions.logout(dispatch)
        }
    })
}


export const setJobStatusFilter = (dispatch, status, data) => {
    dispatch({
        type: SET_JOB_STATUS_FILTER,
        update: {
            [status]: !data.jobStatusFilters[status]
        },
    })
    loadJobs(dispatch, {
        ...data,
        jobStatusFilters: {
            ...data.jobStatusFilters,
            [status]: !data.jobStatusFilters[status],
        }
    })
}


export const setMeOnlyFilter = (dispatch, data) => {
    dispatch({
        type: SET_ME_ONLY_FILTER,
        update: {
            jobsMeOnlyFilter: !data.jobsMeOnlyFilter
        }
    })
    loadJobs(dispatch, {
        ...data,
        jobsMeOnlyFilter: !data.jobsMeOnlyFilter,
    })
}


export const setJobsStartFilter = (dispatch, startDate, data) => {
    dispatch({
        type: SET_JOB_START_FILTER,
        update: {
            jobsStartFilter: startDate,
        }
    })
    loadJobs(dispatch, {
        ...data,
        jobsStartFilter: startDate,
    })
}


export const setJobsEndFilter = (dispatch, endDate, data) => {
    dispatch({
        type: SET_JOB_END_FILTER,
        update: {
            jobsEndFilter: endDate,
        }
    })
    loadJobs(dispatch, {
        ...data,
        jobsEndFilter: endDate,
    })
}


export const setJobsCustomerFilter = (dispatch, customerId, data) => {
    dispatch({
        type: SET_JOB_CUSTOMER_FILTER,
        update: {
            jobsCustomerFilter: customerId,
        }
    })
    loadJobs(dispatch, {
        ...data,
        jobsCustomerFilter: customerId,
    })
}


export const setJobsVendorFilter = (dispatch, vendorId, data) => {
    dispatch({
        type: SET_JOB_VENDOR_FILTER,
        update: {
            jobsVendorFilter: vendorId,
        }
    })
    loadJobs(dispatch, {
        ...data,
        jobsVendorFilter: vendorId,
    })
}

export const sortJobs = (dispatch, sort, data) => {
    let order = 'asc'
    if (data.jobsOrderBy === sort) {
        if (data.jobsOrder === 'asc') {
            order = 'desc'
        }
    }
    dispatch({
        type: SORT_JOBS,
        update: {
            jobsOrderBy: sort,
            jobsOrder: order,
        },
    })
    loadJobs(dispatch, {
        ...data,
        jobsOrderBy: sort,
        jobsOrder: order,
    })
}


const loadMoreJobs = (dispatch, loadId, token, next) => {
    axios.get(next, {
        headers: {
            Authorization: `Token ${token}`
        }
    })
    .then(response => {
        if (loadId !== JOBS_LOAD_ID) {
            return
        }
        dispatch({
            type: LOAD_MORE_JOBS,
            update: {
                jobs: response.data.results,
            },
        })
        if (response.data.next !== null) {
            loadMoreJobs(dispatch, loadId, token, response.data.next)
        }
    })
}
