import React, { PureComponent } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import AddCircleIcon from '@material-ui/icons/AddCircle'

import EstimateMilestone from './EstimateMilestone'

import classes from './EstimateMilestonesList.module.sass'


class EstimateMilestonesList extends PureComponent {
    state = {}

    percentTotal = () => {
        let total = 0
        for (let milestone of this.props.milestones) {
            if (milestone.percent !== '') {
                total += parseInt(milestone.percent)
            }
        }
        return total
    }

    componentDidMount = () => {
        if (this.props.milestones.length === 0) {
            this.props.addMilestone()
        }
    }

    render = () => {
        const total = this.percentTotal()
        let totalClass = classes.warning
        if (total === 100) {
            totalClass = classes.success
        }

        return (
            <div>
                {this.props.milestones.map((milestone, index) => (
                    <EstimateMilestone
                        key={milestone.id}
                        milestone={milestone}
                        index={index}
                        moveUp={this.props.moveUp}
                        moveDown={this.props.moveDown}
                        deleteMilestone={this.props.deleteMilestone}
                        handleChange={this.props.handleChange}
                    />
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.props.addMilestone}
                    className={classes.addMilestoneButton}
                >
                    <AddCircleIcon className={classes.buttonIcon} />
                    Add a Milestone
                </Button>
                <Typography variant="h6" className={totalClass}>
                    {`Total: ${total}%`}
                </Typography>
            </div>
        )
    }
}

export default EstimateMilestonesList
