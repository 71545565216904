import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

import classes from './ProviderProfile.module.sass'


const ProviderProfile = (props) => {
    const providerGroup = props.provider
    const providerLocation = providerGroup.location

    return (
        <Dialog
            onClose={props.handleClose}
            aria-labelledby="provider-profile-dialog"
            open={props.open}
            fullWidth={true}
            maxsize="md"
        >
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h6" id="provider-profile-dialog">
                    {providerGroup.name ? providerGroup.name : 'Pending'}
                </Typography>
                <IconButton aria-label="Close" className={classes.closeButton} onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {providerGroup.logo_file ? (
                    <img src={providerGroup.logo_file} className={classes.logo} alt="Provider Logo" />
                ) : null}

                <Typography variant="h6" className={classes.title}>
                    Website
                </Typography>
                <Typography gutterBottom variant="body1">
                    {providerGroup.website}
                </Typography>

                <Typography variant="h6" className={classes.title}>
                    Phone Number
                </Typography>
                <Typography gutterBottom variant="body1">
                    {providerGroup.phone}
                </Typography>

                <Typography variant="h6" className={classes.title}>
                    Capabilities
                </Typography>
                <div dangerouslySetInnerHTML={{
                    __html: providerGroup.skill_description }}
                    className={classes.inlineP}
                />

                <Typography variant="h6" className={classes.title}>
                    About Us
                </Typography>
                <div dangerouslySetInnerHTML={{
                    __html: providerGroup.about_us }}
                    className={classes.inlineP}
                />

                <Typography variant="h6" className={classes.title}>
                    Our Team
                </Typography>
                <div dangerouslySetInnerHTML={{
                    __html: providerGroup.our_team }}
                    className={classes.inlineP}
                />

                <Typography variant="h6" className={classes.title}>
                    Location
                </Typography>
                <Typography gutterBottom variant="body1">
                    {providerLocation ? (
                        <div>
                            {
                                providerLocation.street1 + ' ' + 
                                providerLocation.street2 + ' ' +
                                providerLocation.city + ', ' +
                                providerLocation.state_code + ' ' +
                                providerLocation.postal_code
                            }
                        </div>
                    ) : null}
                </Typography>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProviderProfile