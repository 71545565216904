import React, { Component } from 'react'
import { Colors } from './colors'
import { Bar } from 'react-chartjs-2'
import Typography from '@material-ui/core/Typography'

import IntervalSelect from './IntervalSelect'

import classes from './Charts.module.sass'


class InvoicesChart extends Component {
    state = {
        data: [3, 2, 8]
    }

    getRandomInt = (min, max) => {
        return Math.floor((Math.random() * ((max + 1) - min)) + min)
    }

    setRandomData = () => {
        const max = this.getRandomInt(10, 15)
        const newData = this.state.data.map(_ => this.getRandomInt(1, max))
        this.setState({
            data: newData
        })
    }

    render = () => {
        return (
            <div style={{ padding: 16}}>
                <Typography variant="h3" className={classes.chartTitle}>
                    Invoices

                    <IntervalSelect
                        name="invoicesInterval"
                        id="invoicesInterval"
                        onChange={this.setRandomData}
                    />
                </Typography>

                <Bar
                    data={{
                        labels: ['Pending', 'Past Due', 'Paid'],
                        datasets: [
                            {

                                label: 'Number of Invoices',
                                data: this.state.data,
                                backgroundColor: [
                                    Colors.Yellow,
                                    Colors.Red,
                                    Colors.Green,
                                ],
                                borderColor: [
                                    Colors.YellowBorder,
                                    Colors.RedBorder,
                                    Colors.GreenBorder,
                                ],
                            }
                        ],

                    }}
                    options={{
                        legend: {
                            position: 'bottom',
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero:true,
                                    callback: (value) => {
                                        if (value % 1 === 0) {
                                            return value
                                        }
                                    },
                                },
                            }]
                        },
                    }}
                    width={400}
                    height={400}
                />
            </div>
        )
    }
}


export default InvoicesChart
