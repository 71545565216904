import axios from 'axios'

import { retrieveFromStorage } from '../../src/store/state'


export const SET_USER_INFO = 'SET_USER_INFO'


export const getUserInfo = (dispatch, data) => {
    axios.get('/api/users/user_info/', {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        const data = response.data
        const update = {
            userId: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            username: data.email,
            notificationEmail: data.notification_email,
            phone: data.phone,
            title: data.title,
            companyAdmin: data.is_company_admin,
            providerAdmin: data.is_provider_admin,
            // Defaults
            companyId: null,
            companyName: '',
            companyLogo: '',
            providerId: null,
            providerName: '',
            providerLogo: '',
            organizationName: '',
        }
        if (data.company !== null) {
            update.companyId = data.company.id
            update.companyName = data.company.name
            update.organizationName = data.company.name
            update.companyLogo = data.company.logo_file
        }
        if (data.provider_group !== null) {
            update.providerId = data.provider_group.id
            update.providerName = data.provider_group.name
            update.organizationName = data.provider_group.name
            update.providerLogo = data.provider_group.logo_file
        }
        for (let key of Object.keys(update)) {
            if (retrieveFromStorage.inArray(key)) {
                sessionStorage.setItem(key, update[key])
            }
        }
        dispatch({
            type: SET_USER_INFO,
            update: update,
        })
    })
    .catch(response => {
        console.log(response)
    })
}


export const updateMyInfo = (dispatch, data) => {
    const url = `/api/users/${data.userId}/`
    axios.patch(url, data.formData, {
        headers: {
            Authorization: `Token ${data.token}`
        }
    })
    .then(response => {
        getUserInfo(dispatch, { token: data.token })
    })
    .catch(thrown => {
        console.log(thrown.message)
    })
}
