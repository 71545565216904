import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
// Material UI
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
// Icons
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
// import CheckCircleIcon from '@material-ui/icons/CheckCircle'
// Custom components
import * as vendorActions from '../actions/vendorActions'
import ProviderProfile from '../../providers/components/ProviderProfile'
// Styling
import classes from './CompanyProvidersTab.module.sass'


class CompanyProvidersTab extends Component {
    state = {
        providerProfile: {},
        providerProfileOpen: false
    }

    viewProviderProfile = (event, providerId) => {
        event.preventDefault()
        event.stopPropagation()
        axios.get(`/api/providers/${providerId}/`, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            this.setState({
                providerProfile: response.data,
                providerProfileOpen: true
            })
        })
        
    }

    closeProviderProfile = () => {
        this.setState({ providerProfileOpen: false })
    }

    changeStatus = (event, vendor) => {
        event.preventDefault()
        this.props.updateVendor({
            id: vendor.vendor_id,
            active: !vendor.active
        }, this.props)
    }

    handleSort = (sort) => {
        this.props.sortVendors(sort, this.props)
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Company Providers
                </Typography>
                <ProviderProfile
                    handleClose={this.closeProviderProfile}
                    provider={this.state.providerProfile}
                    open={this.state.providerProfileOpen}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.vendorsOrderBy === 'provider_group__name'}
                                        direction={this.props.vendorsOrder}
                                        onClick={() => this.handleSort(
                                            'provider_group__name'
                                        )}
                                    >
                                        Name
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.vendorsOrderBy === 'provider_group__admin__last_name'}
                                        direction={this.props.vendorsOrder}
                                        onClick={() => this.handleSort(
                                            'provider_group__admin__last_name'
                                        )}
                                    >
                                        Primary Contact
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.vendorsOrderBy === 'provider_group__admin__notification_email'}
                                        direction={this.props.vendorsOrder}
                                        onClick={() => this.handleSort(
                                            'provider_group__admin__notification_email'
                                        )}
                                    >
                                        Email
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.vendorsOrderBy === 'provider_group__admin__phone'}
                                        direction={this.props.vendorsOrder}
                                        onClick={() => this.handleSort(
                                            'provider_group__admin__phone'
                                        )}
                                    >
                                        Phone
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.vendorsOrderBy === 'active'}
                                        direction={this.props.vendorsOrder}
                                        onClick={() => this.handleSort(
                                            'active'
                                        )}
                                    >
                                        Status
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
               
                    <TableBody>
                        {this.props.vendors.map((vendor) => (
                            <TableRow key={vendor.id}>
                                <TableCell className={classes.tableCell}>
                                    {vendor.name}
                                    {vendor.has_profile ? (
                                        <a 
                                            href="#providerProfile"
                                            className={classes.link}
                                            onClick={(e) => this.viewProviderProfile(e, vendor.id)}
                                        >
                                            <OpenInNewIcon fontSize="small" className={classes.linkIcon} />
                                            View Profile
                                        </a>
                                    ) : null}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {vendor.first_name + ' ' + vendor.last_name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {vendor.email}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {vendor.phone}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <a
                                        href="#status/{vendor.id}"
                                        className={classes.link}
                                        onClick={(e) => this.changeStatus(e, vendor)}
                                    >
                                        {vendor.active ? 'Active' : 'Disabled'}
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    updateVendor: (formData, data) => vendorActions.updateVendor(
        dispatch, formData, data
    ),
    sortVendors: (sort, data) => vendorActions.sortVendors(
        dispatch, sort, data
    ),
})


export default connect(mapStateToProps, mapDispatchToProps)(CompanyProvidersTab)

