import React, { Component } from 'react'
import { connect } from 'react-redux'
// Material UI
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Divider from '@material-ui/core/Divider'
// Custom components
import * as companyActions from '../actions/companyActions'
// Styling
import classes from './ManageCustomFields.module.sass'


class ManageCustomFields extends Component {
    state = {
        id: null,
        field_name: "",
        active: true
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    changeStatus = (event, customField) => {
        event.preventDefault()
        this.props.updateCustomField({
            id: customField.id,
            active: !customField.active,
        }, this.props)
    }

    edit = (event, customField) => {
        event.preventDefault()
        this.setState(customField)
    }

    save = (event) => {
        const formData = {...this.state}
        formData.company = this.props.companyId
        if (formData.id === null) {
            this.props.addCustomField(formData, this.props)
        } else {
            this.props.updateCustomField(formData, this.props)
        }
        
        this.setState({
            id: null,
            field_name: "",
            active: true,
        })
    }

    render = () => {
        return (
             <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="custom-fields-dialog"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="custom-fields-dialog">
                    Manage Custom Fields
                </DialogTitle>

                <DialogContent style={{ paddingTop: 8}}>
                    <div className={classes.formRow}>
                        <TextField
                            id="field_name"
                            name="field_name"
                            label="Field Name"
                            value={this.state.field_name}
                            type="text"
                            className={classes.field}
                            onChange={this.updateField}
                        />
                        <Button
                            onClick={this.save}
                            color="primary"
                            variant="contained"
                            className={classes.uploadButton}
                        >
                            {this.state.id === null ? 'Add Field' : 'Save Field'}
                        </Button>
                    </div>
                    <Typography variant="h6" style={{ marginTop: 16 }}>
                        Custom Fields
                    </Typography>

                    <Divider />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    Field Name
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Status
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Edit
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.props.customFields.map((customField) => (
                                <TableRow key={customField.id}>
                                    <TableCell className={classes.tableCell}>
                                        {customField.field_name}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <a
                                            href={`#status/${customField.id}`}
                                            className={classes.link}
                                            onClick={(e) => this.changeStatus(e, customField)}
                                        >
                                            {customField.active ? 'Active' : 'Disabled'}
                                        </a>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <a
                                            href={`#edit/${customField.id}`}
                                            className={classes.link}
                                            onClick={(e) => this.edit(e, customField)}
                                        >
                                            Edit
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary" className={classes.dialogButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    updateCustomField: (formData, data) => companyActions.updateCustomField(
        dispatch, formData, data
    ),
    addCustomField: (formData, data) => companyActions.addCustomField(
        dispatch, formData, data
    ),
})


export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomFields)

