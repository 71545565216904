import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import classes from './CandidateForm.module.sass'


const CandidateForm = (props) => (
    <Dialog
        onClose={props.handleClose}
        aria-labelledby="candidate-form"
        open={props.open}
        fullWidth={true}
        maxWidth={'md'}
    >
        <MuiDialogTitle id="candidate-form" disableTypography className={classes.root}>
            <Typography variant="h6">
                Candidate Form
            </Typography>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={props.handleClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
        <MuiDialogContent>
            Hello!
        </MuiDialogContent>
        <MuiDialogActions>
            <Button onClick={props.handleClose} color="primary">
                Save changes
            </Button>
        </MuiDialogActions>
    </Dialog>
)

export default CandidateForm