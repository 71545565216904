import React, { Component } from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import classes from './InvoicingMilestonesTab.module.sass'


class InvoicingMilestonesTab extends Component {
    state = {}

    formatDate = (dateString) => {
        let date = new moment(dateString)
        if (date.isValid()) {
            return date.format('MM/DD/YYYY')
        }
        return ''
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Milestones
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                #
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Title
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Content
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Percent
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Completed
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Complete
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.milestones.map((milestone) => {
                            return (
                                <TableRow key={milestone.id}>
                                    <TableCell className={classes.tableCell}>
                                        {milestone.order}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {milestone.title}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {milestone.description}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {milestone.percent}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(milestone.completed_date)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        Done
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


export default InvoicingMilestonesTab

