import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'
import moment from 'moment'

import classes from './JobList.module.sass'


class JobList extends Component {

    dateDisplay = (dateString) => {
        let dateTime = new moment(dateString)
        if (dateTime.isValid()) {
            return dateTime.format('MM/DD/YYYY')
        }
        return "Not started"
    }

    render = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>
                            <Tooltip
                                title="Sort"
                                placement={'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={this.props.orderBy === 'name'}
                                    direction={this.props.order}
                                    onClick={() => this.props.handleSort('name')}
                                >
                                    Project name
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            <Tooltip
                                title="Sort"
                                placement={'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={this.props.orderBy === 'customer__last_name'}
                                    direction={this.props.order}
                                    onClick={() => this.props.handleSort('customer__last_name')}
                                >
                                    Customer name
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            <Tooltip
                                title="Sort"
                                placement={'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={this.props.orderBy === 'status_text'}
                                    direction={this.props.order}
                                    onClick={() => this.props.handleSort('status_text')}
                                >
                                    Status
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            <Tooltip
                                title="Sort"
                                placement={'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={this.props.orderBy === 'request_date'}
                                    direction={this.props.order}
                                    onClick={() => this.props.handleSort('request_date')}
                                >
                                    Opened
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            <Tooltip
                                title="Sort"
                                placement={'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={this.props.orderBy === 'provider_group__name'}
                                    direction={this.props.order}
                                    onClick={() => this.props.handleSort('provider_group__name')}
                                >
                                    Vendor
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
           
                <TableBody>
                    {this.props.jobs.map((job) => (
                        <TableRow key={job.id}>
                            <TableCell className={classes.tableCell} style={{ maxWidth: 240}}>
                                <Link to={'/jobs/' + job.id} className={classes.link}>
                                    {job.name ? job.name : 'Not named'}
                                </Link>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {job.customer.fullname}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {job.status_text}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {this.dateDisplay(job.request_date)}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {job.provider_group ? job.provider_group.name : null}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
             </Table>
        )
    }
}

export default JobList