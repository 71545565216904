import { connect } from 'react-redux'
import EditProfile from '../../users/components/EditProfile'
import * as providerActions from '../actions/providerActions'


class AddProviderMember extends EditProfile {
    state = {
        first_name: "",
        last_name: "",
        notification_email: "",
        email: "",
        phone: "",
        title: "",
        is_provider_admin: false
    }

    save = (event) => {
        const formData = {...this.state}
        formData.email = formData.notification_email
        this.props.addMember(formData)
        this.props.handleClose(event)
    }

    dialogTitle = () => {
        return `Add New Member to ${this.props.providerName}`
    }

    emailLabel = () => {
        return 'Email'
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    addMember: (data) => providerActions.addMember(dispatch, data),
})


export default connect(mapStateToProps, mapDispatchToProps)(AddProviderMember)
