import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import classes from './ProviderCustomersTab.module.sass'


class ProviderCustomersTab extends Component {
    state = {}

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Customers
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'last_name'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'last_name', 'customers'
                                        )}
                                    >
                                        Name
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'title'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'title', 'customers'
                                        )}
                                    >
                                        Title
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'notification_email'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'notification_email', 'customers'
                                        )}
                                    >
                                        Email
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'phone'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'phone', 'customers'
                                        )}
                                    >
                                        Phone
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'company__name'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'company__name', 'customers'
                                        )}
                                    >
                                        Company
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
               
                    <TableBody>
                        {this.props.customers.map((customer) => (
                            <TableRow key={customer.id}>
                                <TableCell className={classes.tableCell}>
                                    {customer.fullname}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {customer.title}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {customer.email}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {customer.phone}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {customer.company.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


export default ProviderCustomersTab