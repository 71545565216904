
export const Colors = {
    Green: 'rgba(76, 175, 80, 0.57)',
    GreenBorder: 'rgba(76, 175, 80, 0.2)',
    Blue: 'rgba(41, 121, 255, 0.57)',
    BlueBorder: 'rgba(41, 121, 255, 0.2)',
    Yellow: 'rgba(255, 203, 93, 0.57)',
    YellowBorder: 'rgba(255, 203, 93, 0.2)',
    Orange: 'rgba(245, 132, 38, 0.57)',
    OrangeBorder: 'rgba(245, 132, 38, 0.2)',
    // Purple: 'rgba(90, 64, 182, 0.57)',
    // PurpleBorder: 'rgba(90, 64, 182, 0.2)',
    Red: 'rgba(238, 49, 36, 0.57)',
    RedBorder: 'rgba(238, 49, 36, 0.2)'
}


// Jrobb colors:
// '#FFE0B2', '#FFB74D', '#FF9800', '#F57C00'

// Total Company Users
// Total Company Vendors
// Total Company Admins
// Total Company contractors
