import React, { Component } from 'react'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CKEditor from 'react-ckeditor-component'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
// import Snackbar from '@material-ui/core/Snackbar'
// import SnackbarContent from '@material-ui/core/SnackbarContent'
// import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import AddCircleIcon from '@material-ui/icons/AddCircle'

import classes from './InvoicingExpensesTab.module.sass'


class InvoicingExpensesTab extends Component {
    state = {
        amount: '',
        date: null,
        description: '',
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    updateDescription = (event) => {
        const currentDescription = event.editor.getData();
        this.setState({
            description: currentDescription
        })
    }

    formatDate = (dateString) => {
        let date = new moment(dateString)
        if (date.isValid()) {
            return date.format('MM/DD/YYYY')
        }
        return ''
    }

    addExpense = () => {
        let formData = new FormData()
        formData.append('amount', Math.ceil(this.state.amount))
        formData.append('date', moment(this.state.date).format())
        formData.append('description', this.state.description)
        formData.append('job', this.props.jobId)
        this.props.addExpense(formData, () => {
            this.setState({
                amount: '',
                date: null,
                description: '',
            })
        })
    }

    renderProviderForm = () => {
        return (
            <React.Fragment>
                <Typography variant="h6" className={classes.dtitle}>
                    Add an Expense
                </Typography>
                <Paper className={classes.section}>
                    <Grid container space={16} className={classes.formRow}>
                        <Grid item md={6} className={classes.gridField}>
                            <TextField
                                id="amount"
                                name="amount"
                                label="Amount"
                                value={this.state.amount}
                                type="number"
                                step="1"
                                fullWidth
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item md={6} className={classes.gridField}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    name="date"
                                    id="date"
                                    label="Date"
                                    fullWidth
                                    placeholder="Optional"
                                    value={this.state.date}
                                    onChange={(date) => this.updateField({ target: { name: 'date', value: date }})}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container space={16} className={classes.formRow}>
                        <Grid item sm={12} className={classes.gridField}>
                            <CKEditor
                                activeClass={classes.ckeditor}
                                content={this.state.description} 
                                events={{
                                    "blur": this.updateDescription,
                                    "change": this.updateDescription,
                                    "keyup": this.updateDescription
                                }}
                                config={{
                                    disableNativeSpellChecker: false,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ textAlign: 'right' }}>
                        <Button 
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.addExpense}
                            className={classes.formButton}
                        >
                            <AddCircleIcon className={classes.buttonIcon} />
                            Submit Expense
                        </Button>
                    </div>
                </Paper>
            </React.Fragment>
        )
    }

    render = () => {
        return (
            <div>
                {this.props.userType === 'Provider' ? this.renderProviderForm() : null}
                <Typography variant="h6" className={classes.dtitle}>
                    Expenses
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'date', 'expenses'
                                        )}
                                    >
                                        Date
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'amount'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'amount', 'expenses'
                                        )}
                                    >
                                        Amount
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Description
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'paid_date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'paid_date', 'expenses'
                                        )}
                                    >
                                        Paid Date
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.expenses.map((expense) => {
                            return (
                                <TableRow key={expense.id}>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(expense.date)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {"$" + expense.amount}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <div dangerouslySetInnerHTML={{
                                            __html: expense.description }}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(expense.paid_date)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


export default InvoicingExpensesTab

