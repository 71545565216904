import React, { Component } from 'react'
import { Colors } from './colors'
import { Bar } from 'react-chartjs-2'
import Typography from '@material-ui/core/Typography'

import classes from './Charts.module.sass'


class UsersChart extends Component {
    state = {}

    render = () => {
        return (
            <div style={{ padding: 16}}>
                <Typography variant="h3" className={classes.chartTitle}>
                    {this.props.companyId ? (
                        "Company Accounts"
                    ) : (
                        "Provider Group Accounts"
                    )}
                </Typography>

                <Bar
                    data={{
                        // For Providers, Vendors > Customers
                        // Contractors > Placements
                        labels: ['Users', 'Admins', 'Vendors', 'Contractors'],
                        datasets: [
                            {
                                label: 'Number of Users',
                                data: [11, 2, 5, 3],
                                backgroundColor: [
                                    Colors.Green,
                                    Colors.Red,
                                    Colors.Yellow,
                                    Colors.Blue,
                                ],
                                borderColor: [
                                    Colors.GreenBorder,
                                    Colors.RedBorder,
                                    Colors.YellowBorder,
                                    Colors.BlueBorder,
                                ],
                            }
                        ],

                    }}
                    options={{
                        legend: {
                            position: 'bottom',
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero:true,
                                },
                            }]
                        },
                    }}
                    width={400}
                    height={400}
                />
            </div>
        )
    }
}


export default UsersChart
