import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
// Material UI
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
// Custom components
import LeftNavBar from '../../src/components/LeftNavBar'
import {
    DefaultCustomerMenu,
    DefaultExpertMenu
} from '../../src/components/DefaultNavBarMenus'
import JobList from '../../jobs/components/JobList'
import SearchBar from './SearchBar'
import * as jobActions from '../../jobs/actions/jobActions'
// Styling
import classes from './MyProjects.module.sass'


class MyProjects extends Component {

    state = {
        filter_vendor: '',
    }

    componentDidMount = () => {}

    handleCheck = (event, field) => {
        const value = !this.state[field]
        this.setState({ 
            [field]: value
        });
    }

    handleChange = (event) => {
        const key = event.target.name
        const value = event.target.value
        switch (key) {
            case 'filter_customer':
                this.props.setJobsCustomerFilter(value, this.props)
                break
            case 'filter_vendor':
                this.props.setJobsVendorFilter(value, this.props)
                break
            default:
                this.setState({
                    [key]: value
                })
        }
    }

    handleDateChange = (key, date) => {
        if (key === 'filter_start_date') {
            this.props.setJobsStartFilter(date, this.props)
        }
        if (key === 'filter_end_date') {
            this.props.setJobsEndFilter(date, this.props)
        }
    }

    render = () => {
        if (!this.props.loggedIn) {
            return <Redirect push to="/" />
        }

        return (
            <div className={classes.root}>
                <LeftNavBar>
                    {this.props.companyId ? <DefaultCustomerMenu /> : null}
                    {this.props.providerId ? <DefaultExpertMenu /> : null}
                </LeftNavBar>

                <Paper className={classes.newContent}>
                    <Grid container spacing={16} className={classes.formGrid}>
                        <Grid item md={6}>
                            <FormGroup row style={{ marginTop: 8 }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!this.props.jobsMeOnlyFilter}
                                            onChange={() => this.props.setMeOnlyFilter(this.props)}
                                            value="showAll"
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="body1"
                                        >
                                            Me
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                />
                                <label
                                    className={classes.label}
                                    onClick={() => this.props.setMeOnlyFilter(this.props)}
                                >
                                    <Typography
                                        variant="body1"
                                    >
                                        {
                                            `All ${this.props.companyName}`
                                        }
                                    </Typography>
                                </label>

                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <SearchBar
                                label=""
                                placeholder="Search by project name, company, vendor, or people"
                                token={this.props.token}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={16} className={classes.formGrid}>
                        <Grid item lg={3}>
                            <FormLabel component="legend" style={{ marginLeft: 8 }}>
                                Filter by Project Status
                            </FormLabel>
                            <FormControl component="fieldset" style={{ padding: '2px 8px' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.jobStatusFilters['In Progress']}
                                                onChange={() => this.props.setJobStatusFilter(
                                                    'In Progress', this.props
                                                )}
                                                value="filter_in_progress"
                                                className={classes.filterCheckbox}
                                            />
                                        }
                                        label="In Progress"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.jobStatusFilters['In Review']}
                                                onChange={() => this.props.setJobStatusFilter(
                                                    'In Review', this.props
                                                )}
                                                value="filter_in_review"
                                                className={classes.filterCheckbox}
                                            />
                                        }
                                        label="In Review"
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl component="fieldset" style={{ padding: '0 8px' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.jobStatusFilters['Draft']}
                                                onChange={() => this.props.setJobStatusFilter(
                                                    'Draft', this.props
                                                )}
                                                value="filter_draft"
                                                className={classes.filterCheckbox}
                                            />
                                        }
                                        label="Draft"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.props.jobStatusFilters['Closed']}
                                                onChange={() => this.props.setJobStatusFilter(
                                                    'Closed', this.props
                                                )}
                                                value="filter_closed"
                                                className={classes.filterCheckbox}
                                            />
                                        }
                                        label="Closed"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>

                        <Grid item lg={3}>
                            <FormLabel component="legend">
                                Filter By Request Date
                            </FormLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl fullWidth>
                                    <DatePicker
                                        keyboard
                                        clearable
                                        name="filter_start_date"
                                        id="filter_start_date"
                                        fullWidth
                                        placeholder="MM/DD/YYYY (Start)"
                                        format="MM/dd/yyyy"
                                        value={this.props.jobsStartFilter}
                                        mask={value =>
                                            value ? [
                                            /\d/, /\d/, '/',        // MM
                                            /\d/, /\d/, '/',        // DD
                                            /\d/, /\d/, /\d/, /\d/,  // YYYY
                                            ] : []
                                        }
                                        onChange={value => this.handleDateChange(
                                            'filter_start_date', value
                                        )}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        className={classes.select}

                                    />
                                    <DatePicker
                                        keyboard
                                        clearable
                                        name="filter_end_date"
                                        id="filter_end_date"
                                        fullWidth
                                        placeholder="MM/DD/YYYY (End)"
                                        format="MM/dd/yyyy"
                                        value={this.props.jobsEndFilter}
                                        mask={value =>
                                            value ? [
                                            /\d/, /\d/, '/',        // MM
                                            /\d/, /\d/, '/',        // DD
                                            /\d/, /\d/, /\d/, /\d/,  // YYYY
                                            ] : []
                                        }
                                        onChange={value => this.handleDateChange(
                                            'filter_end_date', value
                                        )}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        className={classes.select}
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item lg={3}>
                            <FormLabel component="legend">
                                Filter By Customer
                            </FormLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={this.props.jobsCustomerFilter}
                                    onChange={this.handleChange}
                                    className={classes.select}
                                    inputProps={{
                                        id: 'filter_customer',
                                        name: 'filter_customer',
                                    }}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {this.props.members_list.map((member) => (
                                        <MenuItem key={member.id} value={member.id}>
                                            {member.fullname}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3}>
                            <FormLabel component="legend">
                                Filter By Vendor
                            </FormLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={this.props.jobsVendorFilter}
                                    onChange={this.handleChange}
                                    className={classes.select}
                                    inputProps={{
                                        id: 'filter_vendor',
                                        name: 'filter_vendor',
                                    }}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {this.props.vendors_list.map((vendor) => (
                                        <MenuItem key={vendor.id} value={vendor.id}>
                                            {vendor.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>


                    <hr className={classes.divider} />

                    <JobList
                        jobs={this.props.jobs}
                        orderBy={this.props.jobsOrderBy}
                        order={this.props.jobsOrder}
                        type="active"
                        handleSort={sort => this.props.sortJobs(sort, this.props)}
                        style={{
                            marginLeft: -8,
                            marginRight: -8,
                        }}
                    />
                    
                </Paper>
            </div>

        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    loadJobs: (data) => jobActions.loadJobs(dispatch, data),
    sortJobs: (sort, data) => jobActions.sortJobs(dispatch, sort, data),
    setJobStatusFilter: (status, data) => jobActions.setJobStatusFilter(
        dispatch, status, data
    ),
    setMeOnlyFilter: (data) => jobActions.setMeOnlyFilter(dispatch, data),
    setJobsStartFilter: (startDate, data) => jobActions.setJobsStartFilter(
        dispatch, startDate, data
    ),
    setJobsEndFilter: (endDate, data) => jobActions.setJobsEndFilter(
        dispatch, endDate, data
    ),
    setJobsCustomerFilter: (customerId, data) => (
        jobActions.setJobsCustomerFilter(dispatch, customerId, data)
    ),
    setJobsVendorFilter: (vendorId, data) => (
        jobActions.setJobsVendorFilter(dispatch, vendorId, data)
    ),
})


export default connect(mapStateToProps, mapDispatchToProps)(MyProjects)
