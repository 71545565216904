import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

// import Snackbar from '@material-ui/core/Snackbar'
// import SnackbarContent from '@material-ui/core/SnackbarContent'
// import IconButton from '@material-ui/core/IconButton'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import CancelIcon from '@material-ui/icons/Cancel'

import classes from './JobCollaboratorsTab.module.sass'


class JobCollaboratorsTab extends Component {
    state = {
        collaborator: ''

    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    addCollaborator = () => {
        let formData = new FormData()
        formData.append('inviter', this.props.userId)
        formData.append('user_id', this.state.collaborator)
        formData.append('job', this.props.jobId)
        this.props.addCollaborator(formData)
    }

    getAvailableMembers = () => {
        const collab_ids = this.props.collaborators.map(col => col.user.id)
        let availableMembers = []
        for (let member of this.props.members) {
            if (collab_ids.indexOf(member.id) === -1) {
                availableMembers.push(member)
            }
        }
        return availableMembers
    }

    render = () => {
        const availableMembers = this.getAvailableMembers()

        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Add Collaborator
                </Typography>
                <Paper className={classes.section}>
                    <div className={classes.formRow}>
                        <FormControl className={classes.field}>
                            <InputLabel htmlFor="collaborator">
                                Select a Member
                            </InputLabel>
                            <Select
                                value={this.state.collaborator}
                                onChange={this.updateField}
                                inputProps={{
                                    name: 'collaborator',
                                    id: 'collaborator',
                                }}
                                fullWidth
                            >
                                <MenuItem key={0} value="">
                                    <em>Select a member</em>
                                </MenuItem>
                                {availableMembers.map((member) => {
                                    return (
                                        <MenuItem key={member.id} value={member.id}>
                                            {member.fullname}
                                        </MenuItem>
                                    )
                                })}
                                <MenuItem key={-1} value="-1">
                                    <span className={classes.link}>
                                        Add a member to your company
                                    </span>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.addButton}
                            onClick={this.addCollaborator}
                        >
                            Add Collaborator
                        </Button>
                    </div>
                </Paper>

                <Typography variant="h6" className={classes.dtitle}>
                    Collaborators
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'user__last_name'}
                                        direction={this.props.order}
                                        onClick={() => {
                                            this.props.handleSort(
                                                'user__last_name', 'collaborators'
                                            )
                                        }}
                                    >
                                        Name
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'user__title'}
                                        direction={this.props.order}
                                        onClick={() => {
                                            this.props.handleSort(
                                                'user__title', 'collaborators'
                                            )
                                        }}
                                    >
                                        Title
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'user__notification_email'}
                                        direction={this.props.order}
                                        onClick={() => {
                                            this.props.handleSort(
                                                'user__notification_email', 'collaborators'
                                            )
                                        }}
                                    >
                                        Email
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'user__phone'}
                                        direction={this.props.order}
                                        onClick={() => {
                                            this.props.handleSort(
                                                'user__phone', 'collaborators'
                                            )
                                        }}
                                    >
                                        Phone
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Role
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                ...
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.tableCell}>
                                <strong>{this.props.customer.fullname}</strong>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {this.props.customer.title}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {this.props.customer.notification_email}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                {this.props.customer.phone}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <strong>Customer lead</strong>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {this.props.expert.id ? (
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    <strong>{this.props.expert.fullname}</strong>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {this.props.expert.title}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {this.props.expert.notification_email}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {this.props.expert.phone}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <strong>Provider lead</strong>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        ) : null}
                        {this.props.collaborators.map((collaborator) => {
                            return (
                                <TableRow key={collaborator.id}>
                                    <TableCell className={classes.tableCell}>
                                        {collaborator.user.fullname}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {collaborator.user.title}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {collaborator.user.notification_email}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {collaborator.user.phone}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {collaborator.role}
                                    </TableCell>
                                    <TableCell>
                                        <a
                                            href={`#remove/${collaborator.id}`}
                                            className={classes.link}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                this.props.removeCollaborator(collaborator.id)
                                            }}
                                        >
                                            <CancelIcon />
                                        </a>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


export default JobCollaboratorsTab
