import * as loginActions from '../../users/actions/loginActions'
import * as userActions from '../../users/actions/userActions'
import * as jobActions from '../../jobs/actions/jobActions'

import { initialState, retrieveFromStorage } from './state'


const loadState = {
    ...initialState,
    errors: {...initialState.errors},
}


for (let key of retrieveFromStorage) {
    if (sessionStorage.getItem(key)) {
        let value = sessionStorage.getItem(key)
        switch (value) {
            case 'null':
                value = null
                break
            case 'false':
                value = false
                break
            case 'true':
                value = true
                break
            default:
                break
        }
        loadState[key] = value
    }
}


const reducer = (state=loadState, action) => {
    switch (action.type) {
        case loginActions.LOGIN:
            return {
                ...state,
                token: action.token,
                loggedIn: action.loggedIn,
                errors: action.errors,
            }
        case loginActions.REMOVE_ERROR:
            let errors = {...state.errors}
            delete errors[action.fieldName]
            return {
                ...state,
                errors: errors,
            }
        case loginActions.LOGOUT:
            return initialState
        case userActions.SET_USER_INFO:
            return {
                ...state,
                ...action.update,
            }
        case jobActions.LOAD_JOBS:
            return {
                ...state,
                jobs: action.update.jobs,
                jobsCount: action.update.count,
                jobsNext: action.update.next,
            }
        case jobActions.LOAD_MORE_JOBS:
            return {
                ...state,
                jobs: [
                    ...state.jobs,
                    ...action.update.jobs,
                ],
            }
        case jobActions.SORT_JOBS:
            return {
                ...state,
                ...action.update,
            }
        case jobActions.SET_JOB_STATUS_FILTER:
            return {
                ...state,
                jobStatusFilters: {
                    ...state.jobStatusFilters,
                    ...action.update,
                }
            }
        case jobActions.SET_ME_ONLY_FILTER:
            return {
                ...state,
                ...action.update,
            }
        default:
            if (action.hasOwnProperty('update')) {
                return {
                    ...state,
                    ...action.update,
                }
            }
            return state
    }
}


export default reducer
