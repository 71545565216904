import React, { Component } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import EditIcon from '@material-ui/icons/Edit'

import EditCompany from './EditCompany'

import classes from './CompanyProfileTab.module.sass'


class CompanyProfileTab extends Component {
    state = {
        editCompanyOpen: false
    }

    handleCompanyOpen = () => {
        this.setState({ editCompanyOpen: true })
    }

    handleCompanyClose = () => {
        this.setState({ editCompanyOpen: false })
    }

    render = () => {

        let companyLocation = {}
        if (this.props.company) {
            companyLocation = this.props.company.primary_address
        }

        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    {`My Company (${this.props.companyName})`}
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.rightFloatButton}
                        onClick={this.handleCompanyOpen}
                    >
                        <EditIcon className={classes.buttonIcon} fontSize="small" />
                        Edit
                    </Button>
                    <EditCompany
                        open={this.state.editCompanyOpen}
                        handleClose={this.handleCompanyClose}
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}
                    />
                </Typography>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Logo
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        <img src={this.props.companyLogo} className={classes.ddLogo} alt="Company Logo" />
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Name
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.companyName}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Website
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.company.website}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Phone Number
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.company.phone}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        About Us
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        <div dangerouslySetInnerHTML={{
                            __html: this.props.company.about_us }}
                            className={classes.inlineP}
                        />
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Location
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {companyLocation ? (
                            <div>
                                {
                                    companyLocation.street1 + ' ' + 
                                    companyLocation.street2 + ' ' +
                                    companyLocation.city + ', ' +
                                    companyLocation.state_code + ' ' +
                                    companyLocation.postal_code
                                }
                            </div>
                        ) : null}
                    </Typography>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileTab)
