import axios from 'axios'

import { retrieveFromStorage } from '../../src/store/state'
import { getUserInfo } from './userActions'


export const REMOVE_ERROR = 'REMOVE_ERROR'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'


export const login = (dispatch, data) => {
    axios.post(
        '/api/token-auth/', data
    )
    .then(response => {
        sessionStorage.setItem('token', response.data.token)
        sessionStorage.setItem('loggedIn', true)
        dispatch({
            type: LOGIN,
            token: response.data.token,
            loggedIn: true,
            errors: {},

        })
        getUserInfo(dispatch, { token: response.data.token })
    })
    .catch(errors => {
        if (typeof errors.response !== 'undefined') {
             errors = errors.response.data
        }
        dispatch({
            type: LOGIN,
            token: null,
            loggedIn: false,
            errors: errors,
        })
    })
}


export const logout = (dispatch) => {
    for (let key of retrieveFromStorage) {
        sessionStorage.removeItem(key)
    }
    dispatch({
        type: LOGOUT
    })
}
