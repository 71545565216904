import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import EditProfile from '../../users/components/EditProfile'

import classes from './ProviderMembersTab.module.sass'


class ProviderMembersTab extends Component {
    state = {
        editProfileOpen: false,
        activeProfile: null
    }

    changeStatus = (event, member) => {
        event.preventDefault()
        this.props.updateMember(member.id, {
            active: !member.active
        })
    }

    handleProfileOpen = (profile) => {
        this.setState({
            editProfileOpen: true,
            activeProfile: profile
        })
    }

    handleProfileClose = () => {
        this.setState({
            editProfileOpen: false,
            activeProfile: null
        })
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Members
                </Typography>
                <EditProfile
                    open={this.state.editProfileOpen}
                    profile={this.state.activeProfile}
                    handleClose={this.handleProfileClose}
                    updateMember={this.props.updateMember}
                    providerId={this.props.providerId}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'last_name'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'last_name'
                                        )}
                                    >
                                        Name
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'title'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'title'
                                        )}
                                    >
                                        Title
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'notification_email'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'notification_email'
                                        )}
                                    >
                                        Email
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'phone'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'phone'
                                        )}
                                    >
                                        Phone
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Admin
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Projects
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'active'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'active'
                                        )}
                                    >
                                        Status
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Edit
                            </TableCell>
                        </TableRow>
                    </TableHead>
               
                    <TableBody>
                        {this.props.members.map((profile) => (
                            <TableRow key={profile.id}>
                                <TableCell className={classes.tableCell}>
                                    {profile.first_name + ' ' + profile.last_name}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {profile.title}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {profile.email}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {profile.phone}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {profile.is_provider_admin ? (
                                        <CheckCircleIcon />
                                    ) : null}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <a href="#projects/{profile.id}" className={classes.link}>
                                        Projects
                                    </a>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <a
                                        href="#status/{profile.id}"
                                        className={classes.link}
                                        onClick={(e) => this.changeStatus(e, profile)}
                                    >
                                        {profile.active ? 'Active' : 'Disabled'}
                                    </a>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <a href="#edit/{profile.id}"
                                        className={classes.link}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            this.handleProfileOpen(profile)
                                        }}
                                    >
                                        Edit
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


export default ProviderMembersTab