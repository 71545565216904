import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import Badge from '@material-ui/core/Badge'
// import Toolbar from '@material-ui/core/Toolbar'
import axios, { CancelToken } from 'axios'
import moment from 'moment'

import Event from '@material-ui/icons/Event'
import Person from '@material-ui/icons/Person'
import CreateIcon from '@material-ui/icons/Create'
import EditIcon from '@material-ui/icons/Edit'
import HomeIcon from '@material-ui/icons/Home'

import classes from './Job.module.sass'

import LeftNavBar from '../../src/components/LeftNavBar'
import RightNavBar from '../../src/components/RightNavBar'
import NotificationsList from '../../notifications/components/NotificationsList'
import JobDetailsTab from './JobDetailsTab'
import JobCommentsTab from './JobCommentsTab'
import JobCandidatesTab from './JobCandidatesTab'
import JobCollaboratorsTab from './JobCollaboratorsTab'
import JobFilesTab from '../../files/components/JobFilesTab'
import JobInvoicingTab from '../../invoices/components/JobInvoicingTab'
import AddCompanyMember from '../../companies/components/AddCompanyMember'
import AddProviderMember from '../../providers/components/AddProviderMember'
import EstimateDialog from './EstimateDialog'


class JobDetail extends Component {
    ajaxToken = null
    ajaxCancel = null

    state = {
        jobId: null,
        job: {},
        candidates: [],
        tasks: [],
        comments: [],
        files: [],
        members: [],
        collaborators: [],
        worklogs: [],
        expenses: [],
        milestones: [],
        invoices: [],
        collaboratorsOrder: 'desc',
        collaboratorsOrderBy: '',
        filesOrder: 'desc',
        filesOrderBy: '',
        worklogsOrder: 'desc',
        worklogsOrderBy: '',
        expensesOrder: 'desc',
        expensesOrderBy: '',
        invoicesOrder: 'desc',
        invoicesOrderBy: '',
        addMemberOpen: false,
        addVendorOpen: false,
        estimateOpen: false,
        menuValue: 0,
    }

    componentDidMount = () => {
        this.ajaxToken = new CancelToken(c => {this.ajaxCancel = c})
        const jobId = this.props.jobId
        this.setState({
            jobId: jobId
        }, () => {
            this.loadJob(this.loadMembers)
            this.loadComments()
            this.loadCandidates()
            this.loadFiles()
            this.loadCollaborators()
            this.loadWorklogs()
            this.loadMilestones()
            this.loadExpenses()
            this.loadInvoices()
        })
    }

    loadJob = (callback=null) => {
        let url = '/api/jobs/' + this.state.jobId
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                job: response.data
            }, () => {
                if (typeof callback === 'function') {
                    callback()
                }
            })
        })
    }

    /**************
     * Candidates *
     **************/

    loadCandidates = () => {
        let url = '/api/candidates/?job=' + this.state.jobId
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                candidates: response.data.results
            })
        })
    }

    addToCandidates = (candidate) => {
        this.setState({
            candidates: [
                ...this.state.candidates,
                candidate,
            ]
        })
    }

    updateCandidateData = (candidate) => {
        const index = this.state.candidates.findIndex(
            cand => cand.id === candidate.id
        )
        this.setState({
            candidates: [
                ...this.state.candidates.slice(0, index),
                candidate,
                ...this.state.candidates.slice(index + 1),
            ]
        })
    }

    /*****************
     * Collaborators *
     *****************/

    loadCollaborators = () => {
        let url = `/api/collaborators/?job=${this.props.jobId}`
        if (this.state.collaboratorsOrderBy !== '') {
            url += '&ordering='
            if (this.state.collaboratorsOrder === 'desc') {
                url += '-'
            }
            url += this.state.collaboratorsOrderBy
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                collaborators: response.data
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    addCollaborator = (formData) => {
        const url = `/api/collaborators/`
        axios.post(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadCollaborators()
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    removeCollaborator = (collaboratorId) => {
        const url = `/api/collaborators/${collaboratorId}/`
        axios.delete(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadCollaborators()
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    /**************
     * Comments   *
     **************/

    loadComments = () => {
        let url = '/api/comments/?job=' + this.state.jobId
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                comments: response.data
            })
        })
    }

    prependComment = (comment) => {
        let comments = [...this.state.comments]
        comments.unshift(comment)
        this.setState({
            comments: comments
        })
    }

    /**************
     * Expenses   *
     **************/

    loadExpenses = () => {
        let url = `/api/expenses/?job=${this.props.jobId}`
        if (this.state.expensesOrderBy !== '') {
            url += '&ordering='
            if (this.state.expensesOrder === 'desc') {
                url += '-'
            }
            url += this.state.expensesOrderBy
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                expenses: response.data
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    addExpense = (formData, callback=null) => {
        const url = '/api/expenses/'
        axios.post(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadExpenses()
            if (typeof callback === 'function') {
                callback()
            }
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    /**************
     * Files      *
     **************/

    loadFiles = () => {
        let url = `/api/files/?job=${this.state.jobId}`
        if (this.state.filesOrderBy !== '') {
            url += '&ordering='
            if (this.state.filesOrder === 'desc') {
                url += '-'
            }
            url += this.state.filesOrderBy
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                files: response.data
            })
        })
    }

    addFile = (formData, callback=null) => {
        const url = '/api/files/'
        axios.post(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadFiles()
            if (typeof callback === 'function') {
                callback()
            }
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    deleteFile = (fileId) => {
        const url = `/api/files/${fileId}/`
        axios.delete(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadFiles()
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    /**************
     * Invoices   *
     **************/

    loadInvoices = () => {
        let url = `/api/invoices/?job=${this.props.jobId}`
        if (this.state.invoicesOrderBy !== '') {
            url += '&ordering='
            if (this.state.invoicesOrder === 'desc') {
                url += '-'
            }
            url += this.state.invoicesOrderBy
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                invoices: response.data.results
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    /**************
     * Members    *
     **************/

    loadMembers = () => {
        let url
        switch (this.state.job.relation) {
            case 'Customer':
                url = `/api/users/?company=${this.props.companyId}`
                break
            case 'Provider':
                url = `/api/users/?provider_group=${this.props.providerId}`
                break
            default:
                return
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                members: response.data.results
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    addMember = (formData) => {
        const url = `/api/users/add_company_member/`
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadMembers()
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    /**************
     * Milestones *
     **************/

    loadMilestones = () => {
        let url = `/api/milestones/?job=${this.props.jobId}`
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                milestones: response.data
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    /**************
     * Worklogs   *
     **************/

    loadWorklogs = () => {
        let url = `/api/worklogs/?job=${this.props.jobId}`
        if (this.state.worklogsOrderBy !== '') {
            url += '&ordering='
            if (this.state.worklogsOrder === 'desc') {
                url += '-'
            }
            url += this.state.worklogsOrderBy
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                worklogs: response.data
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    addWorklog = (formData, callback=null) => {
        const url = '/api/worklogs/'
        axios.post(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadWorklogs()
            if (typeof callback === 'function') {
                callback()
            }
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    /*****************************************************/

    sendEstimate = (formData, callback) => {
        const url = `/api/jobs/${this.props.jobId}/estimate/`
        axios.post(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadJob()
            this.loadMilestones()
            if (typeof callback === 'function') {
                callback()
            }
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    handleSort = (value, type='files') => {
        let order = 'asc'
        if (this.state[type + 'OrderBy'] === value) {
            if (this.state[type + 'Order'] === 'asc') {
                order = 'desc'
            }
        }
        this.setState({
            [type + 'OrderBy']: value,
            [type + 'Order']: order
        }, () => {
            switch (type) {
                case 'files':
                    this.loadFiles()
                    break
                case 'collaborators':
                    this.loadCollaborators()
                    break
                case 'worklogs':
                    this.loadWorklogs()
                    break
                case 'expenses':
                    this.loadExpenses()
                    break
                case 'invoices':
                    this.loadInvoices()
                    break
                default:
                    console.log(type)
            }
        })
    }

    handleChange = (event, value) => {
        this.setState({ menuValue: value });
    }

    handleAddMemberOpen = () => {
        this.setState({
            addMemberOpen: true
        })
    }

    handleAddMemberClose = () => {
        this.setState({
            addMemberOpen: false
        })
    }

    handleEstimateOpen = () => {
        this.setState({
            estimateOpen: true
        })
    }

    handleEstimateClose = () => {
        this.setState({
            estimateOpen: false
        })
    }

    dateDisplay = (dateString) => {
        let dateTime = new moment(dateString)
        if (dateTime.isValid()) {
            return dateTime.format('MMM Do, YYYY')
        }
        return "N/A"
    }

    dateTimeDisplay = (dateString) => {
        let dateTime = new moment(dateString)
        if (dateTime.isValid()) {
            return dateTime.format('MM/DD/YYYY h:mm a')
        }
        return "N/A"
    }

    renderEstimateMenu = () => {
        return (
            <React.Fragment>
                <EstimateDialog
                    open={this.state.estimateOpen}
                    handleOpen={this.handleEstimateOpen}
                    handleClose={this.handleEstimateClose}
                    job={this.state.job}
                    milestones={this.state.milestones}
                    loadMilestones={this.loadMilestones}
                    dateDisplay={this.dateDisplay}
                    dateTimeDisplay={this.dateTimeDisplay}
                    sendEstimate={this.sendEstimate}
                />
                {this.state.job.estimate_status === 'Awaiting Estimate' ? (
                    <Button 
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleEstimateOpen}
                    >
                        <CreateIcon className={classes.actionButtonIcon} />
                        Estimate this Project
                    </Button>
                ) : null}
                {this.state.job.estimate_status === 'Pending Revision' ||
                 this.state.job.estimate_status === 'Pending Approval' ? (
                    <Button 
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleEstimateOpen}
                    >
                        <EditIcon className={classes.actionButtonIcon} />
                        Edit Estimate
                    </Button>
                ) : null}
            </React.Fragment>
        )
    }


    render = () => {
        if (!this.props.loggedIn) {
            return <Redirect push to="/" />
        }
        const invoicingTabCount = (
            this.state.worklogs.length + 
            this.state.expenses.length +
            this.state.milestones.length + 
            this.state.invoices.length
        )

        // Sensible defaults
        const job = this.state.job ? this.state.job : {}
        const customer = job.customer ? job.customer : {}
        const company = job.company ? job.company : {}
        const provider = job.provider_group ? job.provider_group : {}
        const expert = job.expert ? job.expert : {
            name: 'Pending assignment'
        }

        return (
            <div className={classes.root}>
                <LeftNavBar>
                    <Button
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        component={Link}
                        to="/my_projects"
                    >
                        <HomeIcon className={classes.actionButtonIcon} />
                        Back to My Projects
                    </Button>
                    {this.state.job.draft ? (
                        <Button
                            color="primary"
                            size="large"
                            className={classes.navButton}
                            component={Link}
                            to={`/new_job/${this.state.job.request_type}/${this.state.jobId}`}
                        >
                            <EditIcon className={classes.actionButtonIcon} />
                            Edit Draft
                        </Button>
                    ) : null }
                    {this.state.job.relation === 'Provider' ? (
                        this.renderEstimateMenu()
                    ) : null}
                    {this.props.companyId ? (
                        <React.Fragment>
                        
                            <AddCompanyMember
                                open={this.state.addMemberOpen}
                                profile={{}}
                                handleClose={this.handleAddMemberClose}
                                addMember={this.addMember}
                                companyId={this.props.companyId}
                                companyName={this.props.companyName}
                            />
                        </React.Fragment>
                    ) : null}
                    {this.props.providerId ? (
                        <React.Fragment>
                            <Button 
                                color="primary"
                                size="large"
                                className={classes.navButton}
                                onClick={this.handleAddMemberOpen}
                            >
                                <Person className={classes.actionButtonIcon} />
                                Add a member to your group
                            </Button>
                            <AddProviderMember
                                open={this.state.addMemberOpen}
                                profile={{}}
                                handleClose={this.handleAddMemberClose}
                                addMember={this.addMember}
                                providerId={this.props.providerId}
                                providerName={this.props.providerName}
                            />
                        </React.Fragment>
                    ) : null}

                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        component={Link} to="#"
                    >
                        <Event className={classes.actionButtonIcon} />
                        Schedule a project meeting
                    </Button>
                </LeftNavBar>

                <Paper className={classes.newContent}>
                    <Tabs
                        value={this.state.menuValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className={classes.tabNav}
                    >
                        <Tab label={'Details'} value={0} />
                        {this.state.job.staffing ?
                            <Tab
                                label={
                                    `Candidates (${this.state.candidates.length})`
                                }
                                value={1}
                            />
                        : null}
                        <Tab
                            label={
                                `Comments (${this.state.comments.length})`
                            }
                            value={2}
                        />
                        {/*<Tab
                            label={
                                'Tasks'
                            }
                            value={3}
                        />
                        */}
                        <Tab
                            label={
                                `Files (${this.state.files.length})`
                            }
                            value={4}
                        />
                        <Tab 
                            label={
                                `Collaborators (${this.state.collaborators.length})`
                            }
                            value={5}
                        />
                        <Tab
                            label={
                                `Invoicing (${invoicingTabCount})`
                            }
                            value={6}
                        />
                    </Tabs>

                    <Typography variant="h4" gutterBottom className={classes.jobTitle}>
                        {`Project Details: ${this.state.job.name}`}
                    </Typography>

                    {this.state.menuValue === 0 ?
                        <JobDetailsTab
                            job={job}
                            customer={customer}
                            expert={expert}
                            company={company}
                            provider={provider}
                            dateDisplay={this.dateDisplay}
                            dateTimeDisplay={this.dateTimeDisplay}
                            candidates={this.state.candidates}
                            worklogs={this.state.worklogs}
                            milestones={this.state.milestones}
                            customFields={this.props.customFields}
                            handleEstimateOpen={this.handleEstimateOpen}
                        />
                    : null}
                    {this.state.menuValue === 1 ?
                        <JobCandidatesTab
                            job={job}
                            candidates={this.state.candidates}
                            addToCandidates={this.addToCandidates}
                            updateCandidateData={this.updateCandidateData}
                            userId={this.props.userId}
                            token={this.props.token}
                            userType={this.state.job.relation}
                            dateTimeDisplay={this.dateTimeDisplay}
                            dateDisplay={this.dateDisplay}
                        />
                    : null}
                    {this.state.menuValue === 2 ?
                        <JobCommentsTab
                            job={job}
                            comments={this.state.comments}
                            prependComment={this.prependComment}
                            userId={this.props.userId}
                            token={this.props.token}
                        />
                    : null}
                    {this.state.menuValue === 3 ?
                        <div>Tasks</div>
                    : null}
                    {this.state.menuValue === 4 ?
                        <JobFilesTab
                            jobId={this.state.jobId}
                            files={this.state.files}
                            userId={this.props.userId}
                            token={this.props.token}
                            orderBy={this.state.filesOrderBy}
                            order={this.state.filesOrder}
                            handleSort={this.handleSort}
                            addFile={this.addFile}
                            deleteFile={this.deleteFile}
                        />
                    : null}
                    {this.state.menuValue === 5 ?
                        <JobCollaboratorsTab
                            job={job}
                            jobId={this.props.jobId}
                            collaborators={this.state.collaborators}
                            members={this.state.members}
                            customer={customer}
                            expert={expert}
                            userId={this.props.userId}
                            token={this.props.token}
                            userType={this.state.job.relation}
                            orderBy={this.state.collaboratorsOrderBy}
                            order={this.state.collaboratorsOrder}
                            handleSort={this.handleSort}
                            addCollaborator={this.addCollaborator}
                            removeCollaborator={this.removeCollaborator}
                        />
                    : null}
                    {this.state.menuValue === 6 ?
                        <JobInvoicingTab
                            job={job}
                            jobId={this.props.jobId}
                            userId={this.props.userId}
                            token={this.props.token}
                            userType={this.state.job.relation}
                            worklogs={this.state.worklogs}
                            addWorklog={this.addWorklog}
                            expenses={this.state.expenses}
                            addExpense={this.addExpense}
                            milestones={this.state.milestones}
                            invoices={this.state.invoices}
                            handleSort={this.handleSort}
                            worklogsOrder={this.state.worklogsOrder}
                            worklogsOrderBy={this.state.worklogsOrderBy}
                            expensesOrder={this.state.expensesOrder}
                            expensesOrderBy={this.state.expensesOrderBy}
                            invoicesOrder={this.state.invoicesOrder}
                            invoicesOrderBy={this.state.invoicesOrderBy}
                        />
                    : null}
                </Paper>

                <RightNavBar>
                    <NotificationsList />
                </RightNavBar>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    // updateMyInfo: (data) => {
    //     userActions.updateMyInfo(dispatch, data)
    // },
})


export default connect(mapStateToProps, mapDispatchToProps)(JobDetail)
