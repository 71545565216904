import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
// import Button from '@material-ui/core/Button'

import ProviderProfile from '../../providers/components/ProviderProfile'

import classes from './Job.module.sass'


class JobDetailsTab extends Component {
    state = {
        providerProfileOpen: false
    }

    viewProviderProfile = (event) => {
        event.preventDefault()
        this.openProviderProfile()
    }

    openProviderProfile = () => {
        this.setState({ providerProfileOpen: true })
    }

    closeProviderProfile = () => {
        this.setState({ providerProfileOpen: false })
    }

    getHoursDisplay = () => {
        if (this.props.worklogs) {
            let hours = 0
            for (let log of this.props.worklogs) {
                hours += log.hours
            }
            return `${hours} hrs`
        }
        return 'N/A'
    }

    getOnsiteAddressDisplay = () => {
        if (!this.props.job.onsite_address) {
            return null
        }
        const address = {...this.props.job.onsite_address}
        return `
            ${address.street1}, ${address.city},
            ${address.state_code} ${address.postal_code}
        `
    }

    isCustomerType = () => {
        const CustomerTypes = ['Customer', 'Customer Collaborator']
        return CustomerTypes.indexOf(this.props.job.relation) !== -1
    }

    render = () => (
        <div>
            <ProviderProfile
                handleClose={this.closeProviderProfile}
                provider={this.props.provider}
                open={this.state.providerProfileOpen}
            />
            <Grid>
                <Typography variant="h6" className={classes.dtitle}>
                    Project
                </Typography>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Name
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.job.name}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Status
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.job.status}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Opened
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.dateDisplay(this.props.job.request_date)}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Started
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.dateDisplay(this.props.job.start_date)}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Completed
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.dateDisplay(this.props.job.completed_date)}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        SourcePanel ID
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.job.id}
                    </Typography>
                </div>
            </Grid>

        {/* Customer */}
            <Grid container space={16}>
                <Grid item lg={6} md={6}>
                    <Typography variant="h6" className={classes.dtitle}>
                        Customer
                    </Typography>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Name
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.customer.fullname}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Role
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.customer.title}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Company
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.company.name}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Phone
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.customer.phone}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Email
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.customer.notification_email}
                        </Typography>
                    </div>
                </Grid>

                {/* Provider */}
                <Grid item lg={6} md={6}>
                    <Typography variant="h6" className={classes.dtitle}>
                        Provider
                    </Typography>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Name
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.expert.fullname}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Role
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.expert.title}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Company
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.provider.name}
                            {this.props.provider.about_us ? (
                                <a href="#showProviderProfile" className={classes.link} onClick={this.viewProviderProfile}>
                                    <OpenInNewIcon fontSize="small" className={classes.linkIcon} />
                                    Profile
                                </a>
                            ) : null}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Phone
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.expert.phone}
                        </Typography>
                    </div>
                    <div className={classes.dl}>
                        <Typography variant="inherit" className={classes.dt}>
                            Email
                        </Typography>
                        <Typography variant="inherit" className={classes.dd}>
                            {this.props.expert.notification_email}
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            {/* Project Details */}
            <Typography variant="h6" className={classes.dtitle}>
                Project details
                {this.props.job.draft ? (
                    <Link
                        className={classes.titleLink}
                        to={`/new_job/${this.props.job.request_type}/${this.props.job.id}`}
                    >
                        (edit draft)
                    </Link>
                ) : null }
            </Typography>
            {this.props.job.requested_start_date ? (
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Requested start date
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.dateDisplay(this.props.requested_start_date)}
                    </Typography>
                </div>
            ) : null }
            {this.props.job.expected_completion_date ? (
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Expected end date
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.dateDisplay(this.props.expected_completion_date)}
                    </Typography>
                </div>
            ) : null }
            <div className={classes.dl}>
                <Typography variant="inherit" className={classes.dt}>
                    Skill Category
                </Typography>
                <Typography variant="inherit" className={classes.dd}>
                    {this.props.job.skill}
                </Typography>
            </div>
            {this.props.job.project_type === 'HOURLY' ? (
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Hours to date
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.getHoursDisplay()}
                    </Typography>
                </div>
            ) : null }
            {this.props.job.onsite ? (
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        *Onsite required
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.getOnsiteAddressDisplay()}
                    </Typography>
                </div>
            ) : null }
            <div className={classes.dl}>
                <Typography variant="inherit" className={classes.dt}>
                    Description
                </Typography>
                <Typography variant="inherit" className={classes.dd}>
                    <div dangerouslySetInnerHTML={{
                        __html: this.props.job.description }}
                        className={classes.inlineP}
                    />
                </Typography>
            </div>
            
            {/* Candidates  / Estimate */}
            {this.props.job.staffing ? (
                <React.Fragment>
                    <Typography variant="h6" className={classes.dtitle}>
                        Candidates
                    </Typography>
                    {this.isCustomerType() ? (
                        <React.Fragment>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Status
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.candidates.length ? (
                                        <span>Review candidates in the candidates tab</span>
                                    ) : (
                                        <em className={classes.red}>Awaiting candidates</em>
                                    )}
                                </Typography>
                            </div>
                            {this.props.candidates.filter(candidate => candidate.interested === 'Hired').map((candidate, index) => (
                                <div className={classes.dl} key={candidate.id}>
                                    <Typography variant="inherit" className={classes.dt}>
                                        Candidate #{index + 1}
                                    </Typography>
                                    <Typography variant="inherit" className={classes.dd}>
                                        {candidate.fullname}
                                        <br />
                                        {candidate.offer_submitted_by ? (
                                            <React.Fragment>
                                                Offer submitted by: {candidate.offer_submitted_by.fullname}
                                                <em>
                                                    &nbsp;{this.props.dateTimeDisplay(candidate.offer_submitted)}
                                                </em>
                                                <br />
                                            </React.Fragment>
                                        ) : null }
                                        {candidate.offer_accepted_by ? (
                                            <React.Fragment>
                                                Offer accepted by: {candidate.offer_accepted_by.fullname}
                                                <em>
                                                    &nbsp;{this.props.dateTimeDisplay(candidate.offer_accepted)}
                                                </em>
                                                <br />
                                            </React.Fragment>
                                        ) : null }
                                        Requested:&nbsp;
                                        {candidate.requested_start ? (
                                            this.props.dateDisplay(candidate.requested_start)
                                        ) : null }
                                        {candidate.requested_end ? (
                                            ` through ${this.props.dateDisplay(candidate.requested_end)}`
                                        ) : null }
                                        {candidate.requested_start === null && candidate.requested_end === null ? (
                                            'As soon as possible'
                                        ) : null }
                                        <br />
                                        {candidate.work_schedule ? (
                                            <React.Fragment>
                                                Schedule:&nbsp;
                                                {candidate.work_schedule}
                                                <br />
                                            </React.Fragment>
                                        ) : null }
                                        <strong>
                                            Rate: ${candidate.offer_rate}
                                        </strong>
                                    </Typography>
                                </div>
                            ))}
                        </React.Fragment>
                    ) : (
                        <div className={classes.dl}>
                            <Typography variant="inherit" className={classes.dt}>
                                Status
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                Upload candidates in the candidates tab
                            </Typography>
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography variant="h6" className={classes.dtitle}>
                        Estimate
                        {this.props.job.status === 'In Review' && this.props.job.relation === 'Provider' ? (
                            <span
                                className={classes.titleLink}
                                onClick={this.props.handleEstimateOpen}
                            >
                                (edit estimate)
                            </span>
                        ) : null }
                    </Typography>
                    {this.props.job.estimator ? (
                        <div className={classes.dl}>
                            <Typography variant="inherit" className={classes.dt}>
                                Estimated by
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                {this.props.job.estimator.fullname}&nbsp;
                                <em>
                                    {this.props.dateTimeDisplay(this.props.job.estimated_date)}
                                </em>
                            </Typography>
                        </div>
                    ) : null }
                    {this.props.job.acceptor ? (
                        <div className={classes.dl}>
                            <Typography variant="inherit" className={classes.dt}>
                                Accepted by
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                {this.props.job.acceptor.fullname}&nbsp;
                                <em>
                                    {this.props.dateTimeDisplay(this.props.job.accepted_date)}
                                </em>
                            </Typography>
                        </div>
                    ) : null }
                    {this.props.job.customer_decline ? (
                        <div className={classes.dl}>
                            <Typography variant="inherit" className={classes.dt}>
                                Note
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                <em>
                                    Customer declined this estimate
                                </em>
                            </Typography>
                        </div>
                    ) : null }
                    {this.props.job.project_type === 'NEW' ? (
                        <div className={classes.dl}>
                            <Typography variant="inherit" className={classes.dt}>
                                Type
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                New project <em>(awaiting estimate)</em>
                            </Typography>
                        </div>
                    ) : null }
                    {this.props.job.project_type === 'FIXED' ? (
                        <React.Fragment>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Type
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    Fixed bid
                                </Typography>
                            </div>

                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Fixed price estimate
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    ${this.props.job.customer_quote}
                                </Typography>
                            </div>
                        </React.Fragment>
                    ) : null }
                    {this.props.job.project_type === 'HOURLY' || this.props.job.project_type === 'DYNAMIC' ? (
                        <React.Fragment>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Type
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    Hourly
                                </Typography>
                            </div>

                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Hourly rate
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    ${this.props.job.custom_rate}
                                </Typography>
                            </div>
                        </React.Fragment>
                    ) : null }
                    {this.props.milestones.length ? (
                        <div className={classes.dl}>
                            <Typography variant="inherit" className={classes.dt}>
                                Milestones
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                {this.props.milestones.map(milestone => (
                                    <div key={milestone.id}>
                                        <strong>
                                            {milestone.change_order ? 'Change Order Milestone #' : 'Milestone #'}
                                            {milestone.order}
                                        </strong>
                                        {milestone.completed_date ? ` (Completed on ${this.props.dateDisplay(milestone.completed_date)})` : null}
                                        <br />
                                        {milestone.title} — <em>{milestone.percent}%</em>
                                        <p>
                                            {milestone.content}
                                        </p>
                                    </div>
                                ))}
                            </Typography>
                        </div>
                    ) : null}
                    {this.props.job.expert_summary ? (
                        <div className={classes.dl}>
                            <Typography variant="inherit" className={classes.dt}>
                                Expert clarification
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                <div dangerouslySetInnerHTML={{
                                    __html: this.props.job.expert_summary }}
                                    className={classes.inlineP}
                                />
                            </Typography>
                        </div>
                    ) : null }
                </React.Fragment>
            )}
            {this.isCustomerType() ? (
                <React.Fragment>
                    <Typography variant="h6" className={classes.dtitle}>
                        Custom Fields
                    </Typography>
                    {this.props.customFields.filter(
                            customField => customField.active).map(
                            customField => (
                        <div className={classes.dl} key={customField.id}>
                            <Typography variant="inherit" className={classes.dt}>
                                {customField.field_name}
                            </Typography>
                            <Typography variant="inherit" className={classes.dd}>
                                {(() => {
                                    let value = this.props.job.custom_values.find(
                                        val => val.field === customField.id
                                    )
                                    return value ? value.field_value : ''
                                })()}
                            </Typography>
                        </div>
                    ))}
                </React.Fragment>
            ) : null }
            <p><br /></p>
        </div>
    )
}


export default JobDetailsTab