import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Material UI
import Button from '@material-ui/core/Button'
// Icons
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import PersonIcon from '@material-ui/icons/Person'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
// import ViewListIcon from '@material-ui/icons/ViewList'
import BusinessIcon from '@material-ui/icons/Business'
// Custom components
import AddProviderMember from '../../providers/components/AddProviderMember'
import InviteCustomer from '../../providers/components/InviteCustomer'
// Styling
import classes from './DefaultNavBarMenus.module.sass'


export const DefaultCustomerMenu = (props) => (
    <React.Fragment>
        <Button 
            color="primary"
            size="large"
            className={classes.navButton}
            component={Link} to="/new_job/Project/"
        >
            <CreateNewFolderIcon className={classes.actionButtonIcon} />
            Start a Project with a Vendor / Get an Estimate
        </Button>
        <Button 
            color="primary"
            size="large"
            className={classes.navButton}
            component={Link} to="/new_job/Contractor/"
        >
            <PersonIcon className={classes.actionButtonIcon} />
            Hire a Contractor
        </Button>

        {/*
        <Button
            color="primary"
            size="large"
            className={classes.navButton}
            component={Link} to="/solutions"
        >
            <ViewListIcon className={classes.actionButtonIcon} />
            Shop Solutions
        </Button>
        */}
    </React.Fragment>
)


export class DefaultExpertMenu extends Component {
    state = {
        addMemberOpen: false,
        inviteOpen: false,
    }

    handleAddMemberOpen = event => this.setState({ addMemberOpen: true })
    handleAddMemberClose = event => this.setState({ addMemberOpen: false })
    handleInviteOpen = event => this.setState({ inviteOpen: true })
    handleInviteClose = event => this.setState({ inviteOpen: false })

    render = () => (
        <React.Fragment>
            <Button
                color="primary"
                size="large"
                className={classes.navButton}
                component={Link} to="/provider_proposed/"
            >
                <CreateNewFolderIcon className={classes.actionButtonIcon} />
                Propose a project to a Customer
            </Button>
            <Button 
                color="primary"
                size="large"
                className={classes.navButton}
                onClick={this.handleAddMemberOpen}
            >
                <PersonAddIcon className={classes.actionButtonIcon} />
                Add a Member to My Group
            </Button>
            <AddProviderMember
                open={this.state.addMemberOpen}
                profile={{}}
                handleClose={this.handleAddMemberClose}
            />
            <Button 
                color="primary"
                size="large"
                className={classes.navButton}
                onClick={this.handleInviteOpen}
            >
                <BusinessIcon className={classes.actionButtonIcon} />
                Invite a new customer
            </Button>
            <InviteCustomer
                open={this.state.inviteOpen}
                handleClose={this.handleInviteClose}
            />
        </React.Fragment>
    )
}
