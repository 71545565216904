import React, { Component } from 'react'
import axios from 'axios'
import Typography from '@material-ui/core/Typography'

import classes from './Job.module.sass'

import CandidateCard from '../../candidates/components/CandidateCard'


class JobCandidatesTab extends Component {
    state = {}

    addCandidate = (formData) => {
        let url = '/api/candidates/'
        axios.post(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            this.props.addToCandidates(response.data)
        })
        .catch(error => {
            console.log(error)
            // this.setState({
            //     addCommentOpen: true,
            //     addCommentMessage: "Error sending comment. " + error,
            //     addCommentClass: classes.error
            // })
        })
    }

    updateCandidate = (candidateId, formData) => {
        let url = `/api/candidates/${candidateId}/`
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            this.props.updateCandidateData(response.data)
        })
        .catch(error => {
            console.log(error)
            // this.setState({
            //     addCommentOpen: true,
            //     addCommentMessage: "Error sending comment. " + error,
            //     addCommentClass: classes.error
            // })
        })
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Candidates
                </Typography>
                {this.props.candidates.map((candidate) => {
                    return (
                        <CandidateCard
                            key={candidate.id}
                            candidate={candidate} 
                            userId={this.props.userId}
                            userType={this.props.userType}
                            updateCandidate={this.updateCandidate}
                            dateTimeDisplay={this.props.dateTimeDisplay}
                            dateDisplay={this.props.dateDisplay}
                        />
                    )
                })}
            </div>
        )
    }
}


export default JobCandidatesTab