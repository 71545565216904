import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

import { AutoSizer, List } from 'react-virtualized'


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 16
    },
    input: {
        display: 'flex',
        height: 19
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center'
    },
    singleValue: {
        fontSize: 16
    },
    paper: {
        position: 'absolute',
        zIndex: 2,
        left: 0,
        right: 0,
        height: 402
    }
})

const NoOptionsMessage = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

const inputComponent = ({ inputRef, ...props }) => {
    return <div ref={inputRef} {...props} />
}

const Control = (props) => {
    return (
        <TextField
            required
            fullWidth
            id="skill"
            name="skill"
            label="Select a Skill Category"
            variant="outlined"
            margin="dense"
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                }
            }}
            InputLabelProps={{
                shrink: true
            }}
            {...props.selectProps.textFieldProps}
        />
    )
}

const Option = (props) => {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
                height: 34,
                padding: '0 16px'
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    )
}

const Placeholder = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    )
}

const SingleValue = (props) => {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    )
}

const ValueContainer = (props) => {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}


const Menu = (props) => {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    )
}

const MenuList = (props) => {
    const rows = props.children
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
        <div key={key} style={style}>{rows[index]}</div>
    )
    return (
        <AutoSizer disableHeight>
            {({ width }) => (
                <List
                    width={width}
                    height={402}
                    rowCount={rows.length ? rows.length : 0}
                    rowHeight={35}
                    rowRenderer={rowRenderer}
                />
            )}
        </AutoSizer>
    )
}

const components = {
    Control,
    Menu,
    MenuList,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

class SkillSelect extends React.Component {
    state = {}

    render = () => {
        const { classes, theme } = this.props

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 16,
                marginTop: 2
            }),
            menuList: base => ({
                height: 402
            })
        }

        return (
            <div className={classes.root}>
                <NoSsr>
                    <Select
                        classes={classes}
                        styles={selectStyles}
                        style={{
                            marginTop: 16,
                            marginBottom: 8,
                        }}
                        options={this.props.skillList}
                        components={components}
                        value={this.props.skill}
                        onChange={this.props.selectSkill}
                        placeholder=""
                        isClearable
                        variant="outlined"
                        margin="dense"
                        noOptionsMessage={(search) => (
                            `Nothing found for: ${search}`
                        )}
                    />
                    <div className={classes.divider} />
                </NoSsr>
            </div>
        )
    }
}

SkillSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(SkillSelect)
