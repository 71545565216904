import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router'
import axios, { CancelToken } from 'axios'
import CKEditor from 'react-ckeditor-component'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
import { Link } from 'react-router-dom'
import moment from 'moment'
// Material UI
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from "@material-ui/core/OutlinedInput"
import MenuItem from "@material-ui/core/MenuItem"
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
// Icons
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import SendIcon from '@material-ui/icons/Send'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
// Custom
import SkillSelect from '../../skills/components/SkillSelect'
import ProviderProfile from '../../providers/components/ProviderProfile'
import JobStepsFTE from './JobStepsFTE'
import JobStepsContractor from './JobStepsContractor'
import JobStepsProject from './JobStepsProject'
// Styles
import classes from './JobForm.module.sass'

class JobForm extends Component {
    ajaxToken = null
    ajaxCancel = null
    
    state = {
        jobId: null,
        request_type: null,
        name: "",
        skillObj: null,
        skill_requested: "",
        use_vendor: false,
        vendor: "",
        vendorOpen: false,
        onsite: false,
        onsite_address: {
            street1: "",
            street2: "",
            city: "",
            state_code: "",
            postal_code: "",
        },
        description: "",
        expected_start_date: null,
        expected_completion_date: null,
        job_file: null,
        job_file_display: "",
        skillset: [
            {
                value: -1,
                label: "Loading..."
            }
        ],
        custom_values: [],
        providerProfile: {},
        providerProfileOpen: false
    }

    componentDidMount = () => {
        this.ajaxToken = new CancelToken(c => {this.ajaxCancel = c})
        this.loadSkillset()
        const jobId = this.props.jobId
        this.setState({
            jobId: jobId
        }, () => {
            if (jobId) {
                this.loadJob()
            }
        })
    }

    componentDidUpdate = (previous) => {
        if (this.props !== previous) {
            this.loadSkillset()
        }
    }

    componentWillUnmount = () => {
        this.ajaxCancel('Unmounting MyPanel.')
    }

    loadSkillset = () => {
        if (this.props.token === null) {
            return
        }
        let url = '/api/skills/'
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            let skillset = []
            for (let skill of response.data) {
                if (skill.available) {
                    skillset.push({
                        value: skill.id,
                        label: skill.label
                    })
                }
                for (let subskill of skill.children) {
                    if (subskill.available) {
                        skillset.push({
                            value: subskill.id,
                            label: `${skill.label} > ${subskill.label}`
                        })
                    }
                }
            }
            this.setState({
                skillset: skillset
            })
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                this.props.handleLogout()
            }
            console.log(error.message)
        })
    }

    loadJob = () => {
        let url = `/api/jobs/${this.state.jobId}/`
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            this.setState(response.data)
            if (response.data.provider_group) {
                this.setState({
                    use_vendor: true,
                    vendor: response.data.provider_group.id
                })
            }
            if (response.data.skill_requested) {
                this.setState({
                    skillObj: {
                        value: response.data.skill_requested,
                        label: response.data.skill
                    }
                })
            }
        })
        .catch(error => {
            console.log(error.message)
        })
    }

    saveJob = (draft=false) => {
        let formData = new FormData()
        formData.append('draft', draft)
        formData.append('customer', this.props.userId)
        formData.append('company', this.props.companyId)
        formData.append('request_type', this.props.type)
        formData.append('name', this.state.name)
        formData.append('skill_requested', this.state.skill_requested)
        let expected_start_date = moment(this.state.expected_start_date)
        if (expected_start_date.isValid()) {
            formData.append(
                'expected_start_date',
                expected_start_date.format('YYYY-MM-DD')
            )
        }
        let expected_completion_date = moment(this.state.expected_completion_date)
        if (expected_completion_date.isValid()) {
            formData.append(
                'expected_completion_date',
                expected_completion_date.format('YYYY-MM-DD')
            )
        }
        formData.append('description', this.state.description)
        formData.append('onsite', this.state.onsite)
        let url = '/api/jobs/'
        let method = 'post'
        
        if (this.state.use_vendor) {
            formData.append('provider_group_id', this.state.vendor)
        }
        if (this.state.onsite) {
            formData.append('onsite_address', JSON.stringify(
                this.state.onsite_address
            ))
        }
        if (this.state.job_file) {
            formData.append('job_file', this.state.job_file)
        }
        if (this.state.custom_values.length > 0) {
            formData.append('custom_values', JSON.stringify(
                this.state.custom_values
            ))
        }
        if (this.state.jobId) {
            url = `/api/jobs/${this.state.jobId}/`
            method = 'patch'
            formData.append('id', this.state.jobId)
        }
        axios.request({
            method: method,
            url: url,
            data: formData,
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            if (response.data && response.data.id) {
                this.props.history.push(`/jobs/${response.data.id}/`)
            }
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    handleChange = (event) => {
        const key = event.target.name
        const value = event.target.value
        console.log(key, value)
        if (key.search('__') !== -1) {
            const keys = key.split('__')
            const stateKey = keys[0]
            const fieldKey = keys[1]
            let updatedData = {...this.state[stateKey]}
            updatedData[fieldKey] = value
            this.setState({
                [stateKey]: updatedData
            })
        } else if (key.match(/customField_/)) {
            console.log('We get here.')
            const customField = parseInt(key.replace(/customField_/, ''))
            let index = this.state.custom_values.findIndex(val => (
                val.field === customField
            ))
            console.log(index)
            if (index !== -1) {
                this.setState({
                    custom_values: [
                        ...this.state.custom_values.slice(0, index),
                        {
                            ...this.state.custom_values[index],
                            field_value: value,
                        },
                        ...this.state.custom_values.slice(index + 1)
                    ]
                })
            } else {
                this.setState({
                    custom_values: [
                        ...this.state.custom_values,
                        {
                            id: null,
                            job: this.state.jobId,
                            field: customField,
                            field_value: value
                        },
                    ]
                })
            }
        } else {
            this.setState({
                [key]: value
            })
        }
        if (key === 'vendor') {
            if (value === '') {
                this.setState({
                    use_vendor: false
                })
            }
        }
    }

    handleDateChange = (key, date) => {
        this.setState({
            [key]: date
        })
    }

    handleToggle = (event) => {
        const key = event.target.name
        const checked = !this.state[key]
        this.setState({
            [key]: checked
        })
        if (key === 'use_vendor') {
            this.setState({
                vendorOpen: true
            })
        }
    }

    handleSkillSelect = (skill) => {
        if (skill !== null && skill.value) {
            this.setState({
                skill_requested: skill.value,
                skillObj: skill
            })
        } else {
            this.setState({
                skill_requested: null,
                skillObj: null
            })
        }
    }

    handleFileChange = (event) => {
        const job_file = event.target.files[0]
        console.log('job_file', job_file)
        this.setState({
            job_file: job_file,
            job_file_display: job_file.name
        })
    }

    handleRemoveFile = (event) => {
        event.preventDefault()
        this.setState({
            job_file: null,
            job_file_display: ''
        })
    }

    updateDescription = (event) => {
        const currentDescription = event.editor.getData();
        this.setState({
            description: currentDescription
        })
    }

    viewProviderProfile = (event, providerId) => {
        event.preventDefault()
        event.stopPropagation()
        axios.get(`/api/providers/${providerId}`, {
            headers: {
                Authorization: `Token ${this.props.token}`
            }
        })
        .then(response => {
            this.setState({
                providerProfile: response.data,
                providerProfileOpen: true
            })
        })
        
    }

    closeProviderProfile = () => {
        this.setState({ providerProfileOpen: false })
    }

    render = () => {
        if (!this.props.loggedIn) {
            return <Redirect push to="/" />
        }

        let name_label = "Project Name"
        let description_label = "Project Description *"
        if (this.props.type === 'Contractor' || this.props.type === 'FTE') {
            name_label = "Job Title"
            description_label = "Job Description *"
        }

        return (
            <div className={classes.root}>
                <Grid container space={16}>
                    <Grid item lg={8} md={12} className={classes.content}>
                        <Typography variant="h4" gutterBottom>
                            {this.props.type === 'Project' ? (
                                "Project Builder"
                            ) : null }
                            {this.props.type === 'Contractor' ? (
                                "Contractor Request Form"
                            ) : null }
                            {this.props.type === 'FTE' ? (
                                "Employee Request Form"
                            ) : null }
                        </Typography>
                        <Paper className={classes.section}>
                            <FormGroup row style={{ marginTop: 8}}>
                                <TextField
                                    required
                                    autoComplete="off"
                                    id="name"
                                    name="name"
                                    label={name_label}
                                    value={this.state.name}
                                    fullWidth
                                    margin="dense"
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            </FormGroup>

                            <FormGroup row>
                                <SkillSelect
                                    selectSkill={this.handleSkillSelect}
                                    skillList={this.state.skillset}
                                    skill={this.state.skillObj}
                                />
                            </FormGroup>

                            {this.props.type === 'Project' ? (
                            <React.Fragment>
                                <FormGroup row className={classes.fieldSection}>
                                    <Typography variant="caption">
                                        Service Provider Options
                                    </Typography>
                                </FormGroup>

                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="use_vendor"
                                                checked={this.state.use_vendor}
                                                onChange={this.handleToggle}
                                                value="use_vendor"
                                            />
                                        }
                                        label="Use an existing provider"
                                    />
                                </FormGroup>

                                <FormGroup row>
                                    <ProviderProfile
                                        handleClose={this.closeProviderProfile}
                                        provider={this.state.providerProfile}
                                        open={this.state.providerProfileOpen}
                                    />
                                    {this.state.use_vendor ? (
                                        <FormControl fullWidth>
                                            <InputLabel
                                                htmlFor="vendor"
                                                shrink={true}
                                                variant="outlined"
                                            >
                                                Existing Service Providers
                                            </InputLabel>
                                            <Select
                                                value={this.state.vendor}
                                                onChange={this.handleChange}
                                                input={
                                                    <OutlinedInput
                                                        name="vendor"
                                                        id="vendor"
                                                        fullWidth
                                                        labelWidth={190}
                                                        notched={true}
                                                    />
                                                }
                                                open={this.state.vendorOpen}
                                                onOpen={(e) => this.setState({ vendorOpen: true })}
                                                onClose={(e) => {
                                                    const self = this;
                                                    setTimeout(() => {
                                                        let update = { vendorOpen: false }
                                                        if (self.state.vendor === '') {
                                                            update.use_vendor = false
                                                        }
                                                        self.setState(update)
                                                    }, 30)
                                                }}
                                                margin="dense"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.props.vendors.map((vendor) => (
                                                    <MenuItem
                                                        key={vendor.id}
                                                        value={vendor.id}
                                                    >
                                                        {vendor.name}
                                                        {vendor.has_profile ? (
                                                            <a 
                                                                href="#providerProfile"
                                                                className={classes.link}
                                                                onClick={(e) => this.viewProviderProfile(e, vendor.id)}
                                                            >
                                                                <OpenInNewIcon fontSize="small" className={classes.linkIcon} />
                                                                View Profile
                                                            </a>
                                                        ) : null}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography variant="body2">
                                           *A new provider will be assigned based on 
                                           the requested skill category.
                                        </Typography>
                                    )}
                                </FormGroup>
                            </React.Fragment>
                            ) : null}

                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="onsite"
                                            checked={this.state.onsite}
                                            onChange={this.handleToggle}
                                            value="onsite"
                                        />
                                    }
                                    label="Onsite required? (May delay project start date)"
                                />
                            </FormGroup>

                            {this.state.onsite ? (
                                <FormGroup row>
                                    <FormControl fullWidth>
                                        <Grid container space={16}>
                                            <Grid item sm={6} className={classes.gridField}>
                                                <TextField
                                                    margin="dense"
                                                    id="onsite_address__street1"
                                                    name="onsite_address__street1"
                                                    label="Address Line 1"
                                                    value={this.state.onsite_address ? this.state.onsite_address.street1 : null}
                                                    type="text"
                                                    fullWidth
                                                    className={classes.field}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item sm={6} className={classes.gridField}>
                                                <TextField
                                                    margin="dense"
                                                    id="onsite_address__street2"
                                                    name="onsite_address__street2"
                                                    label="Address Line 2"
                                                    value={this.state.onsite_address ? this.state.onsite_address.street2 : null}
                                                    type="text"
                                                    fullWidth
                                                    className={classes.field}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container space={16}>
                                            <Grid item sm={4} className={classes.gridField}>
                                                <TextField
                                                    margin="dense"
                                                    id="onsite_address__city"
                                                    name="onsite_address__city"
                                                    label="City"
                                                    value={this.state.onsite_address ? this.state.onsite_address.city : null}
                                                    type="text"
                                                    fullWidth
                                                    className={classes.field}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item sm={4} className={classes.gridField}>
                                                <TextField
                                                    margin="dense"
                                                    id="onsite_address__state_code"
                                                    name="onsite_address__state_code"
                                                    label="State (code)"
                                                    value={this.state.onsite_address ? this.state.onsite_address.state_code : null}
                                                    type="text"
                                                    fullWidth
                                                    className={classes.field}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item sm={4} className={classes.gridField}>
                                                <TextField
                                                    margin="dense"
                                                    id="onsite_address__postal_code"
                                                    name="onsite_address__postal_code"
                                                    label="Zip Code"
                                                    value={this.state.onsite_address ? this.state.onsite_address.postal_code : null}
                                                    type="text"
                                                    fullWidth
                                                    className={classes.field}
                                                    onChange={this.handleChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </FormGroup>
                            ) : null}

                            <FormGroup row className={this.state.onsite ? classes.fieldSection : ""}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container space={16}>
                                        <Grid item sm={6} className={classes.gridField}>
                                            <DatePicker
                                                keyboard
                                                clearable
                                                name="expected_start_date"
                                                id="expected_start_date"
                                                label="Expected Start Date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant="outlined"
                                                margin="dense"
                                                placeholder="ASAP"
                                                format="MM/dd/yyyy"
                                                value={this.state.expected_start_date}
                                                mask={value =>
                                                    value ? [
                                                        /\d/, /\d/, '/',         // MM
                                                        /\d/, /\d/, '/',         // DD
                                                        /\d/, /\d/, /\d/, /\d/,  // YYYY
                                                    ] : []
                                                }
                                                onChange={value => this.handleDateChange(
                                                    'expected_start_date', value
                                                )}
                                                disableOpenOnEnter
                                                animateYearScrolling={false}
                                                className={classes.dateSelect}
                                            />
                                        </Grid>
                                        <Grid item sm={6} className={classes.gridField}>
                                            <DatePicker
                                                keyboard
                                                clearable
                                                name="expected_completion_date"
                                                id="expected_completion_date"
                                                label="Expected Completion Date"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                variant="outlined"
                                                margin="dense"
                                                placeholder="Defer to expert"
                                                format="MM/dd/yyyy"
                                                value={this.state.expected_completion_date}
                                                mask={value =>
                                                    value ? [
                                                        /\d/, /\d/, '/',         // MM
                                                        /\d/, /\d/, '/',         // DD
                                                        /\d/, /\d/, /\d/, /\d/,  // YYYY
                                                    ] : []
                                                }
                                                onChange={value => this.handleDateChange(
                                                    'expected_completion_date', value
                                                )}
                                                disableOpenOnEnter
                                                animateYearScrolling={false}
                                                className={classes.dateSelect}
                                            />
                                        </Grid>
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </FormGroup>

                            <FormGroup row className={classes.fieldSection}>
                                <Typography variant="caption" gutterBottom>
                                    {description_label}
                                </Typography>
                                <CKEditor
                                    activeClass={classes.ckeditor}
                                    content={this.state.description} 
                                    events={{
                                        "blur": this.updateDescription,
                                        "change": this.updateDescription,
                                        "keyup": this.updateDescription
                                    }}
                                    config={{
                                        disableNativeSpellChecker: false,
                                    }}
                                />
                            </FormGroup>

                            <FormGroup row className={classes.fieldSection}>
                                <Typography variant="caption" gutterBottom>
                                    Optional File Upload
                                </Typography>
                                <Grid container space={16}>
                                    <Grid item sm={4} className={classes.gridField}>
                                        <input
                                            accept="*/*"
                                            className={classes.hidden}
                                            id="job_file"
                                            name="job_file"
                                            type="file"
                                            onChange={this.handleFileChange}
                                        />
                                        <label htmlFor="job_file">
                                            <Button variant="outlined" color="primary" component="span" className={classes.uploadButton} fullWidth>
                                                Select Files to Upload
                                            </Button>
                                        </label>
                                    </Grid>
                                    <Grid item sm={8} className={classes.gridField}>
                                        {this.state.job_file ? (
                                            <Typography variant="body2">
                                                {this.state.job_file_display}
                                                <a
                                                    href="#nofile"
                                                    className={classes.link}
                                                    onClick={this.handleRemoveFile}
                                                >
                                                    <CancelIcon
                                                        style={{
                                                            margin: '6px 3px -6px 20px'
                                                        }}
                                                    />
                                                    Remove File
                                                </a>
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </FormGroup>

                            <FormGroup row className={classes.fieldSection}>
                                <Typography variant="caption" gutterBottom>
                                    Custom Fields
                                </Typography>
                                {this.props.customFields.filter(field => field.active === true).map(
                                    field => (
                                        <TextField
                                            key={field.id}
                                            autoComplete="off"
                                            id={`customField_${field.id}`}
                                            name={`customField_${field.id}`}
                                            label={field.field_name}
                                            value={(() => {
                                                var value = this.state.custom_values.find(el => el.field === field.id);
                                                return value ? value.field_value : ''
                                            })()}
                                            fullWidth
                                            margin="dense"
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    )
                                )}
                            </FormGroup>

                            <FormGroup row className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    component={Link} to="/my_panel"
                                >
                                    <CancelIcon className={classes.buttonIcon} />
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={(e) => this.saveJob(true)}
                                >
                                    <SaveIcon className={classes.buttonIcon} />
                                    Save as Daft
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={(e) => this.saveJob()}
                                >
                                    <SendIcon className={classes.buttonIcon} />
                                    Submit Request Now
                                </Button>
                            </FormGroup>
                        </Paper>
                    </Grid>
                    <Grid item lg={4} md={12} className={classes.content}>
                        <Typography variant="h4" gutterBottom>
                            How does it work?
                        </Typography>
                        <Paper>
                            {this.props.type === 'Project' ? (
                                <JobStepsProject />
                            ) : null }
                            {this.props.type === 'Contractor' ? (
                                <JobStepsContractor />
                            ) : null }
                            {this.props.type === 'FTE' ? (
                                <JobStepsFTE />
                            ) : null }
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    // handleLogin: (data) => {
    //     loginActions.login(dispatch, data)
    // },
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobForm))
