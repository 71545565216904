import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router'

import * as loginActions from '../actions/loginActions'

import classes from './Login.module.sass'


class Login extends Component {
    state = {
        username: '',
        password: '',
        showPassword: false,
    }

    handleChange = fieldName => event => {
        this.setState({
            [fieldName]: event.target.value
        })
        this.props.removeError(fieldName)
    }

    handleClickShowPassword = (event) => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    enterLogin = (event) => {
        const ENTER_CODE = 13
        if (event.keyCode === ENTER_CODE) {
            this.props.handleLogin({
                username: this.state.username,
                password: this.state.password
            })
        }
    }

    render = () => {
        if (this.props.loggedIn) {
            return <Redirect push to="/my_panel/" />
        }
        const errors = this.props.errors
        
        return (
            <div className={classes.root}>
                <Grid container justify="center" spacing={16}>
                    <Grid item lg={5} md={6}>
                        <Paper className={classes.content}>
                            <Typography variant="h3" gutterBottom>
                                Login
                            </Typography>
                            <hr />
                            {errors.non_field_errors && errors.non_field_errors.map((msg) => (
                                <FormHelperText key={msg} error>
                                    {msg}
                                </FormHelperText>
                            ))}
                            <br />
                            <TextField
                                id="username"
                                name="username"
                                label="Username"
                                className={classes.formField}
                                variant="outlined"
                                type="text"
                                error={errors.username ? true : false}
                                helperText={errors.username ? errors.username.join(" ") : ""}
                                value={this.state.username}
                                onChange={this.handleChange('username')}
                                InputProps={{startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                )}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="password"
                                name="password"
                                label="Password"
                                className={classes.formField}
                                variant="outlined"
                                type={this.state.showPassword ? 'text' : 'password'}
                                error={errors.password ? true : false}
                                helperText={errors.password ? errors.password.join(" ") : ""}
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                onKeyDown={this.enterLogin}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Show password"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => this.props.handleLogin({
                                    username: this.state.username,
                                    password: this.state.password
                                })}>
                                Login
                            </Button>

                            <div className={classes.linkSection}>
                                <Link to="/forgot_password" className={classes.link}>
                                    Forgot your password?
                                </Link>
                                <br />
                                <Link to="/signup" className={classes.link}>
                                    Signup.
                                </Link>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    handleLogin: (data) => {
        loginActions.login(dispatch, data)
    },
    removeError: (fieldName) => {
        dispatch({
            type: loginActions.REMOVE_ERROR,
            fieldName: fieldName,
        })
    },
})


export default connect(mapStateToProps, mapDispatchToProps)(Login)
