import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import NotificationsIcon from '@material-ui/icons/Notifications'
import EditIcon from '@material-ui/icons/Edit'
import SecurityIcon from '@material-ui/icons/Security'

import * as userActions from '../actions/userActions'
import EditProfile from './EditProfile'
import NotificationSettings from '../../notifications/components/NotificationSettings'
import ChangePassword from './ChangePassword'
import LeftNavBar from '../../src/components/LeftNavBar'
import RightNavBar from '../../src/components/RightNavBar'
import AlternateRightNavBar from '../../src/components/AlternateRightNavBar'
import NotificationsList from '../../notifications/components/NotificationsList'

import classes from './MyAccount.module.sass'


class MyAccount extends Component {
    state = {
        menuValue: 0,
        editProfileOpen: false,
        profile: {},
        changePasswordOpen: false,
        notificationSettingsOpen: false,
    }

    componentDidMount = () => {
        this.updateProfile()
    }

    componentDidUpdate = (previous) => {
        if (this.props !== previous) {
            this.updateProfile()
        }
    }

    updateProfile = () => {
        const profile = {
            id: this.props.userId,
            first_name: this.props.firstName,
            last_name: this.props.lastName,
            notification_email: this.props.notificationEmail,
            phone: this.props.phone,
            title: this.props.title,
            is_company_admin: this.props.companyAdmin,
            is_provider_admin: this.props.providerAdmin
        }
        this.setState({
            profile: profile
        })
    }

    handleChange = (event, value) => {
        this.setState({ menuValue: value })
    }

    handleProfileOpen = () => {
        this.setState({
            editProfileOpen: true
        })
    }

    handleProfileClose = () => {
        this.setState({
            editProfileOpen: false
        })
    }

    handlePasswordOpen = () => {
        this.setState({
            changePasswordOpen: true
        })
    }

    handlePasswordClose = () => {
        this.setState({
            changePasswordOpen: false
        })
    }

    handleNotificationSettingsOpen = () => {
        this.setState({
            notificationSettingsOpen: true
        })
    }

    handleNotificationSettingsClose = () => {
        this.setState({
            notificationSettingsOpen: false
        })
    }

    handleUpdateMember = (userId, formData) => {
        this.props.updateMyInfo({
            token: this.props.token,
            userId: userId,
            formData: formData
        })
    }

    renderRightNav = () => (
        <div>
            <Typography variant="h6" className={classes.sideTitle}>
                Notifications.
                <Card>
                    <CardContent style={{ padding: 8, fontSize: '1rem', fontWeight: 400 }}>
                        <Typography variant="overline">
                            New Comment on Job #10494
                        </Typography>
                        <Typography variant="body2">
                            We are ready to go ahead with deployment if you
                            could send credentials to our devops pers...
                        </Typography>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: 8 }}>
                    <CardContent style={{ padding: 8, fontSize: '1rem', fontWeight: 400 }}>
                        <Typography variant="overline">
                           Candidate Added to Job #10495
                        </Typography>
                        <Typography variant="body2">
                            Sarah Barlogni. Sr. Developer. Rate: $135/hr.
                            Experienced with modern Frontend stacks like Reactjs,
                            Red...
                        </Typography>
                    </CardContent>
                </Card>
            </Typography>

            <Typography variant="h6" className={classes.sideTitle}>
                Meetings.
                <Card>
                    <CardContent style={{ padding: 8, fontSize: '1rem', fontWeight: 400 }}>
                        <Typography variant="overline">
                           Candidate Intereview: 04/16/19
                        </Typography>
                        <Typography variant="body2">
                            Wilma Watson at 3:30 PM PDT.
                            <br />
                            Call: (206) 545-4688
                        </Typography>
                    </CardContent>
                </Card>
            </Typography>
        </div>
    )

    render = () => {
        return (
            <div className={classes.root}>
                <LeftNavBar>
                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handlePasswordOpen}
                    >
                        <SecurityIcon className={classes.actionButtonIcon} />
                        Change password
                    </Button>
                    <ChangePassword
                        open={this.state.changePasswordOpen}
                        userId={this.props.userId}
                        token={this.props.token}
                        handleClose={this.handlePasswordClose}
                    />
                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleNotificationSettingsOpen}
                    >
                        <NotificationsIcon className={classes.actionButtonIcon} />
                        Notification preferences
                    </Button>
                    <NotificationSettings
                        open={this.state.notificationSettingsOpen}
                        userId={this.props.userId}
                        token={this.props.token}
                        handleClose={this.handleNotificationSettingsClose}
                    />

                    <AlternateRightNavBar>
                        <NotificationsList />
                    </AlternateRightNavBar>
                </LeftNavBar>
                
                <Paper className={classes.newContent}>
                    <Tabs
                        value={this.state.menuValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="off"
                        className={classes.tabNav}
                    >
                        <Tab label={'Personal Information'} className={classes.tab} value={0} />
                    </Tabs>
                    {this.state.menuValue === 0 ? (
                        <div>
                            <Typography variant="h6" className={classes.dtitle}>
                                Personal Information
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.rightFloatButton}
                                    onClick={this.handleProfileOpen}
                                >
                                    <EditIcon className={classes.buttonIcon} fontSize="small" />
                                    Edit
                                </Button>
                                <EditProfile
                                    open={this.state.editProfileOpen}
                                    profile={this.state.profile}
                                    handleClose={this.handleProfileClose}
                                    updateMember={this.handleUpdateMember}
                                    companyId={this.props.companyId}
                                    companyAdmin={this.props.companyAdmin}
                                />
                            </Typography>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Name
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.firstName + ' ' + this.props.lastName}
                                </Typography>
                            </div>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Title
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.title}
                                </Typography>
                            </div>
                            {this.props.companyId ? (
                                <div className={classes.dl}>
                                    <Typography variant="inherit" className={classes.dt}>
                                        Company
                                    </Typography>
                                    <Typography variant="inherit" className={classes.dd}>
                                        {this.props.companyName}
                                    </Typography>
                                </div>
                            ) : null}
                            {this.props.providerId ? (
                                <div className={classes.dl}>
                                    <Typography variant="inherit" className={classes.dt}>
                                        Provider Group
                                    </Typography>
                                    <Typography variant="inherit" className={classes.dd}>
                                        {this.props.providerName}
                                    </Typography>
                                </div>
                            ) : null}
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Username
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.username}
                                </Typography>
                            </div>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Notification Email
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.notificationEmail}
                                </Typography>
                            </div>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Phone Number
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.phone}
                                </Typography>
                            </div>
                            
                        </div>
                    ) : null}
                </Paper>

                <RightNavBar>
                    <NotificationsList />
                </RightNavBar>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    updateMyInfo: (data) => {
        userActions.updateMyInfo(dispatch, data)
    },
})


export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
