import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import DecloupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import Grid from '@material-ui/core/Grid'

import classes from './EditCompany.module.sass'


class EditCompany extends Component {
    state = {
        name: "",
        email: "",
        phone: "",
        website: "",
        logo_file: "",
        about_us: "",
        primary_address: {}
    }

    componentDidMount = () => {
        this.setCompanyState(this.props.company)
    }

    componentDidUpdate = (previous) => {
        if (this.props.company !== null &&
                (this.props.company !== previous.company ||
                this.props.company.primary_address !== previous.company.primary_address)) {
            this.setCompanyState(this.props.company)
        }
    }

    setCompanyState = (company) => {
        this.setState({...company})
    }

    updateAboutUs = (editor) => {
        const about_us = editor.getData()
        this.setState({
            about_us: about_us
        })
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        if (key.search('__') !== -1) {
            const keys = key.split('__')
            const stateKey = keys[0]
            const fieldKey = keys[1]
            let updatedData = {...this.state[stateKey]}
            updatedData[fieldKey] = value
            this.setState({
                [stateKey]: updatedData
            })
        } else {
            this.setState({
                [key]: value
            })
        }
    }

    handleFileChange = (event) => {
        const file = event.target.files[0]
        this.setState({
            logo_file: file
        })
    }

    save = (event) => {
        let formData = new FormData()
        formData.append('name', this.state.name)
        formData.append('email', this.state.email)
        formData.append('phone', this.state.phone)
        formData.append('website', this.state.website)
        formData.append('about_us', this.state.about_us)
        formData.append('primary_address', this.state.primary_address)
        if (this.state.logo_file !== this.props.company.logo_file) {
            formData.append('logo_file', this.state.logo_file)
        }
        this.props.updateCompany(formData)
        this.props.handleClose(event)
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="edit-company-dialog"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="edit-company-dialog">
                    Edit {this.props.company.name}
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <Grid container space={16}>
                        <Grid item sm={6} className={classes.content}>
                            <img src={this.props.company.logo_file} className={classes.logoFile} alt="logo file" />
                        </Grid>
                        <Grid item sm={6} className={classes.content}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="logo_file"
                                name="logo_file"
                                type="file"
                                onChange={this.handleFileChange}
                            />
                            <label htmlFor="logo_file">
                                <Button variant="outlined" color="primary" component="span" className={classes.uploadButton}>
                                    Upload New Logo File
                                </Button>
                            </label>
                        </Grid>

                        <Grid item sm={12} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Company Name"
                                name="name"
                                value={this.state.name}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>

                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="phone"
                                name="phone"
                                label="Phone Number"
                                value={this.state.phone}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="website"
                                name="website"
                                label="Website"
                                value={this.state.website}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>

                        <Grid item sm={12} className={classes.content}>
                            <label htmlFor="about_us" className={classes.aboutLabel}>
                                About Us
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={this.state.about_us}
                                onChange={( event, editor ) => {
                                    this.updateAboutUs(editor)
                                }}
                                onBlur={( event, editor ) => {
                                    this.updateAboutUs(editor)
                                }}
                                config={{
                                    disableNativeSpellChecker: false,
                                }}
                            />
                        </Grid>

                        <Grid item sm={8} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="primary_address__street1"
                                name="primary_address__street1"
                                label="Address Line 1"
                                value={this.state.primary_address ? this.state.primary_address.street1 : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="primary_address__street2"
                                name="primary_address__street2"
                                label="Address Line 2"
                                value={this.state.primary_address ? this.state.primary_address.street2 : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>

                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="primary_address__city"
                                name="primary_address__city"
                                label="City"
                                value={this.state.primary_address ? this.state.primary_address.city : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="primary_address__state_code"
                                name="primary_address__state_code"
                                label="State (code)"
                                value={this.state.primary_address ? this.state.primary_address.state_code : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="primary_address__postal_code"
                                name="primary_address__postal_code"
                                label="Zip Code"
                                value={this.state.primary_address ? this.state.primary_address.postal_code : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary" className={classes.dialogButton}>
                        Cancel
                    </Button>
                    <Button onClick={this.save} color="primary" variant="contained" className={classes.dialogButton}>
                        Save and Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default EditCompany