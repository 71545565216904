import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import classes from './EstimateMilestone.module.sass'


const EstimateMilestone = (props) => {
    return (
        <Card className={classes.milestone}>
            <CardHeader
                action={
                    <React.Fragment>
                        <IconButton
                            onClick={event => (
                                props.moveUp(props.milestone.order)
                            )}
                            title="Move milestone up"
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton
                            onClick={event => (
                                props.moveDown(props.milestone.order)
                            )}
                            title="Move milestone down"
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton
                            onClick={event => (
                                props.deleteMilestone(props.milestone.order)
                            )}
                            title="Delete milestone"
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </React.Fragment>
                }
                title={`Milestone ${props.milestone.order}.`}
                style={{
                    paddingBottom: 0
                }}
            />
            <CardContent style={{ paddingTop: 0 }}>
                <Grid container spacing={16} style={{ marginBottom: 4 }}>
                    <Grid item md={8} lg={9}>
                        <TextField
                            id={'milestone_title_' + props.milestone.order}
                            name="title"
                            value={props.milestone.title}
                            label="Title"
                            placeholder="Project Completion (default)"
                            fullWidth
                            onChange={event => props.handleChange(event, props.index)}
                        />
                    </Grid>
                    <Grid item md={4} lg={3}>
                        <TextField
                            id={'milestone_percent_' + props.milestone.order}
                            name="percent"
                            value={props.milestone.percent}
                            label="Percent"
                            placeholder="100"
                            fullWidth
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            onChange={event => props.handleChange(event, props.index)}
                        />
                    </Grid>
                </Grid>
                <TextField
                    id={'milestone_content_' + props.milestone.order}
                    name="content"
                    value={props.milestone.content}
                    label="Requirements"
                    placeholder="Milestone completion requirements"
                    fullWidth
                    multiline
                    rows="2"
                    onChange={event => props.handleChange(event, props.index)}
                />
            </CardContent>
        </Card>
    )
}

export default EstimateMilestone
