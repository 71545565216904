import React, { Component } from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'

import classes from './InvoicesTab.module.sass'


class InvoicesTab extends Component {
    state = {}

    formatDate = (dateString) => {
        let date = new moment(dateString)
        if (date.isValid()) {
            return date.format('MM/DD/YYYY')
        }
        return ''
    }

    render = () => {
        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    Invoices
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'invoice_number'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'invoice_number', 'invoices'
                                        )}
                                    >
                                        Invoice #
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'request_date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'request_date', 'invoices'
                                        )}
                                    >
                                        Requested
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'invoice_date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'invoice_date', 'invoices'
                                        )}
                                    >
                                        Invoiced
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'due_date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'due_date', 'invoices'
                                        )}
                                    >
                                        Due
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'payment_date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'payment_date', 'invoices'
                                        )}
                                    >
                                        Paid
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            {this.props.userType === 'Provider' ? (
                                <TableCell className={classes.tableHead}>
                                    <Tooltip
                                        title="Sort"
                                        placement={'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={this.props.orderBy === 'provider_payment_real_amount'}
                                            direction={this.props.order}
                                            onClick={() => this.props.handleSort(
                                                'provider_payment_real_amount', 'invoices'
                                            )}
                                        >
                                            Payment Amount
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            ) : (
                                <TableCell className={classes.tableHead}>
                                    <Tooltip
                                        title="Sort"
                                        placement={'bottom-start'}
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={this.props.orderBy === 'amount'}
                                            direction={this.props.order}
                                            onClick={() => this.props.handleSort(
                                                'amount', 'invoices'
                                            )}
                                        >
                                            Amount
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>

                            )}
                            <TableCell className={classes.tableHead}>
                                Description
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.invoices.map((invoice) => {
                            return (
                                <TableRow key={invoice.id}>
                                    <TableCell className={classes.tableCell}>
                                        <a href={invoice.pdf} className={classes.link} target="_blank" rel="noopener noreferrer">
                                            {"#" + invoice.invoice_number}
                                        </a>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(invoice.request_date)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(invoice.invoice_date)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(invoice.due_date)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(invoice.payment_date)}
                                    </TableCell>
                                    {this.props.userType === 'Provider' ? (
                                        <TableCell className={classes.tableCell}>
                                            {"$" + invoice.provider_payment_real_amount}
                                        </TableCell>
                                    ) : (
                                        <TableCell className={classes.tableCell}>
                                            {"$" + invoice.amount}
                                        </TableCell>
                                    )}
                                    
                                    <TableCell className={classes.tableCell}>
                                        <div dangerouslySetInnerHTML={{
                                            __html: invoice.description }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


export default InvoicesTab

