import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Edit from '@material-ui/icons/Edit'
import AccessTime from '@material-ui/icons/AccessTime'
import Notifications from '@material-ui/icons/Notifications'
import moment from 'moment'

import CandidateForm from './CandidateForm'
import CandidateCardCustomerMenu from './CandidateCardCustomerMenu'

import classes from './CandidateCard.module.sass'


const CONTRACTOR_FEE = 15


class CandidateCard extends Component {
    state = {
        open: false
    }

    userInGroups = (groups=[]) => {
        if (groups.indexOf(this.props.userType) !== -1) {
            return true
        }
        return false
    }

    providerPayout = () => {
        return this.moneyString(
            this.props.candidate.rate * (100.0 - CONTRACTOR_FEE) / 100.0
        )
    }

    moneyString = (value) => {
        if (value % 1 === 0) {
            return value
        }
        return parseFloat(value).toFixed(2)
    }

    handleOpen = (event) => {
        this.setState({
            open: true
        })
    }

    handleClose = (event) => {
        this.setState({
            open: false
        })
    }

    handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        this.setState({
            [name]: value
        })
    }

    updateCandidate = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.props.updateCandidate(this.props.candidate.id, {
            [key]: value
        })
    }

    renderMenu = () => {
        if (this.userInGroups(['Provider', 'SubProvider'])) {
            return (
                <div className={classes.actionSection}>
                    {this.props.candidate.interested !== "Hired" ?
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.actionButton}
                            onClick={this.handleOpen}
                        >
                            <Edit className={classes.actionButtonIcon} />
                            Edit candidate
                        </Button>
                    : null}
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.actionButton}
                    >
                        <AccessTime className={classes.actionButtonIcon} />
                        View Proposed Interview Times
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.actionButton}
                    >
                        <Notifications className={classes.actionButtonIcon} />
                        View Offer
                    </Button>
                </div>
            )
        } else if (this.userInGroups(['Customer'])) {
            return (
                <CandidateCardCustomerMenu
                    candidate={this.props.candidate}
                    updateCandidate={this.updateCandidate}
                />
            )
        }
    }

    render = () => {
        const candidate = this.props.candidate
        let header = (
            <Typography variant="body2">
                {candidate.fullname}
                <span className={classes.right}>
                    {this.userInGroups(['Customer', 'CustomerCollaborator']) ? (
                        <span>${candidate.customer_rate}</span>
                    ) : null}
                    {this.userInGroups(['Provider', 'ProviderCollaborator']) ? (
                        <span>${candidate.customer_rate} [${this.providerPayout()}]</span>
                    ) : null}
                    {this.userInGroups(['SubProvider']) ? (
                        <span>${candidate.rate} [${this.providerPayout()}]</span>
                    ) : null}
                </span>
            </Typography>
        )
        let subheader = candidate.title
        if (candidate.approved) {
            let presentedDate = new moment(candidate.approved_date)
            if (presentedDate.isValid()) {
                subheader += ` (Presented: ${presentedDate.format('llll')})`
            }
        }
        return (
            <div>
                <CandidateForm
                    handleClose={this.handleClose}
                    open={this.state.open}
                />
                
                <Grid container spacing={0}>
                    <Grid item xs={3}>
                        {this.renderMenu()}
                    </Grid>
                    <Grid item xs={9}>
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="Commenter">
                                        {candidate.first_name[0]}
                                    </Avatar>
                                }
                                title={header}
                                subheader={subheader}
                            />
                            <CardContent className={classes.cardContent}>
                                <div className={classes.dl}>
                                    <Typography variant="inherit" className={classes.dt}>
                                        Status
                                    </Typography>
                                    <Typography variant="inherit" className={classes.dd}>
                                        {candidate.interested}
                                    </Typography>
                                </div>
                                <div className={classes.dl}>
                                    <Typography variant="inherit" className={classes.dt}>
                                        Email
                                    </Typography>
                                    <Typography variant="inherit" className={classes.dd}>
                                        {candidate.email}
                                    </Typography>
                                </div>
                                <div className={classes.dl}>
                                    <Typography variant="inherit" className={classes.dt}>
                                        Phone
                                    </Typography>
                                    <Typography variant="inherit" className={classes.dd}>
                                        {candidate.phone}
                                    </Typography>
                                </div>
                                <div className={classes.dl}>
                                    <Typography variant="inherit" className={classes.dt}>
                                        Description
                                    </Typography>
                                    <Typography variant="inherit" className={classes.dd}>
                                        {candidate.description}
                                    </Typography>
                                </div>
                                {['Submitted', 'Accepted', 'Change Requested'].indexOf(candidate.offer_status) !== -1 ? (
                                    <div className={classes.dl}>
                                        <Typography variant="inherit" className={classes.dt}>
                                            Offer
                                        </Typography>
                                        <Typography variant="inherit" className={classes.dd}>
                                            {candidate.offer_accepted && candidate.offer_accepted_by ? (
                                                <React.Fragment>
                                                    Accepted by: {candidate.offer_accepted_by.fullname}&nbsp;
                                                    <em>
                                                        ({this.props.dateTimeDisplay(candidate.offer_accepted_date)})
                                                    </em>
                                                    <br />
                                                </React.Fragment>
                                            ) : null}
                                            Requested:&nbsp;
                                            {candidate.requested_start ? this.props.dateDisplay(candidate.requested_start) : ''}
                                            {candidate.requested_end ? ` through ${this.props.dateDisplay(candidate.requested_end)}` : null}
                                            {candidate.requested_start === null && candidate.requested_end === null ? 'As soon as possible' : ''}
                                            <br />
                                            {candidate.work_schedule ? (
                                                <React.Fragment>
                                                    Schedule: {candidate.work_schedule}
                                                    <br />
                                                </React.Fragment>
                                            ) : null }
                                            {candidate.location ? (
                                                <React.Fragment>
                                                    Location: {candidate.location}
                                                    {candidate.remote && candidate.remote !== '0' ? ' - Remote OK' : null }
                                                    <br />
                                                </React.Fragment>
                                            ) : candidate.remote && candidate.remote !== '0' ? (
                                                <React.Fragment>
                                                    Remote OK
                                                    <br />
                                                </React.Fragment>
                                            ) : null }
                                            {candidate.report_to ? (
                                                <React.Fragment>
                                                    Report to: {candidate.report_to}
                                                    <br />
                                                </React.Fragment>
                                            ) : null }
                                            Rate: ${candidate.offer_rate}
                                            <br />
                                            {candidate.offer_details ? (
                                                `Details: ${candidate.offer_details}`
                                            ) : null }
                                        </Typography>
                                    </div>
                                ) : null }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default CandidateCard