import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import classes from './EditProfile.module.sass'


class EditProfile extends Component {
    state = {
        id: null,
        first_name: "",
        last_name: "",
        notification_email: "",
        phone: "",
        title: "",
        is_company_admin: false,
        is_provider_admin: false
    }

    componentDidMount = () => {
        this.setProfileState(this.props.profile)
    }

    componentDidUpdate = (previous) => {
        if (this.props.profile !== null &&
                (this.props.profile !== previous.profile)) {
            this.setProfileState(this.props.profile)
        }
    }

    setProfileState = (profile) => {
        this.setState({...profile})
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.checked
        })
    }

    save = (event) => {
        const formData = {...this.state}
        const memberId = formData.id
        this.props.updateMember(memberId, formData)
        this.props.handleClose(event)
    }

    dialogTitle = () => {
        return `Edit ${this.props.profile.fullname}`
    }

    emailLabel = () => {
        return 'Notification Email'
    }

    render() {
        if (this.props.profile === null) {
            return <div />
        }
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="edit-profile-dialog"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="edit-profile-dialog">
                    {this.dialogTitle()}
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <Grid container space={16}>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                value={this.state.first_name}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                value={this.state.last_name}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container space={16}>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="notification_email"
                                name="notification_email"
                                label={this.emailLabel()}
                                value={this.state.notification_email}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="phone"
                                name="phone"
                                label="Phone Number"
                                value={this.state.phone}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container space={16}>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="title"
                                name="title"
                                label="Title"
                                value={this.state.title}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={6} className={classes.content}>
                            {this.props.companyId && this.props.companyAdmin ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.is_company_admin}
                                            onChange={this.handleChange('is_company_admin')}
                                            value="is_company_admin"
                                            color="primary"
                                        />
                                    }
                                    label="Is Company Admin?"
                                />
                            ) : null}
                            {this.props.providerId && this.props.providerAdmin ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.is_provider_admin}
                                            onChange={this.handleChange('is_provider_admin')}
                                            value="is_provider_admin"
                                            color="primary"
                                        />
                                    }
                                    label="Is Group Admin?"
                                />
                            ) : null}
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.handleClose}
                        color="primary"
                        className={classes.dialogButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.save}
                        color="primary"
                        variant="contained"
                        className={classes.dialogButton}
                    >
                        Save and Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default EditProfile
