import React from 'react'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'

import classes from './Comment.module.sass'


const CommentCard = (props) => {
    const commenter = props.comment.commenter
    const initials = commenter.first_name[0]
    let commenterName = commenter.fullname
    let cardStyle = {
        width: '80%',
        float: 'left',
        margin: '8px'
    }

    if (parseInt(props.userId) === commenter.id) {
        cardStyle.float = 'right'
    }

    let commentDateDisplay = props.comment.date
    let commentDateTime = new moment(props.comment.date)
    if (commentDateTime.isValid()) {
        commentDateDisplay = commentDateTime.format('llll')
    }

    return (
        <div className={classes.clearFix}>
            <Card style={cardStyle} >
                <CardHeader
                    avatar={
                        <Avatar aria-label="Commenter">
                            {initials}
                        </Avatar>
                    }
                    title={commenterName}
                    subheader={commentDateDisplay}
                />
                <CardContent style={{ paddingTop: 0 }}>
                    <div dangerouslySetInnerHTML={{
                        __html: props.comment.comment }}
                        className={classes.inlineP}
                    />
                </CardContent>
            </Card>
        </div>
    )
}

export default CommentCard