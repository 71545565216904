import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import axios, { CancelToken } from 'axios'
import { Link } from 'react-dom'

import PersonAddIcon from '@material-ui/icons/PersonAdd'
import BusinessIcon from '@material-ui/icons/Business'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'

import LeftNavBar from '../../src/components/LeftNavBar'
import RightNavBar from '../../src/components/RightNavBar'
import AlternateRightNavBar from '../../src/components/AlternateRightNavBar'
import NotificationsList from '../../notifications/components/NotificationsList'
import ProviderProfileTab from './ProviderProfileTab'
import ProviderMembersTab from './ProviderMembersTab'
import ProviderCustomersTab from './ProviderCustomersTab'
// import AvailableProjectsTab from './AvailableProjectsTab'
import AddProviderMember from './AddProviderMember'
import InviteCustomer from './InviteCustomer'

import classes from './MyGroup.module.sass'


class MyGroup extends Component {
    ajaxToken = null
    ajaxCancel = null

    state = {
        menuValue: 0,
        provider: {},
        members: [],
        customers: [],
        availableProjects: [],
        membersOrderBy: '',
        membersOrder: 'desc',
        customersOrderBy: '',
        customersOrder: 'desc',
        availableProjectsOrderBy: '',
        availableProjectsOrder: 'desc',
        addMemberOpen: false,
        inviteCustomerOpen: false,
    }

    componentDidMount = () => {
        this.ajaxToken = new CancelToken(c => {this.ajaxCancel = c})
        if (this.props.providerId !== null) {
            this.loadProvider()
            this.loadMembers()
            this.loadCustomers()
        }
    }

    componentDidUpdate = (previous) => {
        if (this.props.providerId !== null &&
                this.props.providerId !== previous.providerId) {
            this.loadProvider()
            this.loadMembers()
            this.loadCustomers()
        }
    }

    componentWillUnmount = () => {
        this.ajaxCancel('Unmounting MyGroup.')
    }

    loadProvider = () => {
        const url = `/api/providers/${this.props.providerId}/`
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                provider: response.data
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    loadMembers = () => {
        let url = `/api/users/?provider_group=${this.props.providerId}`
        if (this.state.membersOrderBy !== '') {
            url += '&ordering='
            if (this.state.membersOrder === 'desc') {
                url += '-'
            }
            url += this.state.membersOrderBy
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                members: response.data.results
            })
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    loadCustomers = () => {
        let url = `/api/providers/${this.props.providerId}/customers/`
        if (this.state.customersOrderBy !== '') {
            url += '?ordering='
            if (this.state.customersOrder === 'desc') {
                url += '-'
            }
            url += this.state.customersOrderBy
        }
        axios.get(url, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.setState({
                customers: response.data
            })
        })
        .catch(error => {
            console.log(error.message)
        })
    }

    updateMember = (memberId, formData) => {
        const url = `/api/users/${memberId}/`
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadMembers()
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    addMember = (formData) => {
        const url = `/api/users/add_provider_member/`
        axios.patch(url, formData, {
            headers: {
                Authorization: `Token ${this.props.token}`
            },
            cancelToken: this.ajaxToken
        })
        .then(response => {
            this.loadMembers()
        })
        .catch(thrown => {
            console.log(thrown.message)
        })
    }

    inviteCustomer = (formData) => {
        return
        // const url = `/api/customers/invite_customer/`
        // axios.post(url, formData, {
        //     headers: {
        //         Authorization: `Token ${this.props.token}`
        //     },
        //     cancelToken: this.ajaxToken
        // })
        // .then(response => {
        //     this.loadCustomers()
        // })
        // .catch(thrown => {
        //     console.log(thrown.message)
        // })
    }

    handleChange = (event, value) => {
        this.setState({ menuValue: value })
    }

    handleSort = (value, type='members') => {
        let order = 'asc'
        if (this.state[type + 'OrderBy'] === value) {
            if (this.state[type + 'Order'] === 'asc') {
                order = 'desc'
            }
        }
        this.setState({
            [type + 'OrderBy']: value,
            [type + 'Order']: order
        }, () => {
            if (type === 'members') {
                this.loadMembers()
            } else if (type === 'customers') {
                this.loadCustomers()
            } else if (type === 'availableProjects') {
                // this.loadAvailableProjects()
            }
        })
    }

    handleAddMemberOpen = () => {
        this.setState({
            addMemberOpen: true
        })
    }

    handleAddMemberClose = () => {
        this.setState({
            addMemberOpen: false
        })
    }

    handleInviteCustomerOpen = () => {
        this.setState({
            inviteCustomerOpen: true
        })
    }

    handleInviteCustomerClose = () => {
        this.setState({
            inviteCustomerOpen: false
        })
    }

    render = () => {
        return (
            <div className={classes.root}>
                <LeftNavBar>
                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleAddMemberOpen}
                    >
                        <PersonAddIcon className={classes.actionButtonIcon} />
                        Add Member to My Group
                    </Button>
                    <AddProviderMember
                        open={this.state.addMemberOpen}
                        profile={{}}
                        handleClose={this.handleAddMemberClose}
                        addMember={this.addMember}
                        providerId={this.props.providerId}
                        providerName={this.props.providerName}
                    />
                    <Button 
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        onClick={this.handleInviteCustomerOpen}
                    >
                        <BusinessIcon className={classes.actionButtonIcon} />
                        Invite customer to join
                    </Button>
                    <InviteCustomer
                        open={this.state.inviteCustomerOpen}
                        handleClose={this.handleInviteCustomerClose}
                        inviteCustomer={this.inviteCustomer}
                        providerId={this.props.providerId}
                        providerName={this.props.providerName}
                    />
                    <Button
                        color="primary"
                        size="large"
                        className={classes.navButton}
                        component={Link} to="/provider_proposed/"
                    >
                        <CreateNewFolderIcon className={classes.actionButtonIcon} />
                        Propose new project
                    </Button>
                    <AlternateRightNavBar>
                        <NotificationsList />
                    </AlternateRightNavBar>
                </LeftNavBar>
                <Paper className={classes.newContent}>
                        
                    <Tabs
                        value={this.state.menuValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="off"
                        className={classes.tabNav}
                    >
                        <Tab label={'My Provider Group'} value={0} />
                        <Tab label={'Members'} value={1} />
                        <Tab label={'Customers'} value={2} />
                        <Tab label={'Assigned Skill Categoires'} value={3} />
                        <Tab label={'My Solutions'} value={4} />
                        {/* request a skill too */}
                    </Tabs>

                    {this.state.menuValue === 0 ? (
                        <ProviderProfileTab
                            isProviderAdmin={this.props.isProviderAdmin}
                            providerId={this.props.providerId}
                            provider={this.state.provider}
                            updateMyGroup={(formData) => {
                                this.props.updateMyGroup(formData, this.loadProvider)
                            }}
                        />
                    ) : null}
                    {this.state.menuValue === 1 ? (
                         <ProviderMembersTab
                            isProviderAdmin={this.props.isProviderAdmin}
                            providerId={this.props.providerId}
                            provider={this.state.provider}
                            members={this.state.members}
                            orderBy={this.state.membersOrderBy}
                            order={this.state.membersOrder}
                            handleSort={this.handleSort}
                            updateMember={this.updateMember}
                        />
                    ) : null}
                    {this.state.menuValue === 2 ? (
                        <ProviderCustomersTab
                            token={this.props.token}
                            isProviderAdmin={this.props.isProviderAdmin}
                            providerId={this.props.providerId}
                            provider={this.state.provider}
                            customers={this.state.customers}
                            orderBy={this.state.customersOrderBy}
                            order={this.state.customersOrder}
                            handleSort={this.handleSort}
                        />
                    ) : null}
                </Paper>

                <RightNavBar>
                    <NotificationsList />
                </RightNavBar>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(MyGroup)

