import React, { Component } from 'react'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CKEditor from 'react-ckeditor-component'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
// import Snackbar from '@material-ui/core/Snackbar'
// import SnackbarContent from '@material-ui/core/SnackbarContent'
// import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'


import AddCircleIcon from '@material-ui/icons/AddCircle'

import classes from './InvoicingWorklogsTab.module.sass'


class InvoicingWorklogsTab extends Component {
    state = {
        hours: '',
        date_range_from: null,
        date_range_to: null,
        comment: '',
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            [key]: value
        })
    }

    updateComment = (event) => {
        const currentComment = event.editor.getData();
        this.setState({
            comment: currentComment
        })
    }

    formatDate = (dateString) => {
        let date = new moment(dateString)
        if (date.isValid()) {
            return date.format('MM/DD/YYYY')
        }
        return ''
    }

    formatDateRange = (worklog) => {
        let dateRange = ""
        if (worklog.date_range_from !== null) {
            dateRange += this.formatDate(worklog.date_range_from)
        }
        if (worklog.date_range_to !== null) {
            let endDate = this.formatDate(worklog.date_range_to)
            if (endDate !== '' && dateRange !== '') {
                dateRange += ' - ' + endDate
            } else {
                dateRange += endDate
            }
        }
        return dateRange
    }

    addWorklog = () => {
        let formData = new FormData()
        formData.append('logger', this.props.userId)
        formData.append('log_date', new Date().toISOString())
        formData.append('hours', this.state.hours)
        formData.append(
            'date_range_from',
            moment(this.state.date_range_from).format()
        )
        formData.append(
            'date_range_to',
            moment(this.state.date_range_to).format()
        )
        formData.append('comment', this.state.comment)
        formData.append('job', this.props.jobId)
        this.props.addWorklog(formData, () => {

        })
    }

    renderProviderForm = () => {
        return (
            <React.Fragment>
                <Typography variant="h6" className={classes.dtitle}>
                    Add a Work Log
                </Typography>
                <Paper className={classes.section}>
                    <Grid container space={16} className={classes.formRow}>
                        <Grid item md={12} lg={4} className={classes.gridField}>
                            <TextField
                                id="hours"
                                name="hours"
                                label="Hours worked"
                                value={this.state.hours}
                                type="text"
                                fullWidth
                                required
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item md={6} lg={4} className={classes.gridField}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    name="date_range_from"
                                    id="date_range_from"
                                    label="Date from"
                                    fullWidth
                                    placeholder="Optional"
                                    required
                                    value={this.state.date_range_from}
                                    onChange={(date) => this.updateField({ target: { name: 'date_range_from', value: date }})}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={6} lg={4} className={classes.gridField}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    name="date_range_to"
                                    id="date_range_to"
                                    label="Date to"
                                    fullWidth
                                    placeholder="Optional"
                                    required
                                    value={this.state.date_range_to}
                                    onChange={(date) => this.updateField({ target: { name: 'date_range_to', value: date }})}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container space={16} className={classes.formRow}>
                        <Grid item sm={12} className={classes.gridField}>
                            <CKEditor
                                activeClass={classes.ckeditor}
                                content={this.state.comment} 
                                events={{
                                    "blur": this.updateComment,
                                    "change": this.updateComment,
                                    "keyup": this.updateComment
                                }}
                                config={{
                                    disableNativeSpellChecker: false,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ textAlign: 'right' }}>
                        <Button 
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={this.addWorklog}
                            className={classes.formButton}
                        >
                            <AddCircleIcon className={classes.buttonIcon} />
                            Submit Worklog
                        </Button>
                    </div>
                </Paper>
            </React.Fragment>
        )
    }

    render = () => {
        return (
            <div>
                {this.props.userType === 'Provider' ? this.renderProviderForm() : null}
                <Typography variant="h6" className={classes.dtitle}>
                    Work Logs
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'logger__last_name'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'logger__last_name', 'worklogs'
                                        )}
                                    >
                                        Logged By
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'log_date'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'log_date', 'worklogs'
                                        )}
                                    >
                                        Date
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'hours'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'hours', 'worklogs'
                                        )}
                                    >
                                        Hours
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                <Tooltip
                                    title="Sort"
                                    placement={'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.props.orderBy === 'date_range_from'}
                                        direction={this.props.order}
                                        onClick={() => this.props.handleSort(
                                            'date_range_from', 'worklogs'
                                        )}
                                    >
                                        Notes
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.worklogs.map((worklog) => {
                            return (
                                <TableRow key={worklog.id}>
                                    <TableCell className={classes.tableCell}>
                                        {worklog.logger.fullname}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {this.formatDate(worklog.log_date)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {worklog.hours}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">
                                            {this.formatDateRange(worklog)}
                                        </Typography>
                                        <div dangerouslySetInnerHTML={{
                                            __html: worklog.comment }}
                                        />
                                        <Typography variant="body1">
                                            {worklog.id}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }
}


export default InvoicingWorklogsTab

