import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import EditIcon from '@material-ui/icons/Edit'

import EditProvider from '../../providers/components/EditProvider'

import classes from './ProviderProfileTab.module.sass'


class ProviderProfileTab extends Component {
    state = {
        editProviderOpen: false
    }

    handleProviderOpen = () => {
        this.setState({ editProviderOpen: true })
    }

    handleProviderClose = () => {
        this.setState({ editProviderOpen: false })
    }

    render = () => {

        let providerLocation = {}
        if (this.props.provider) {
            providerLocation = this.props.provider.location
        }

        return (
            <div>
                <Typography variant="h6" className={classes.dtitle}>
                    My Provider Group Profile
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.rightFloatButton}
                        onClick={this.handleProviderOpen}
                    >
                        <EditIcon className={classes.buttonIcon} fontSize="small" />
                        Edit
                    </Button>
                    <EditProvider
                        open={this.state.editProviderOpen}
                        handleClose={this.handleProviderClose}
                        provider={this.props.provider}
                        updateMyGroup={this.props.updateMyGroup}
                    />
                </Typography>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Logo
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        <img src={this.props.provider.logo_file} className={classes.ddLogo} alt="Provider Logo" />
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Name
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.provider.name}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Website
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.provider.website}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Phone Number
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {this.props.provider.phone}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Location
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        {providerLocation ? (
                            <div>
                                {
                                    providerLocation.street1 + ' ' + 
                                    providerLocation.street2 + ' ' +
                                    providerLocation.city + ', ' +
                                    providerLocation.state_code + ' ' +
                                    providerLocation.postal_code
                                }
                            </div>
                        ) : null}
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Capabilities
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        <div dangerouslySetInnerHTML={{
                            __html: this.props.provider.skill_description }}
                            className={classes.inlineP}
                        />
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Ideal Projects
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        <div dangerouslySetInnerHTML={{
                            __html: this.props.provider.ideal_projects }}
                            className={classes.inlineP}
                        />
                    </Typography>
                </div>
                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        About Us
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        <div dangerouslySetInnerHTML={{
                            __html: this.props.provider.about_us }}
                            className={classes.inlineP}
                        />
                    </Typography>
                </div>

                <div className={classes.dl}>
                    <Typography variant="inherit" className={classes.dt}>
                        Solutions Offered
                    </Typography>
                    <Typography variant="inherit" className={classes.dd}>
                        <a href="#na" className={classes.link}>5</a>
                    </Typography>

                </div>
                
            </div>
        )
    }
}


export default ProviderProfileTab