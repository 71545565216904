import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import DecloupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import Grid from '@material-ui/core/Grid'

import classes from './EditProvider.module.sass'


class EditProvider extends Component {
    state = {
        name: "",
        phone: "",
        website: "",
        logo_file: "",
        skill_description: "",
        about_us: "",
        ideal_projects: "",
        location: {}
    }

    componentDidMount = () => {
        this.setProviderState(this.props.provider)
    }

    componentDidUpdate = (previous) => {
        if (this.props.provider !== null &&
                (this.props.provider !== previous.provider ||
                this.props.provider.location !== previous.provider.location)) {
            this.setProviderState(this.props.provider)
        }
    }

    setProviderState = (provider) => {
        this.setState({...provider})
    }

    updateAboutUs = (editor) => {
        const about_us = editor.getData()
        this.setState({
            about_us: about_us
        })
    }

    updateIdealProjects = (editor) => {
        const ideal_projects = editor.getData()
        this.setState({
            ideal_projects: ideal_projects
        })
    }

    updateField = (event) => {
        const key = event.target.name
        const value = event.target.value
        if (key.search('__') !== -1) {
            const keys = key.split('__')
            const stateKey = keys[0]
            const fieldKey = keys[1]
            let updatedData = {...this.state[stateKey]}
            updatedData[fieldKey] = value
            this.setState({
                [stateKey]: updatedData
            })
        } else {
            this.setState({
                [key]: value
            })
        }
    }

    handleFileChange = (event) => {
        const file = event.target.files[0]
        this.setState({
            logo_file: file
        })
    }

    save = (event) => {
        let formData = new FormData()
        formData.append('name', this.state.name)
        formData.append('phone', this.state.phone)
        formData.append('website', this.state.website)
        formData.append('skill_description', this.state.skill_description)
        formData.append('about_us', this.state.about_us)
        formData.append('ideal_projects', this.state.ideal_projects)
        formData.append('location', JSON.stringify(this.state.location))
        if (this.state.logo_file !== this.props.provider.logo_file) {
            formData.append('logo_file', this.state.logo_file)
        }
        this.props.updateMyGroup(formData)
        this.props.handleClose(event)
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="edit-provider-dialog"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="edit-provider-dialog">
                    Edit {this.props.provider.name}
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <DialogContentText className={classes.content}>
                        The information you enter in this profile will be displayed
                        to customers.
                    </DialogContentText>
                    <Grid container space={16}>
                        <Grid item sm={4} className={classes.content}>
                            <img src={this.props.provider.logo_file} className={classes.logoFile} alt="logo file" />
                        </Grid>
                        <Grid item sm={4} className={classes.content}>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="logo_file"
                                name="logo_file"
                                type="file"
                                onChange={this.handleFileChange}
                            />
                            <label htmlFor="logo_file">
                                <Button variant="outlined" color="primary" component="span" className={classes.uploadButton}>
                                    Upload New Logo File
                                </Button>
                            </label>
                        </Grid>
                    </Grid>

                    <Grid item sm={12} className={classes.content}>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Provider Group Name"
                            name="name"
                            value={this.state.name}
                            type="text"
                            fullWidth
                            className={classes.field}
                            onChange={this.updateField}
                        />
                    </Grid>

                    <Grid container space={16}>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="phone"
                                name="phone"
                                label="Phone Number"
                                value={this.state.phone}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={6} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="website"
                                name="website"
                                label="Website"
                                value={this.state.website}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid item sm={12} className={classes.content}>
                        <TextField
                            margin="dense"
                            id="skill_description"
                            name="skill_description"
                            label="Our Capabilities (List of Skills)"
                            value={this.state.skill_description}
                            type="text"
                            fullWidth
                            className={classes.field}
                            onChange={this.updateField}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.content}>
                        <label htmlFor="about_us" className={classes.aboutLabel}>
                            About Us
                        </label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.about_us}
                            onChange={( event, editor ) => {
                                this.updateAboutUs(editor)
                            }}
                            onBlur={( event, editor ) => {
                                this.updateAboutUs(editor)
                            }}
                            config={{
                                disableNativeSpellChecker: false,
                            }}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.content}>
                        <label htmlFor="ideal_projects" className={classes.aboutLabel}>
                            Our Favorite Types of Projects
                        </label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.ideal_projects} 
                            onChange={( event, editor ) => {
                                this.updateIdealProjects(editor)
                            }}
                            onBlur={( event, editor ) => {
                                this.updateIdealProjects(editor)
                            }}
                            config={{
                                disableNativeSpellChecker: false,
                            }}
                        />
                    </Grid>

                    <Grid container space={16}>
                        <Grid item sm={8} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="location__street1"
                                name="location__street1"
                                label="Address Line 1"
                                value={this.state.location ? this.state.location.street1 : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="location__street2"
                                name="location__street2"
                                label="Address Line 2"
                                value={this.state.location ? this.state.location.street2 : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                    </Grid>

                    <Grid container space={16}>
                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="location__city"
                                name="location__city"
                                label="City"
                                value={this.state.location ? this.state.location.city : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="location__state_code"
                                name="location__state_code"
                                label="State (code)"
                                value={this.state.location ? this.state.location.state_code : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                        <Grid item sm={4} className={classes.content}>
                            <TextField
                                margin="dense"
                                id="location__postal_code"
                                name="location__postal_code"
                                label="Zip Code"
                                value={this.state.location ? this.state.location.postal_code : null}
                                type="text"
                                fullWidth
                                className={classes.field}
                                onChange={this.updateField}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.save} color="primary">
                        Save and Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default EditProvider