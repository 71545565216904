import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import classes from './NotificationSettings.module.sass'


class NotificationSettings extends Component {
    state = {
        project_opened: true,
        project_closed: true,
        project_proposed: true,
        estimate_created: true,
        estimate_approved: true,
        estimate_change_requested: true,
        comment_added: true,
        file_uploaded: true,
        expense_added: true,
        worklog_added: true,
        invoice_generated: true,
        invoice_paid: true,
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.checked
        })
    }

    save = (event) => {
        // ...
        this.props.handleClose()
    }

    render = () => {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="notification-settings-dialog"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="notification-settings-dialog">
                    Edit Notification Settings
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <FormControl component="fieldset" style={{ padding: '2px 8px', width: '23%' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.project_opened}
                                        onChange={this.handleChange('project_opened')}
                                        value="project_opened"
                                        color="primary"
                                    />
                                }
                                label="Project Opened"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.project_closed}
                                        onChange={this.handleChange('project_closed')}
                                        value="project_closed"
                                        color="primary"
                                    />
                                }
                                label="Project Closed"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.project_proposed}
                                        onChange={this.handleChange('project_proposed')}
                                        value="project_proposed"
                                        color="primary"
                                    />
                                }
                                label="Project Proposed"
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{ padding: '2px 8px', width: '23%' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.estimate_created}
                                        onChange={this.handleChange('estimate_created')}
                                        value="estimate_created"
                                        color="primary"
                                    />
                                }
                                label="Estimate Created"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.estimate_approved}
                                        onChange={this.handleChange('estimate_approved')}
                                        value="estimate_approved"
                                        color="primary"
                                    />
                                }
                                label="Estimate Approved"
                            />
                             <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.estimate_change_requested}
                                        onChange={this.handleChange('estimate_change_requested')}
                                        value="estimate_change_requested"
                                        color="primary"
                                    />
                                }
                                label="Estimate Change Requested"
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{ padding: '2px 8px', width: '23%' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.comment_added}
                                        onChange={this.handleChange('comment_added')}
                                        value="comment_added"
                                        color="primary"
                                    />
                                }
                                label="Comment Added"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.file_uploaded}
                                        onChange={this.handleChange('file_uploaded')}
                                        value="file_uploaded"
                                        color="primary"
                                    />
                                }
                                label="File Uploaded"
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{ padding: '2px 8px', width: '23%' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.expense_added}
                                        onChange={this.handleChange('expense_added')}
                                        value="expense_added"
                                        color="primary"
                                    />
                                }
                                label="Expense Posted"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.worklog_added}
                                        onChange={this.handleChange('worklog_added')}
                                        value="worklog_added"
                                        color="primary"
                                    />
                                }
                                label="Worklog Entered"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.invoice_generated}
                                        onChange={this.handleChange('invoice_generated')}
                                        value="invoice_generated"
                                        color="primary"
                                    />
                                }
                                label="Invoice Generated"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.invoice_paid}
                                        onChange={this.handleChange('invoice_paid')}
                                        value="invoice_paid"
                                        color="primary"
                                    />
                                }
                                label="Invoice Paid"
                            />
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.handleClose}
                        color="primary"
                        className={classes.dialogButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.save}
                        color="primary"
                        variant="contained"
                        className={classes.dialogButton}
                    >
                        Save and Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default NotificationSettings
