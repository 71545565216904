import React from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
// import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
// import InputBase from '@material-ui/core/InputBase'
// import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
// import { fade } from '@material-ui/core/styles/colorManipulator'
// import { withStyles } from '@material-ui/core/styles'
import MoreIcon from '@material-ui/icons/MoreVert'
import { Link } from 'react-router-dom'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'


import MenuIcon from '@material-ui/icons/Menu'
// import SearchIcon from '@material-ui/icons/Search'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
// import NotificationsIcon from '@material-ui/icons/Notifications'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FolderIcon from '@material-ui/icons/Folder'

import * as loginActions from '../../users/actions/loginActions'

import classes from './NavBar.module.sass'


class NavBar extends React.Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        mainMenu: false,
        mainMenuValue: 0,
    }

    handleChange = (event, value) => {
        this.setState({
            mainMenuValue: value
        })
    }

    toggleMainMenu = (status) => {
        this.setState({
            mainMenu: status
        })
    }

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null })
        this.handleMobileMenuClose()
    }

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget })
    }

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null })
    }

    logout = () => {
        this.setState({
            anchorEl: null,
            mobileMoreAnchorEl: null,
        })
        this.props.handleLogout()
    }

    render() {
        const { anchorEl, mobileMoreAnchorEl } = this.state
        const isMenuOpen = Boolean(anchorEl)
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

        const mainMenu = (
            <div className={classes.mainMenu}>
                <List>
                    {this.props.loggedIn ? (
                        <ListItem button key={0} value={0}component={Link} to="/my_panel/">
                            <HomeIcon />
                            <ListItemText primary={'My Panel'} />
                        </ListItem>
                    ) : null}
                    {this.props.loggedIn ? (
                        <ListItem button key={1} value={1} component={Link} to="/my_projects/">
                            <FolderIcon />
                            <ListItemText primary={'My Projects'} />
                        </ListItem>
                    ) : null}
                    {this.props.companyAdmin ? (
                        <ListItem button key={2} value={2} component={Link} to="/my_company/">
                            <SupervisedUserCircleIcon />
                            <ListItemText primary={'My Company'} />
                        </ListItem> 
                    ) : null}
                    {this.props.providerAdmin ? (
                        <ListItem button key={3} value={3} component={Link} to="/my_group/">
                            <SupervisedUserCircleIcon />
                            <ListItemText primary={'My Provider Group'} />
                        </ListItem>
                    ) : null}

                    <Divider />

                    {this.props.companyId ? (
                        <ListItem button key={4} value={4} component={Link} to="/new_job/">
                            <CreateNewFolderIcon />
                            <ListItemText primary={'Start a Project'} />
                        </ListItem>
                    ) : null}
                    {this.props.companyId ? (
                        <ListItem button key={5} value={5} component={Link} to="/new_job/?staffing=true">
                            <PersonIcon />
                            <ListItemText primary={'Hire a Contractor'} />
                        </ListItem>
                    ) : null}
                </List>
            </div>
        )

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                {this.props.loggedIn ? (
                    <MenuItem onClick={this.handleMenuClose} component={Link} to="/my_account/">
                        <AccountCircleIcon className={classes.buttonIcon} />
                        <p>My Account</p>
                    </MenuItem>
                ) : null}
                {this.props.loggedIn ? (
                    <MenuItem
                        component={Link} to="/"
                        onClick={this.logout}
                    >
                        <ExitToAppIcon className={classes.buttonIcon} />
                        Logout
                    </MenuItem>
                ) : (
                    <MenuItem
                        component={Link} to="/"
                        onClick={this.logout}
                    >
                        <AccountCircleIcon className={classes.buttonIcon} />
                        Login
                    </MenuItem>
                )}
            </Menu>
        )

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                {/*<MenuItem>
                    <IconButton color="inherit">
                        <Badge badgeContent={this.props.notifications.length} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p>Notifications</p>
                </MenuItem>*/}
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircleIcon />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        )

        return (
            <div className={classes.root}>
                <AppBar position="fixed" style={{ backgroundColor: '#252525', zIndex: 1201 }}>
                    <Toolbar className={classes.sectionDesktop}>
                        <div className={classes.logoImgContainer}>
                            <img src="/SourcePanelLogoInline2.png" className={classes.logoImg} alt="SourcePanel" />
                        </div>
                        <Tabs 
                            value={this.state.mainMenuValue}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            centered
                            className={classes.appTabs}
                        >
                            {this.props.loggedIn ? (
                                <Tab
                                    component={Link}
                                    to="/my_panel/"
                                    value={0}
                                    className={classes.appTab}
                                    label={
                                        <React.Fragment>
                                            <HomeIcon  className={classes.tabIcon} />
                                            My Panel
                                        </React.Fragment>
                                    }
                                />
                            ) : null}
                            {this.props.loggedIn ? (
                                <Tab
                                    component={Link}
                                    to="/my_projects/"
                                    value={1}
                                    className={classes.appTab}
                                    label={
                                        <React.Fragment>
                                            <FolderIcon  className={classes.tabIcon} />
                                            My Projects
                                        </React.Fragment>
                                    }
                                />
                            ) : null}

                            {this.props.companyAdmin ? (
                                <Tab
                                    component={Link}
                                    to="/my_company/"
                                    value={3}
                                    className={classes.appTab}
                                    label={
                                        <React.Fragment>
                                            <SupervisedUserCircleIcon  className={classes.tabIcon} />
                                            My Company
                                        </React.Fragment>
                                    }
                                />
                            ) : null}
                            {this.props.providerAdmin ? (
                                <Tab
                                    component={Link}
                                    to="/my_group/"
                                    value={4}
                                    className={classes.appTab}
                                    label={
                                        <React.Fragment>
                                            <SupervisedUserCircleIcon  className={classes.tabIcon} />
                                            My Provider Group
                                        </React.Fragment>
                                    }
                                />
                            ) : null}
                        </Tabs>

                        <div className={classes.grow} />

                        <Typography variant="h6" color="inherit">
                            {this.props.loggedIn ? (
                                "Hi, " + this.props.firstName
                            ) : null}
                        </Typography>
                        {/*<IconButton color="inherit">
                            {this.props.notifications.length ? (
                                <Badge badgeContent={this.props.notifications.length} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            ) : (
                                <NotificationsIcon />
                            )}
                        </IconButton>*/}
                        
                       
                        <IconButton
                            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleProfileMenuOpen}
                            color="inherit"
                        >   
                            <AccountCircleIcon />
                        </IconButton>
                    </Toolbar>

                    <Toolbar className={classes.sectionMobile}>
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => this.toggleMainMenu(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <SwipeableDrawer
                            open={this.state.mainMenu}
                            onClose={() => this.toggleMainMenu(false)}
                            onOpen={() => this.toggleMainMenu(true)}
                        >
                            <div
                                tabIndex={0}
                                role="button"
                                onClick={() => this.toggleMainMenu(false)}
                                onKeyDown={() => this.toggleMainMenu(false)}
                            >
                                {mainMenu}
                            </div>
                        </SwipeableDrawer>

                        <div className={classes.grow} />

                        <Typography variant="h6" color="inherit">
                            {this.props.loggedIn ? ("Hello, " + this.props.firstName) : null}
                        </Typography>
                        <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                            <MoreIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                {renderMobileMenu}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    ...state
})


const mapDispatchToProps = (dispatch) => ({
    handleLogout: () => loginActions.logout(dispatch),
})


export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
