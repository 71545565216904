import React from 'react'
import { connect } from 'react-redux'

import classes from './AlternateRightNavBar.module.sass'


const AlternateRightNavBar = (props) => (
    <div className={classes.alternateRightNavDrawer}>
        {props.children}
    </div>
)


const mapStateToProps = state => ({
    ...state
})


export default connect(mapStateToProps)(AlternateRightNavBar)
  