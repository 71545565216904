
const retrieveFromStorage = [
    'token',
    'loggedIn',
    'userId',
    'firstName',
    'lastName',
    'username',
    'notificationEmail',
    'phone',
    'title',
    'organizationName',
    'companyId',
    'companyName',
    'companyLogo',
    'providerId',
    'providerName',
    'providerLogo',
    'companyAdmin',
    'providerAdmin',
]
retrieveFromStorage.inArray = function(value) {
    if (this.indexOf(value) === -1) {
        return false
    }
    return true
}
export { retrieveFromStorage }


export const initialState = {
    // Website State
    token: null,
    loggedIn: false,
    userId: null,
    notifications: [],
    firstName: '',
    lastName: '',
    username: '',
    notificationEmail: '',
    phone: '',
    title: '',
    organizationName: '',
    companyId: null,
    companyName: '',
    companyLogo: '',
    providerId: null,
    providerName: '',
    providerLogo: '',
    companyAdmin: false,
    providerAdmin: false,
    errors: {},
    // Search Projects
    searchText: '',
    searchResults: [],
    // My Projects State
    jobs: [],
    jobsCount: 0,
    jobsOrderBy: 'request_date',
    jobsOrder: 'desc',
    jobStatusFilters: {
        'In Progress': true,
        'In Review': true,
        'Draft': false,
        'Closed': false,
    },
    jobsMeOnlyFilter: false,
    jobsStartFilter: null,
    jobsEndFilter: null,
    jobsCustomerFilter: '',
    jobsVendorFilter: '',
    // Company/Provider
    company: {},
    members_list: [],
    members: [],
    vendors_list: [],
    vendors: [],
    customFields: [],
}
