import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import EditIcon from '@material-ui/icons/Edit'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import DescriptionIcon from '@material-ui/icons/Description'
import NotificationsIcon from '@material-ui/icons/Notifications'
import StarIcon from '@material-ui/icons/Star'

import moment from 'moment'

import classes from './CandidateCardCustomerMenu.module.sass'


class CandidateCardCustomerMenu extends Component {
    state = {
        interestedOpen: false,
    }

    interestedOptions = [
        {
            value: 'New',
            disabled: true,
        },
        {
            value: 'Interested',
            disabled: false,
        },
        {
            value: 'Maybe',
            disabled: false,
        },
        {
            value: 'Not Interested',
            disabled: false,
        },
        {
            value: 'Hired',
            disabled: true,
        },
    ]

    handleInterestedOpen = () => {
        this.setState({
            interestedOpen: true
        })
    }

    handleInterestedClose = () => {
        this.setState({
            interestedOpen: false
        })
    }

    dateTimeDisplay = (dateString) => {
        let dateTime = new moment(dateString)
        if (dateTime.isValid()) {
            return dateTime.format('llll')
        }
        return 'N/A'
    }

    renderResumeLink = () => {
        if (this.props.candidate.resume) {
            return (
                <Button
                    color="primary"
                    size="small"
                    className={classes.navButton}
                    component="a"
                    href={this.props.candidate.resume}
                    target="_blank"
                    rel="noopener noreferrer"
                    fullWidth
                >
                    <DescriptionIcon className={classes.actionButtonIcon} />
                    View résumé
                </Button>
            )
        }
        return null
    }

    render = () => {
        const candidate = this.props.candidate
        const interested = candidate.interested
        if (interested === 'Hired') {
            return (
                <React.Fragment>
                    <Typography variant="h6" className={classes.actionField}>
                        <StarIcon className={classes.hiredStatusIcon} />
                        Hired
                    </Typography>
                    {this.renderResumeLink()}
                    <ul>
                        <li>
                            <em>Added to project</em>
                        </li>
                    </ul>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <FormControl
                        className={classes.actionField}
                    >
                        <InputLabel
                            shrink={true}
                            htmlFor="interested"
                        >
                            Status
                        </InputLabel>
                        <Select
                            value={interested}
                            onChange={this.props.updateCandidate}
                            inputProps={{
                                name: 'interested',
                                id: 'interested'
                            }}
                            open={this.state.interestedOpen}
                            onOpen={this.handleInterestedOpen}
                            onClose={this.handleInterestedClose}
                            margin="dense"
                        >
                            {this.interestedOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {this.renderResumeLink()}
                    {candidate.accepted_interview !== null ? (
                        <Button
                            color="primary"
                            size="small"
                            className={classes.navButton}
                            fullWidth
                        >
                            <AccessTimeIcon className={classes.actionButtonIcon} />
                            Interview time
                            {this.dateTimeDisplay(candidate.accepted_interview.start_time)}
                        </Button>
                    ) : (
                        <React.Fragment>
                            {candidate.myPartyInterviewCount > 0 ? (
                                <em>Proposed times sent</em>
                            ) : null}
                            <Button
                                color="primary"
                                size="small"
                                className={classes.navButton}
                                fullWidth
                            >
                                <AccessTimeIcon className={classes.actionButtonIcon} />
                                Schedule interview
                            </Button>
                        </React.Fragment>
                    )}
                    {interested !== 'Not Interested' && candidate.offer_status === 'NA' ? (
                        <Button
                            color="primary"
                            size="small"
                            className={classes.navButton}
                            fullWidth
                        >
                            <NotificationsIcon className={classes.actionButtonIcon} />
                            Submit Offer
                        </Button>
                    ) : null}
                    {interested !== 'Not Interested' && candidate.offer_status === 'Submitted' ? (
                        <Button
                            color="primary"
                            size="small"
                            className={classes.navButton}
                            fullWidth
                        >
                            <EditIcon className={classes.actionButtonIcon} />
                            Edit Offer
                        </Button>
                    ) : null}
                    {interested !== 'Not Interested' && candidate.offer_status === 'Change Requested' ? (
                        <React.Fragment>
                            <em>
                                * Provider requested changes to offer
                            </em>
                            <Button
                                color="primary"
                            size="small"
                            className={classes.navButton}
                            >
                                <NotificationsIcon className={classes.actionButtonIcon} />
                                Modify Offer
                            </Button>
                        </React.Fragment>
                    ) : null}
                    
                </React.Fragment>
            )
        }
    }
}


export default CandidateCardCustomerMenu
