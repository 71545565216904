import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import CKEditor from 'react-ckeditor-component'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
// import moment from 'moment'

import EstimateMilestonesList from '../../milestones/components/EstimateMilestonesList'

import classes from './EstimateDialog.module.sass'


class EstimateDialog extends Component {
    state = {
        estimate_type: 'hourly',
        rate: '',
        expert_fixed_estimate: '',
        recurring_rate: '',
        recurring_text: 'monthly',
        expert_summary: '',
        expected_start_date: null,
        recurringLabelWidth: 0,
        milestones: [],
    }
    
    estimate_type_options = {
        hourly: 'Dynamic hourly billing',
        fixed: 'Fixed price',
        recurring: 'Recurring fee',
    }

    recurring_options = [
        {
            value: 'weekly',
            label: 'Weekly',
        },
        {
            value: 'monthly',
            label: 'Monthly',
        },
        {
            value: 'quarterly',
            label: 'Quarterly',
        },
        {
            value: 'bi-annual',
            label: 'Bi-Annually',
        },
        {
            value: 'annual',
            label: 'Annually',
        },
    ]

    componentDidMount = () => {
        if (this.state.estimate_type === 'recurring') {
            const labelWidth = ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
            this.setState({
                recurringLabelWidth: labelWidth
            })
        }
    }

    componentDidUpdate = () => {
        if (this.state.estimate_type === 'recurring') {
            const labelWidth = ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
            const currentLabelWidth = this.state.recurringLabelWidth
            if (currentLabelWidth !== labelWidth) {
                this.setState({
                    recurringLabelWidth: labelWidth
                })
            }
        }
    }

    handleChange = event => {
        this.setState({ 
            [event.target.name]: event.target.value
        });
    }

    updateSummary = (event) => {
        const summary = event.editor.getData();
        this.setState({
            expert_summary: summary
        })
    }

    moveUp = (movedOrder) => {
        if (movedOrder > 1) {
            const newOrder = movedOrder - 1
            // Complex logic necessary to prevent asynchronous issues.
            this.setState({
                milestones: [
                    ...this.state.milestones.slice(0, newOrder - 1),
                    Object.assign({}, this.state.milestones[movedOrder - 1], {order: newOrder}),
                    Object.assign({}, this.state.milestones[newOrder - 1], {order: movedOrder}),
                    ...this.state.milestones.slice(movedOrder)
                ]
            }, () => {
                this.state.milestones.sort((a, b) => a.order - b.order)
            })
        }
    }

    moveDown = (movedOrder) => {
        if (movedOrder < this.state.milestones.length) {
            const newOrder = movedOrder + 1
            // Complex logic necessary to prevent asynchronous issues.
            this.setState({
                milestones: [
                    ...this.state.milestones.slice(0, movedOrder - 1),
                    Object.assign({}, this.state.milestones[newOrder - 1], {order: movedOrder}),
                    Object.assign({}, this.state.milestones[movedOrder - 1], {order: newOrder}),
                    ...this.state.milestones.slice(newOrder)
                ]
            }, () => {
                this.state.milestones.sort((a, b) => a.order - b.order)
            })
        }
    }

    addMilestone = () => {
        let milestones = [...this.state.milestones]
        let milestone = {
            id: '_' + Math.random().toString(36).substr(2, 9),
            title: '',
            percent: '',
            content: '',
            order: milestones.length + 1
        }
        milestones.push(milestone)
        this.setState({
            milestones: milestones
        })
    }

    deleteMilestone = (deletedOrder) => {
        let milestones = [...this.state.milestones]
        let newMilestones = []
        for (let milestone of milestones) {
            if (milestone.order < deletedOrder) {
                newMilestones.push(milestone)
            } else if (milestone.order > deletedOrder) {
                milestone.order -= 1
                newMilestones.push(milestone)
            }
        }
        this.setState({
            milestones: newMilestones
        })
    }

    handleMilestoneChange = (event, index) => {
        const key = event.target.name
        const value = event.target.value
        this.setState({
            milestones: this.state.milestones.map((milestone, i) => {
                if (i !== index) {
                    return milestone
                }
                return {
                    ...milestone,
                    [key]: value
                }
            })
        })
    }

    sendEstimate = (event) => {
        let formData = {}
        switch (this.state.estimate_type) {
            case 'hourly':
                formData.rate = this.state.rate
                break
            case 'fixed':
                formData.expert_fixed_estimate = this.state.expert_fixed_estimate
                formData.milestones = [...this.state.milestones]
                break
            case 'recurring':
                formData.recurring_rate = this.state.recurring_rate
                formData.recurring_text = this.state.recurring_text
                break
            default:
                break
        }
        formData.estimate_type = this.state.estimate_type
        formData.expert_summary = this.state.expert_summary
        formData.expected_start_date = this.state.expected_start_date
        this.props.sendEstimate(formData, () => {
            this.props.handleClose()
        })
    }

    renderHourlySection = () => {
        return (
            <React.Fragment>
                <hr />
                <Typography variant="h6">
                    Dynamic hourly billing
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 4 }}>
                    You are committing to work on the project on an hourly basis
                    until the project is complete or the customer stops the
                    project. Be sure to clarify your understanding of the
                    project and associated deliverables.
                </Typography>
                <TextField
                    id="rate"
                    name="rate"
                    label="Hourly rate"
                    margin="dense"
                    value={this.state.rate}
                    onChange={this.handleChange}
                    variant="outlined"
                    helperText="(numbers only, no decimals)"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </React.Fragment>
        )
    }

    renderMilestonesSection = () => {
        return (
            <React.Fragment>
                <hr />
                <Typography variant="h6">
                    Provide a total fixed bid price with milestones
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 4 }}>
                    You are guaranteeing you will completed the project for a 
                    set price. Be sure your bid allows for adequate pay and 
                    clarify what deliverables are included in the project 
                    scope. You will be able to request a change order for 
                    additional work that is not in this scope.
                </Typography>
                <TextField
                    id="expert_fixed_estimate"
                    name="expert_fixed_estimate"
                    label="Fixed price"
                    margin="dense"
                    value={this.state.expert_fixed_estimate}
                    onChange={this.handleChange}
                    variant="outlined"
                    helperText="(numbers only, no decimals)"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
                <hr />
                <Typography variant="h6">
                    Create project milestones
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 4 }}>
                    For fixed bid projects, there must be one or more 
                    milestones. The combined milestones must add up to 100%, 
                    and invoices will be generated upon marking milestones 
                    complete.
                </Typography>
                <EstimateMilestonesList
                    milestones={this.state.milestones}
                    moveUp={this.moveUp}
                    moveDown={this.moveDown}
                    deleteMilestone={this.deleteMilestone}
                    addMilestone={this.addMilestone}
                    handleChange={this.handleMilestoneChange}
                />
            </React.Fragment>
        )
    }

    renderRecurringSection = () => {
        return (
            <React.Fragment>
                <hr />
                <Typography variant="h6">
                    Recurring fee
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 4 }}>
                    You are setting the recurring fee for this project. Your 
                    estimate will generate regular invoices until the project 
                    is marked complete.
                </Typography>
                <TextField
                    id="recurring_rate"
                    name="recurring_rate"
                    label="Recurring fee"
                    value={this.state.recurring_rate}
                    onChange={this.handleChange}
                    variant="outlined"
                    margin="dense"
                    helperText="(numbers only, no decimals)"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    style={{ marginRight: 16 }}
                />
                <FormControl variant="outlined" margin="dense">
                    <InputLabel
                        ref={ref => {
                            this.InputLabelRef = ref
                        }}
                        htmlFor="recurring_text"
                    >
                        Interval
                    </InputLabel>
                    <Select
                        value={this.state.recurring_text}
                        onChange={this.handleChange}
                        input={
                            <OutlinedInput
                                labelWidth={this.state.recurringLabelWidth}
                                name="recurring_text"
                                id="recurring_text"
                            />
                        }
                    >
                        {this.recurring_options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </React.Fragment>
        )
    }


    render = () => {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="estimate-dialog-title"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="estimate-dialog-title">
                    {`Estimate Project: ${this.props.job.name}`}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={16}>
                        <Grid item lg={5} md={6}>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Status
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.job.status}
                                </Typography>
                            </div>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Opened
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.dateDisplay(this.props.job.request_date)}
                                </Typography>
                            </div>
                            <div className={classes.dl}>
                                <Typography variant="inherit" className={classes.dt}>
                                    Skill Category
                                </Typography>
                                <Typography variant="inherit" className={classes.dd}>
                                    {this.props.job.skill}
                                </Typography>
                            </div>

                            <Typography variant="h6" className={classes.dtitle}>
                                Project details
                            </Typography>
                            <div dangerouslySetInnerHTML={{
                                __html: this.props.job.description }}
                                className={classes.description}
                            />
                        </Grid>

                        <Grid item lg={7} md={6}>
                            <div className={classes.formContent}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Select an estimate type
                                    </FormLabel>
                                    <RadioGroup
                                        aria-label="Estimate type"
                                        name="estimate_type"
                                        value={this.state.estimate_type}
                                        onChange={this.handleChange}
                                    >
                                        <FormControlLabel
                                            value="hourly"
                                            control={<Radio />}
                                            label="Dynamic hourly billing"
                                        />
                                        <FormControlLabel
                                            value="fixed"
                                            control={<Radio />}
                                            label="Fixed price with milestones"
                                        />
                                        <FormControlLabel
                                            value="recurring"
                                            control={<Radio />}
                                            label="Recurring fee"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                {this.state.estimate_type === 'hourly' ? (
                                    this.renderHourlySection()
                                ) : null}
                                {this.state.estimate_type === 'recurring' ? (
                                    this.renderRecurringSection()
                                ) : null}
                                {this.state.estimate_type === 'fixed' ? (
                                    this.renderMilestonesSection()
                                ) : null}

                                <hr style={{ marginTop: 16 }} />
                                <Typography variant="h6" gutterBottom>
                                    Enter any clarifying comments
                                </Typography>
                                <CKEditor
                                    activeClass={classes.ckeditor}
                                    content={this.state.expert_summary} 
                                    events={{
                                        "blur": this.updateSummary,
                                        "change": this.updateSummary,
                                        "keyup": this.updateSummary
                                    }}
                                    style={{
                                        marginBottom: 16
                                    }}
                                    config={{
                                        disableNativeSpellChecker: false,
                                    }}
                                />

                                <hr style={{ marginTop: 16 }} />
                                <Typography variant="h6">
                                    Expected start date
                                </Typography>
                                <Typography variant="body2" style={{ marginBottom: 4 }}>
                                    If you cannot start immediately, enter an expected start date
                                </Typography>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        name="expected_start_date"
                                        id="expected_start_date"
                                        label="Expected start date"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder="ASAP"
                                        value={this.state.expected_start_date}
                                        onChange={(date) => this.handleChange({ target: { name: 'expected_start_date', value: date }})}
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </MuiPickersUtilsProvider>

                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.handleClose}
                        color="primary"
                        className={classes.dialogButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.sendEstimate}
                        color="primary"
                        variant="contained"
                        className={classes.dialogButton}
                    >
                        Send Estimate
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default EstimateDialog
